import React, { FC, useEffect, useState } from 'react';
import { encryptId } from '../../../../../_metronic/helpers';
import { useHistory } from 'react-router-dom';
import { colorStatusProject } from '../../../../../_metronic/helpers/CustomElements';

type Props = {
  board: any;
  onRefreshData: () => void;
};

const ListaTarjeta: FC<Props> = ({ board, onRefreshData }) => {
  const history = useHistory();

  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(board.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, board.length);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [onRefreshData]);

  return (
    <>
      <div className="container mt-5">
        <div className="row ">
          {board.slice(startIndex, endIndex).map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="col-md-6 col-lg-6 col-xxl-4 col-xl-6 mb-3"
              >
                <div
                  className="card border h-100 cursor-pointer"
                  style={{ minHeight: '100px' }}
                  onClick={() => {
                    history.push(
                      `/proyectos/edit/${encryptId(item.id.toString())}`
                    );
                  }}
                >
                  <div className="card-body d-flex  align-items-center  pt-1 pb-0">
                    <div className="min-h-150px w-100">
                      <div className="fw-bolder text-gray-700 fs-4 mt-2 cursor-pointer w-100 large-text">
                        {item.nombre}
                      </div>

                      <div className="">
                        <div className="text-gray-500 fw-bolder fs-7 mt-2">
                          Tipo de proyecto
                        </div>
                        <div className="text-gray-500 fs-7">
                          {item.tipo.name}
                        </div>
                      </div>
                      <div className="text-gray-500 fw-bolder mt-2">
                        <div
                          className={`${colorStatusProject(item.estatus.code)}`}
                        >
                          {item.estatus.name}
                        </div>
                      </div>
                      <div className="text-gray-500 fw-bolder fs-7 mt-2 text-end">
                        <span>Fecha final</span>
                      </div>
                      <div className="text-gray-500 fs-7 mb-4 text-end">
                        {new Date(item.fecha_final).toISOString().split('T')[0]}
                      </div>
                    </div>
                  </div>
                  <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-primary border-bottom w-100"></span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row justify-content-center">
          <nav aria-label="Navegación de página">
            <ul className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? 'active' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => onPageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export { ListaTarjeta };
