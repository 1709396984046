import axios from "axios";
const API_URL = process.env.REACT_APP_API;
const BASE_URL = `${API_URL}categoria/proyectos`;

async function handleRequest(request: Promise<any>) {
  try {
    const response = await request;
    return response.data.doc?.data ?? response.data;
  } catch (error) {
    console.error("Error en la petición:", error);
    throw error; 
  }
}

export async function getCategoriesGrid() {
  return await handleRequest(axios.get(BASE_URL));
}

export async function getDataCategoryById(id: number) {
  return await handleRequest(axios.get(`${BASE_URL}/${id}`));
}

export async function addValueCategory({ nombre, orden }: { nombre: string, orden: number }) {
  return await handleRequest(axios.post(BASE_URL, { nombre, orden }));
}

export async function updValueCategory({ id, nombre, orden }: { id: number, nombre: string, orden: number }) {
  return await handleRequest(axios.patch(`${BASE_URL}/${id}`, { nombre, orden }));
}
export async function removeRegisterCategory(id: number) {
  return await handleRequest(axios.delete(`${BASE_URL}/${id}`));
}
