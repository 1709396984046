import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { obtenerCamposFiltro, obtenerDatosFiltros, obtenerTareasPorEtapa } from '../../../service/bitacora/Bitacora';
import { GenericoModel } from '../../catalogos/generico/models/GenericoModel';



export const useObtenerTareasPorEtapa = (actualizarTareas: number, initFilterValues: any) => {
  const [tareas, setTareas] = useState<any[]>([]);
  const [tareasPorEtapa, setTareasPorEtapa] = useState<any[]>([]);
  const [cargandoTareas, setCargandoTareas] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    let isMounted = true; 
    const fetchTareas = async () => {
      setCargandoTareas(true);
      try {
        const res = await obtenerTareasPorEtapa(initFilterValues);
        if (res && isMounted) {
          setTareas(res.tareas);
          setTareasPorEtapa(res.tareasPorEtapa);
        }
      } catch (error) {
        if (isMounted) {
          history.push('/error/500');
        }
      } finally {
        if (isMounted) {
          setCargandoTareas(false);
        }
      }
    };
    
      fetchTareas();
    
    return () => {
      isMounted = false;
    };
  }, [actualizarTareas, history]);

  return { tareas, tareasPorEtapa, cargandoTareas };
};

export const useObtenerCamposFiltro = (isAdmin: any) => {
  const [listFields, setListFields] = useState<GenericoModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    const fetchPost = async () => {
      setLoadingRequest(true);
      try {
        const res = await obtenerCamposFiltro();
        if (isMounted && res) {
          let filteredRes = res;
          if (!isAdmin) {
            filteredRes = res.filter((option: any) => option.code !== "BIT_ASING");
          }
          const selectOptions = filteredRes.map((option: any) => ({
            value: option.id,
            label: `${option.name}`,
            code: option.code,
          }));
          setListFields(selectOptions);
        }
      } catch (error) {
        history.push("/error/500");
      } finally {
        if (isMounted) {
          setLoadingRequest(false);
        }
      }
    };

    fetchPost();

    return () => {
      isMounted = false;
    };
  }, [history, isAdmin]);

  return {
    listFields,
    loadingRequest,
  };
};


export const useObtenerDatosFiltros = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    const fetchPost = async () => {
      setLoadingRequest(true);
      try {
        const res = await obtenerDatosFiltros(code);
        if (isMounted && res) {
          setDataOptionSelected(res);
        }
      } catch (error) {
        history.push("/error/500");
      } finally {
        if (isMounted) {
          setLoadingRequest(false);
        }
      }
    };

    if (code) {
      fetchPost();
    }

    return () => {
      isMounted = false;
    };
  }, [code, history]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};