import React, { useState, useEffect } from 'react';
import { getCompanyByUser } from '../../../service/company/Company';
import { useGetOneCompany, useUpdateCompany } from '../hooks/HumanResourcesHook';
import { toast } from 'react-toastify';

const View: React.FC = () => {

    const [loading, setLoading] = useState(false);
    const [reloadCompany, setReloadCompany] = useState<number>(
        Math.random() * 40
      );
    const { data } =  useGetOneCompany(reloadCompany)
    const [companyId, setCompanyId] = useState<any>(null);
    
    const [isChecked, setIsChecked] = useState<any>(null);
    const { updateRequest } = useUpdateCompany()
  
    const onCheckBoxChange = (checked : any) => {
        setLoading(true)
        updateRequest(checked, companyId)
        .then((res)=>{
            toast.success('Configuración actualizada correctamente.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              setLoading(false);
              setReloadCompany(Math.random() * 40)
        })
    }

    useEffect(()=>{

        if(data){
            setCompanyId(data.id)
            setIsChecked(data.generar_num_empleado)
        }
        
    }, [data])

 
  return (
    <>
      <div className="row gy-5 g-xl-8">
  <div className="col-xl-12">
    <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9 p-0 d-flex align-items-center">
        <label className="fw-bold fs-6 mb-2 w-auto mt-3 mx-3">
          Generar número de empleado
        </label>
        <input
          className="form-check-input mt-1"
          type="checkbox"
          disabled={loading}
          checked={isChecked}
           onChange={(event: any) => {
            onCheckBoxChange(event.target.checked);
           }}
        />
        { loading && (
        <span className="indicator-progress mt-1 mx-3" style={{ display: "block" }}>
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
        )
}
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export { View };
