import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GenericoModel } from "../models/GenericModel";
import {
  getCategoriesGrid,
  getDataCategoryById,
} from "../../../../service/catalog/CategoriaProyectos";

export const useGetAll = (loading: number) => {
  const [dataCategories, setDataCategories] = useState<GenericoModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const data = await getCategoriesGrid()
        .then()
        .catch((error) => {
          setLoadingRequest(false);

          history.push("/error/500");
        });
      if (data) {
        setDataCategories(data.rows);
        setCount(data.count);
        setLoadingRequest(false);
      }
    };
    fetchPost();
  }, [loading]);
  return { dataCategories, count, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    codigo: "",
    nombre: "",
    orden: 0,
  };
  const [initValues, setInitValues] = useState<GenericoModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getDataCategoryById(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        res.orden = res.orden ? res.orden : 0;
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
  }, [id]);

  return { initValues };
};
