// import { TareaModel, TaskDocModel } from '../models/TaskModel';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getNoteByID,
  updateNoteContact,
} from '../../../service/contacto/Contacto';
import { NoteContactModel } from '../../contacto/models/ContactoModel';
import { SimpleAlert } from '../../../../_metronic/helpers';

export const useGetNoteByID = (id: string, reload: number) => {
  const note: NoteContactModel = {
    id: 0,
    nombre: '',
    descripcion: '',
  };
  const [noteInit, setNoteInit] = useState<NoteContactModel>(note);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getNoteByID(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setNoteInit(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setNoteInit(note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { noteInit };
};

export const useEditNote = () => {
  const [loadingEditNote, setLoadingEditNote] = useState<boolean>(false);
    const history = useHistory();
    const editNote = async (note: NoteContactModel, contacto_id: string) => {
    setLoadingEditNote(false);
        try {
      const res = await updateNoteContact(note, contacto_id);
      setLoadingEditNote(true);
      const { status, message } = res?.data
      SimpleAlert(message, 3000, status);
      history.goBack();
    } catch (error: any) {
      const { status, message } = error?.response?.data
      SimpleAlert(message, 3000, status);
      setLoadingEditNote(false);
    }
  };
  return { editNote, loadingEditNote };
};

