import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {
    useCreateAddress,
    useGetAddressByContactId,
    useGetDocumentsByContactId,
    useGetNotasContactos,
    useGetOne
} from '../hooks/ContactoHook';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';
import { PatternFormat } from 'react-number-format';
import {
  addContacto,
  updateContacto,
} from '../../../service/contacto/Contacto';
import { useHistory } from 'react-router-dom';
import { NoteList } from './sections/NoteList';
import { CreateNoteModal } from '../_modals/CreateNoteModal';
import { CreateNoteDetailModal } from '../_modals/CreateNoteDetailModal';
import { AddressList } from './sections/AddressList';
import { AddressContactModel } from '../models/ContactoModel';
import { AddressModal } from '../../../../_metronic/helpers/components/Address';
import { DocsList } from './sections/DocsList';

type Props = {
  id_row: number;
  onRefreshData: () => void;
  section: string;
};

const ContactoForm: React.FC<Props> = ({ id_row, onRefreshData, section }) => {
  const [loading, setLoading] = useState(false);
    const [activeNewNote, setActiveNewNote] = useState<boolean>(false);
    const [activeNewAddress, setActiveNewAddress] = useState<boolean>(false);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showNoteDetailModal, setShowNoteDetailModal] =
        useState<boolean>(false);
    const [showModalAddress, setShowModalAddress] = useState<boolean>(false);
    const [addressInit, setAddessInit] = useState<AddressContactModel>({
        id: 0,
        nombre: '',
        pais: '',
        calle: '',
        numero_exterior: '',
        numero_interior: '',
        codigo_postal: '',
        colonia: '',
        ciudad: '',
        estado: '',
    });
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const sectionTabs = useRef<HTMLDivElement>(null);
  const { catalog: departamentos } = useSelectGeneric('DEP');
  const { initValues } = useGetOne(id_row);
  const [tabs, setTabs] = useState<
    string | 'NOTE' | 'ADDRESSES' | 'DOCUMENTS'
  >('NOTE');
  const [reloadGridNotes, setReloadGridNotes] = useState<number>(
    Math.random() * 40
    );
    const [reloadGridAddress, setReloadGridAddress] = useState<number>(
        Math.random() * 40
    );
  const { notes, loadingNote } = useGetNotasContactos(
    id_row.toString(),
    reloadGridNotes
    );

    const { address, loadingAddress } = useGetAddressByContactId(
        id_row.toString(),
        reloadGridAddress
    );

    const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
    const { dataDocuments, loadingDocuments } = useGetDocumentsByContactId(
        Number(id_row),
        reloadDocuments
    );

    const { createAddress, loadingInsertAddress } = useCreateAddress();

  const history = useHistory();

  const ContactoSchema = Yup.object().shape({
    nombre: Yup.string().required('Este campo es requerido.'),
    apellido_paterno: Yup.string().required('Este campo es requerido.'),
    fecha_nacimiento: Yup.date()
      .nullable(true) // Permite que el campo sea null
      .notRequired() // Hace que el campo sea opcional
      .max(new Date(), 'La fecha de nacimiento no puede ser en el futuro')
      .test(
        'is-adult',
        'Debes ser mayor de edad para registrarte y menor de 99 años',
        function (value: any) {
          if (!value) {
            return true; // Si el campo es opcional y no se proporciona, la validación pasa
          }
          const fechaNacimiento = new Date(value);
          const fechaHoy = new Date();
          const edadLimite = new Date(
            fechaHoy.getFullYear() - 18,
            fechaHoy.getMonth(),
            fechaHoy.getDate()
          );

          const edadLimite99 = new Date(
            fechaHoy.getFullYear() - 99,
            fechaHoy.getMonth(),
            fechaHoy.getDate()
          );

          return (
            fechaNacimiento <= edadLimite && fechaNacimiento >= edadLimite99
          );
        }
      ),
  });

  const formContacto = useFormik({
    initialValues: initValues,
    validationSchema: ContactoSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateContacto(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              history.goBack();
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formContacto.resetForm();
              setLoading(false);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addContacto(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              history.goBack();
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formContacto.resetForm();
              setLoading(false);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });

  const handlePhoneNumberChange = (values: any) => {
    formContacto.setFieldValue('telefono_movil', values.target.value);
  };

  const openModal = (isOpen: boolean) => {
    setShowNoteModal(isOpen);
    };

    const openAddressModal = (isOpen: boolean) => {
        setShowModalAddress(isOpen);
    };
  const openDetailModal = (isOpen: boolean) => {
    setShowNoteDetailModal(isOpen);
    };

    const openModalToEdit = (values: any) => {
        setAddessInit(values);
        setIsEditable(true);
        setShowModalAddress(true);
    };

    const onSaveAddress = (address: AddressContactModel) => {
        createAddress(address, id_row.toString());
    };

    useEffect(() => {
        if (loadingInsertAddress) {
            setReloadGridAddress(Math.random() * 40);
            onRefreshData();
            setShowModalAddress(false);
        }
    }, [loadingInsertAddress]);

  return (
    <>
      <form
        id="kt_modal_generic_form"
        className="form"
        action="#"
        onSubmit={formContacto.handleSubmit}
      >
        <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5 mb-4">
          <div className="w-100 d-flex">
            <div className="w-100 pe-5">
              <input
                className="form-control mb-2 w-100 border-0"
                style={{
                  width: '100%',
                  padding: '15px',
                  fontSize: '24px',
                  boxSizing: 'border-box',
                  marginBottom: '20px',
                }}
                data-kt-element="input"
                placeholder="Nombre *"
                {...formContacto.getFieldProps('nombre')}
              ></input>
              {formContacto.touched.nombre && formContacto.errors.nombre && (
                <div className="fv-plugins-message-container text-danger w-100">
                  <span role="alert">{formContacto.errors.nombre}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row wh-0100 mb-3">
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="required fw-bold fs-6 mb-2 w-auto">
                  Apellido paterno
                </label>
              </div>
              <input
                className="form-control"
                data-kt-element="input"
                placeholder="Apellido paterno"
                {...formContacto.getFieldProps('apellido_paterno')}
              ></input>
              {formContacto.touched.apellido_paterno &&
                formContacto.errors.apellido_paterno && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formContacto.errors.apellido_paterno}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">
                  Apellido materno
                </label>
              </div>
              <input
                className="form-control"
                data-kt-element="input"
                placeholder="Apellido materno"
                {...formContacto.getFieldProps('apellido_materno')}
              ></input>
              {formContacto.touched.apellido_materno &&
                formContacto.errors.apellido_materno && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formContacto.errors.apellido_materno}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">Celular</label>
              </div>
              <PatternFormat
                {...formContacto.getFieldProps('telefono_movil')}
                className="form-control"
                format="## #### #### ##### ####"
                name="telefono_movil"
                onChange={handlePhoneNumberChange}
              />
              {formContacto.touched.telefono_movil &&
                formContacto.errors.telefono_movil && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formContacto.errors.telefono_movil}
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="row wh-0100">
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">
                  Correo electrónico
                </label>
              </div>
              <input
                {...formContacto.getFieldProps('email')}
                className="form-control"
                placeholder="Correo electrónico"
                type="email"
                name="email"
              />
              {formContacto.touched.email && formContacto.errors.email && (
                <div className="fv-plugins-message-container text-danger w-100">
                  <span role="alert">{formContacto.errors.email}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">Teléfono</label>
              </div>
              <PatternFormat
                className="form-control mb-3"
                {...formContacto.getFieldProps('telefono')}
                format="## #### #### ##### ####"
                allowEmptyFormatting
                autoComplete="new-password"
                // mask="_"
              />
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4">
            <div className="mb-3">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">
                  Fecha de nacimiento
                </label>
              </div>
              <input
                type="date"
                className={`form-control ${
                  formContacto.touched.fecha_nacimiento &&
                  formContacto.errors.fecha_nacimiento
                    ? 'is-invalid'
                    : ''
                }`}
                id="fecha_nacimiento"
                name="fecha_nacimiento"
                value={
                  formContacto.values.fecha_nacimiento
                    ? new Date(formContacto.values.fecha_nacimiento)
                        .toISOString()
                        .split('T')[0]
                    : ''
                }
                onChange={formContacto.handleChange}
                onBlur={formContacto.handleBlur}
              />
              {formContacto.touched.fecha_nacimiento &&
                formContacto.errors.fecha_nacimiento && (
                  <div className="invalid-feedback">
                    {formContacto.errors.fecha_nacimiento}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h3 className="card-title">Empleo</h3>
        </div>
        <div className="separator separator-dashed my-3" />
        <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5">
          <div className="w-100 d-flex">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className="fw-bold fs-6 mb-2 w-auto">Departamento</label>
              </div>
              <select
                className="form-select"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                {...formContacto.getFieldProps('departamento_id')}
              >
                <option value={0}>Seleccione</option>
                {departamentos.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {formContacto.touched.departamento_id &&
                formContacto.errors.departamento_id && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">
                      {formContacto.errors.departamento_id}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="fw-bold fs-6 mb-2 w-auto">Puesto</label>
            </div>
            <input
              {...formContacto.getFieldProps('puesto')}
              className="form-control"
              placeholder=""
              type="text"
              name="puesto"
            />
            {formContacto.touched.puesto && formContacto.errors.puesto && (
              <div className="fv-plugins-message-container text-danger w-100">
                <span role="alert">{formContacto.errors.puesto}</span>
              </div>
            )}
          </div>
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="fw-bold fs-6 mb-2 w-auto">Teléfono fijo</label>
            </div>
            <PatternFormat
              className="form-control mb-3"
              {...formContacto.getFieldProps('telefono_fijo')}
              format="## #### #### ##### ####"
              allowEmptyFormatting
              // mask="_"
            />
          </div>
          <div className="w-25 pe-5">
            <div className="w-100 d-flex">
              <label className="fw-bold fs-6 mb-2 w-auto">Ext.</label>
            </div>
            <PatternFormat
              className="form-control mb-3"
              {...formContacto.getFieldProps('extension')}
              format="####"
              allowEmptyFormatting
              // mask="_"
            />
          </div>
        </div>

        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={loading}
          >
            {!loading && (
              <span className="indicator-label">
                {id_row > 0 ? 'Actualizar' : 'Guardar'}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Espere por favor...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {Number(id_row) > 0 && (
        <div className="row g-6 g-xl-9" ref={sectionTabs}>
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="card card-flush p-5"> 
              <div className="row mb-5">
                <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                  <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'NOTE' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('NOTE');
                        }}
                      >
                        <span className="margin-left-05">Notas</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'ADDRESSES'
                            ? 'text-active-primary active'
                            : ''
                        } `}
                        onClick={() => {
                          setTabs('ADDRESSES');
                        }}
                      >
                        <span className="margin-left-05">Direcciones</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'DOCUMENTS'
                            ? 'text-active-primary active'
                            : ''
                        } `}
                        onClick={() => {
                          setTabs('DOCUMENTS');
                        }}
                      >
                        <span className="margin-left-05">Documentos</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {tabs === 'NOTE' ? (
                  <>
                    <NoteList
                      data={notes}
                      onOpenModal={openModal}
                      active={activeNewNote}
                      onOpenDetailModal={openDetailModal}
                    ></NoteList>
                  </>
                              ) : null}
                              {tabs === 'ADDRESSES' ? (
                                  <AddressList
                                      data={address}
                                      onOpenModal={openAddressModal}
                                      active={activeNewAddress}
                                      onEditAddress={openModalToEdit}
                                  />
                              ) : null}
                 
                {tabs === 'DOCUMENTS' ? (
                  <DocsList
                    documents={dataDocuments || []}
                    onRefreshData={() => {
                      setReloadDocuments(Math.random() * 100);
                      onRefreshData();
                    }}
                    contacto={Number(id_row)}
                  ></DocsList>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      <CreateNoteModal
        show={showNoteModal}
        handleClose={(refresh) => {
          setShowNoteModal(!showNoteModal);
          if (refresh) {
            setReloadGridNotes(Math.random() * 40);
              onRefreshData()
          }
        }}
        contacto_id={Number(id_row)}
      ></CreateNoteModal>
      <CreateNoteDetailModal
        show={showNoteDetailModal}
        handleClose={(refresh) => {
          setShowNoteDetailModal(!showNoteDetailModal);
          onRefreshData();
        }}
        data={notes}
        contactoId={Number(id_row)}
          ></CreateNoteDetailModal>
          <AddressModal
              isEditable={isEditable}
              show={showModalAddress}
              handleSubmit={onSaveAddress}
              handleClose={() => {
                  setShowModalAddress(false);
                  setReloadGridAddress(Math.random() * 40);
                  setIsEditable(false);
                  onRefreshData();
              }}
              dataEditable={addressInit}
          ></AddressModal>
    </>
  );
};
export { ContactoForm };
