
import { uploadFileToQuo, removeFileOfQuo } from '../../../../service/proyectos/Proyectos';
import { toast } from 'react-toastify';
import axios from 'axios';

export const uploadFile = async (
    file: any, id: any,
) => {

    const _file = file[0];
    await uploadFileToQuo(_file, id).then((res) => {
        toast.success(`${res.data.message}`, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });
    })
        .catch((err) => {
            let resMessageToast: string = '';
            const {
                data: { message },
            } = err.response;
            resMessageToast = `${message}`;
            // setUploadLoading(false);
            toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        });
};

export const downloadFile = (fileId: number, fileName: string, event: any) => {
    event.preventDefault();
    event.stopPropagation();

    axios({
        url: `${process.env.REACT_APP_API}proyectos/documentos/download/${fileId}`,
        method: 'GET',
        responseType: 'blob',
    })
        .then((response) => {
            const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = urlBlob;
            link.setAttribute('download', fileName);
            link.click();
            window.URL.revokeObjectURL(urlBlob);
        })
        .catch(async (error) => {
            // let responseObj = JSON.parse(await error.response.data.text());
        });
};

export const downloadQuotationFile = async (quotationId: number, fileName: string, event: any) => {
    event.preventDefault();
    event.stopPropagation();

    let data;

    await axios({
        url: `${process.env.REACT_APP_API}proyectos/pdf/download/${quotationId}`,
        method: 'GET',
        responseType: 'blob',
    })
        .then((response) => {
            data = response;
            const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = urlBlob;
            link.setAttribute('download', `${fileName}.pdf`);
            link.click();
            window.URL.revokeObjectURL(urlBlob);
        })
        .catch(async (error) => {
            data = JSON.parse(await error.response.data.text());
        });

        return data;
};

export const previewQuotationFile = async (quotationId: number) => {
    
    let responseData;

    try {
        // Reemplaza 'URL_DE_TU_API' con la URL real de tu API
        const response = await axios.get(`${process.env.REACT_APP_API}proyectos/pdf/view/${quotationId}`, {
          responseType: 'blob' // Importante para tratar la respuesta como un Blob
        });
        
        // Crear un URL para el Blob recibido
        responseData = response.data;
       
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }

      return responseData;
        
};

export const typeIconFile = (ext: string) => {

    let icon: string = 'generic';

    switch (ext) {
        case '.xlsx':
            icon = 'xlsx'
            break;
        case '.xls':
            icon = 'xlsx'
            break;
        case '.xlsm':
            icon = 'xlsx'
            break;
        case '.pdf':
            icon = 'pdf'
            break;
        case '.docx':
            icon = 'doc'
            break;
        case '.png':
            icon = 'png'
            break;
        case '.jpg':
            icon = 'jpg'
            break;
        case '.txt':
            icon = 'txt'
            break;
        default:
            icon = 'generic'
            break;
    }

    return icon;
};

export const removeFile = async (fileId: number, event: any) => {
    event.preventDefault();
    event.stopPropagation();

    await removeFileOfQuo(fileId).then((res) => {
        toast.success(`${res.data.message}`, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });
    })
        .catch((err) => {
            let resMessageToast: string = '';
            const {
                data: { message },
            } = err.response;
            resMessageToast = `${message}`;
            // setUploadLoading(false);
            toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        });

}