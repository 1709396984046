import { useHistory } from "react-router-dom";
import { getCompanyByUser, updateCompany } from "../../../service/company/Company";
import { useEffect, useState } from "react";

export const useGetOneCompany = (reloadData: any) => {

    const [data, setData] = useState<any>();

    const history = useHistory();
    
    const fetchPost = async () => {
        
        const res = await getCompanyByUser()
            .then()
            .catch((error) => {
                history.push('/error/500');
            });
        if (res) {
            setData(res);
        }
    };

    useEffect(() => {
        fetchPost();
    }, [reloadData]);

    return { data }
}

export const useUpdateCompany = () => {
    const history = useHistory();
    const updateRequest = async (generar_num_empleado: boolean, companyId: number) => {
      try {
        await updateCompany(generar_num_empleado, companyId);
      } catch (error) {
        history.push('/error/500');
      }
    };
    return { updateRequest };
  };