import React, { useState, useEffect } from 'react';
import { KTSVG, encryptId } from '../../../../../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import { dateFormatt } from '../../../../../../../_metronic/helpers/FormatDate';
import { NotaProyectoModel } from '../../../models/ProyectoModel';

type Props = {
  data: NotaProyectoModel[];
  onOpenModal: (isOpen: boolean) => void;
  active: boolean;
  onOpenDetailModal: (isOpen: boolean) => void;
};

const NoteList: React.FC<Props> = ({
  data,
  onOpenModal,
  active,
  onOpenDetailModal,
}) => {
  const [dataMemo, setDataMemo] = useState<NotaProyectoModel[]>(data);

  useEffect(() => {
    setDataMemo(data);
  }, [data]);

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <div className="row">
          <div className="col-12 text-end mb-5">
            <div
              className={'btn btn-sm btn-light-primary'}
              onClick={() => {
                onOpenDetailModal(true);
              }}
            >
              <KTSVG
                path="/media/icons/duotune/text/txt002.svg"
                className="svg-icon-5 svg-icon-gray-500 me-1"
              />
            </div>
          </div>
        </div>
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-200px">Nombre</th>
              <th className="w-200px">Creado por</th>
              <th className="w-100px">Fecha </th>
              <th className="w-100px">Fecha Modificación</th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      <Link
                        className="text-primary"
                        to={`/proyectos/${
                          row.proyecto?.id
                            ? encryptId(row.proyecto.id.toString())
                            : ''
                        }/notas/${encryptId(row.id.toString())}`}
                      >
                        {row.nombre}
                      </Link>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      <div className="symbol symbol-25px tooltip-soft">
                        {row.user_creador && (
                          <>
                            {row.user_creador.profile_photo ? (
                              <>
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC}users/${row.user_creador.profile_photo}`}
                                  crossOrigin="anonymous"
                                  className="symbol-label fs-7 "
                                  alt="user"
                                />
                                <span className="tooltiptext">{`${row.user_creador.first_name} ${row.user_creador.father_last_name} ${row.user_creador.mother_last_name}`}</span>
                              </>
                            ) : (
                              <>
                                <span className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold">
                                  {row.user_creador.first_name &&
                                  row.user_creador.father_last_name ? (
                                    <>{`${row.user_creador.first_name[0]}${row.user_creador.father_last_name[0]}`}</>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                                <span className="tooltiptext">{`${row.user_creador.first_name} ${row.user_creador.father_last_name} ${row.user_creador.mother_last_name}`}</span>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.created_at
                        ? dateFormatt(row.created_at)
                        : row.created_at}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.updated_at
                        ? dateFormatt(row.updated_at)
                        : row.updated_at}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={4}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={() => {
                      onOpenModal(true);
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar nota
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { NoteList };
