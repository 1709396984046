// import CryptoJS from 'crypto-js';

// const secretKey = process.env.REACT_APP_SECRET_KEY || '2024OppertimeSoftnet';

// export const encryptId = (id: string): string => {
//     const encrypted = CryptoJS.AES.encrypt(id, secretKey).toString();
//     const encodedEncrypted = encodeURIComponent(encrypted);
//     return encodedEncrypted;
// };
// export const decryptId = (encodedEncryptedId: string): string => {
//     const decodedEncryptedId = decodeURIComponent(encodedEncryptedId);
//     const bytes = CryptoJS.AES.decrypt(decodedEncryptedId, secretKey);
//     const decrypted = bytes.toString(CryptoJS.enc.Utf8);
//     return decrypted;
// };

import CryptoJS from 'crypto-js';
const secretKey = process.env.REACT_APP_SECRET_KEY || '';

export const encryptId = (id: string): string => {
    try {
        
        const encrypted = CryptoJS.AES.encrypt(id, secretKey).toString();
        return encodeURIComponent(encrypted);

    } catch (error) {
        console.error("Error al cifrar el ID:", error);
        throw new Error("No se pudo cifrar el ID.");
    }
};

export const decryptId = (encodedEncryptedId: string): string | null => {
    try {
        if(encodedEncryptedId){
            const decodedEncryptedId = decodeURIComponent(encodedEncryptedId);
            const bytes = CryptoJS.AES.decrypt(decodedEncryptedId, secretKey);
            const decrypted = bytes.toString(CryptoJS.enc.Utf8);
            
            if (!decrypted) {
                throw new Error("La descodificación falló o los datos son inválidos.");
            }
    
            return decrypted;
        }else{
            return null;
        }
    } catch (error) {
        console.error("Error al descifrar el ID:", error);
        throw new Error("No se pudo descifrar el ID.");
    }
};
