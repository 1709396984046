import axios from "axios";
import { SelectFormat } from "../../../_metronic/helpers/models/GenericModel";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { NotePackageModel } from "../../pages/notes_opp/models/NotePackageModel";

const API_URL = process.env.REACT_APP_API;
const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}paquete/`;

interface Producto {
  code_unidad_moneda: string;
  id: number;
  id_unidad_medida: number;
  precio_impuesto_real: number;
  name_unidad_medida: string;
  nombre: string;
  precio_unitario_real: number;
  sku: string;
  volumen: number;
  categoria: string;
}

interface Servicio {
  code_unidad_moneda: string;
  id: number;
  id_unidad_medida: number;
  precio_impuesto_real: number;
  name_unidad_medida: string;
  nombre: string;
  precio_unitario_real: number;
  sku: string;
  volumen: number;
  ren: number;
  cap: number;
  categoria: string;
}

export interface PaqueteData {
  id: number;
  nombre: string;
  sku: string;
  descripcion: string;
  package_photo: string;
  precio_unitario: number;
  categoria_id: number;
  sub_categoria_id: number;
  tipo_paquete_id: number;
  unidad_moneda_id: number;
  clasif_paquete_id: number;
  unidad_negocio_id: number;
  tipo_cambio: number;
  products: Producto[];
  services: Servicio[];
  existUpdatesProducts: boolean;
  existUpdatesServices: boolean;
  activo?: number
}

export async function getPackageById(packageId: number) {
  try {
    const random = Math.random() * 40;
    const response = await axios.get(
      `${API_URL}cotizacion/paquete/${packageId}`
    );
    const data = response.data.doc.data;
    let img = data.package_photo
          ? `${API_URL_IMAGE_PUBLIC}${data.package_photo}?${random}`
          : toAbsoluteUrl("/media/avatars/photo.png");
    data.package_photo = img;
    return data;
  } catch (error) {
    console.error("ERROR:", error);
    return undefined;
  }
}

export async function getCatalogCategories() {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/categoria/paquete`
  );
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  select.unshift({
    id: 0,
    value: "0",
    label: `Agregar categoría`,
    data: {},
  });

  return select;
}
export async function getCatalogSubcategories(categoryId: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete/categoria/${categoryId}`
  );
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  select.unshift({
    id: 0,
    value: "0",
    label: `Agregar subcategoría`,
    data: {},
  });
  return select;
}

export async function getCatalogProducts() {
  const response = await axios.post(`${API_URL}productos`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}
export async function getCatalogProductsCotizacion() {
  const response = await axios.post(`${API_URL}productos`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {

    if (item.tipoPrecioVenta && item.tipoPrecioVenta.code === 'TPVO') {
      item.precio_fijo_venta = item.precio_objetivo_calculado_venta;
    }
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}

export const addUpdatePackages = async (packageQua: any) => {
  try {
    const {
      id,
      nombre,
      descripcion,
      categoria_id,
      sub_categoria_id,
      tipo_paquete_id,
      unidad_moneda_id,
      clasif_paquete_id,
      unidad_negocio_id,
      products,
      services,
      image,
      update_products,
      update_services,
    } = packageQua;
    const formData = new FormData();
    formData.append("nombre", nombre);
    formData.append("descripcion", descripcion);
    formData.append("update_products", update_products);
    formData.append("update_services", update_services);
    if (categoria_id > 0) {
      formData.append("categoria_id", categoria_id);
    }
    if (sub_categoria_id > 0) {
      formData.append("sub_categoria_id", sub_categoria_id);
    }
    if (tipo_paquete_id > 0) {
      formData.append("tipo_paquete_id", tipo_paquete_id);
    }
    if (unidad_moneda_id > 0) {
      formData.append("unidad_moneda_id", unidad_moneda_id);
    }
    if (clasif_paquete_id > 0) {
      formData.append("clasif_paquete_id", clasif_paquete_id);
    }
    if (unidad_negocio_id > 0) {
      formData.append("unidad_negocio_id", unidad_negocio_id);
    }
    if (products.length) {
      formData.append("productos", JSON.stringify(products));
    }
    if (services.length) {
      formData.append("servicios", JSON.stringify(services));
    }
    if (image) {
      formData.append("package_photo", image);
    }
    let response;
    if (id) {
      response = await axios.patch(
        `${API_URL}cotizacion/paquete/${id}`,
        formData
      );
    } else {
      response = await axios.post(`${API_URL}cotizacion/paquete/add`, formData);
    }
    return response.data;

    // const response = await axios.post(
    //   `${API_URL}cotizacion/paquete/add`,
    //   formData
    // );
    // return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar paquete:", error);
    throw error;
  }
};

export const addCategoryPackages = async (category: any) => {
  try {
    const { nombre } = category;

    const response = await axios.post(
      `${API_URL}cotizacion/catalogo/categoria/paquete`,
      {
        nombre,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar paquete:", error);
    throw error;
  }
};

export const addSubcategoryPackages = async (
  subcategory: any,
  categoryId: number
) => {
  try {
    const { nombre } = subcategory;

    const response = await axios.post(
      `${API_URL}cotizacion/catalogo/subcategoria/paquete`,
      {
        nombre,
        categoria_id: categoryId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al agregar o actualizar paquete:", error);
    throw error;
  }
};

export async function getCatalogServices() {
  const response = await axios.post(`${API_URL}servicios`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}
export async function getCatalogServicesCotizacion() {
  const response = await axios.post(`${API_URL}servicios`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    if (item.tipoPrecioVenta && item.tipoPrecioVenta.code === 'TPVO') {
      item.precio_fijo_venta = item.precio_objetivo_calculado_venta;
    }
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}

export async function getCatalogPackages() {
  const response = await axios.post(`${API_URL}cotizacion/paquete`);
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}
// CATALOGO CATEGORIAS
export async function getCategoriesGrid() {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/categoria/paquete`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getDataCategoryById(id_row: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/categoria/paquete/${id_row}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function addValueCategory(body: any) {
  const response = await axios.post(
    `${API_URL}cotizacion/catalogo/categoria/paquete`,
    {
      nombre: body.nombre,
      orden: body.orden ? body.orden : null,
    }
  );
  return response;
}

export async function updValueCategory(body: any) {
  const data = {
    nombre: body.nombre,
    orden: body.orden,
  };

  const response = await axios.patch(
    `${API_URL}cotizacion/catalogo/categoria/paquete/${body.id}`,
    data
  );
  return response;
}

export async function removeRegisterCategory(id: number) {
  const response = await axios.delete(
    `${API_URL}cotizacion/catalogo/categoria/paquete/${id}`
  );

  return response;
}

// CATALOGO SUBCATEGORIAS

export async function getSubCategoriesGrid() {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getDataSubCategoryById(id_row: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete/${id_row}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function addValueSubCategory(body: any) {
  const response = await axios.post(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete`,
    {
      nombre: body.nombre,
      orden: body.orden ? body.orden : null,
      categoria_id: body.categoria_id,
    }
  );
  return response;
}

export async function updValueSubCategory(body: any) {
  const data = {
    nombre: body.nombre,
    orden: body.orden,
    categoria_id: body.categoria_id,
  };

  const response = await axios.patch(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete/${body.id}`,
    data
  );
  return response;
}

export async function removeRegisterSubCategory(id: number) {
  const response = await axios.delete(
    `${API_URL}cotizacion/catalogo/subcategoria/paquete/${id}`
  );

  return response;
}

export async function getHistoryPackages(search: string, id: string) {
  const response = await axios.get(
    `${API_URL}cotizacion/paquete/historial/${id}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function createHistoryMessagePackages(
  message: string,
  id: number
) {
  const response = await axios.post(
    `${API_URL}cotizacion/paquete/historial/comentario`,
    {
      packageId: id,
      comentario: message,
    }
  );
  return response;
}

export async function getNotesByPackageId(id: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/paquete/notas/byPaquete/${id}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function insertNotePackage(note: NotePackageModel, id: number) {
  const response = await axios.post(`${API_URL}cotizacion/paquete/notas`, {
    nombre: note.nombre,
    descripcion: note.descripcion,
    paquete_id: Number(id),
  });
  return response;
}

export const uploadFileToPackage = async (file: any, id: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("packageId", id);

  const response = await axios.post(
    `${API_URL}cotizacion/paquete/documentos`,
    formData
  );
  return response;
};
export const removeFileOfPackage = async (id: number) => {
  const response = await axios.delete(
    `${API_URL}cotizacion/paquete/documentos/${id}`
  );
  return response;
};
export async function getDocumentsByPackageId(id: number) {
  const response = await axios.get(
    `${API_URL}cotizacion/paquete/documentos/${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export const addCreatePackageCopy = async (id: number) => {
  try {
    const response = await axios.post(
      `${API_URL}cotizacion/paquete/copy/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al copiar paquete:", error);
    throw error;
  }
};

export const deletePackageCurrentService = async (id: number) => {
  try {
    const response = await axios.delete(`${API_URL}cotizacion/paquete/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el paquete:", error);
    throw error;
  }
};

export const updateCosts = async (id: number) => {
  try {
    const response = await axios.patch(
      `${API_URL}cotizacion/paquete/updates/prod/serv`,
      {
        id,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al actualizar los conceptos:", error);
    throw error;
  }
};

export async function getDataFilterBySelectFiledPackages(code: string) {
  let response;
  let data;

  switch (code) {
    case "PAQ_NOM":
      response = await axios.post(`${API_URL}cotizacion/paquete`, {
        campo_id: null,
        valor: null,
      });
      data = response.data.doc.data;
      break;
    case "PAQ_UNEG":
      response = await axios.get(`${API_URL}catalogs/UNEG?sort=name`);
      data = response.data.doc.data.rows;
      break;
    case "PAQ_TIPO":
      response = await axios.get(`${API_URL}catalogs/TPACK?sort=name`);
      data = response.data.doc.data.rows;
      break;

    default:
      break;
  }
  return data;
}

export async function getCatFieldsPackages() {
  const response = await axios.get(`${API_URL}catalogs/campos/TBL_PAQ`);
  const data = response.data.doc.data;
  const codesToFilter: string[] = ["PAQ_NOM", "PAQ_UNEG", "PAQ_TIPO", "PAQ_PU"];
  const dataFilter = data.filter((obj: any) =>
    codesToFilter.includes(obj.code)
  );
  return dataFilter;
}

export const estatusPaquete = async (id: number, estatus: number) => {
  try {
    const response = await axios.patch(`${API_URL}/cotizacion/paquete/${id}/estatus`, {
      estatus: estatus,
    });
    return response;
  } catch (error) {
    console.error("Error actualizando el estatus del paquete:", error);
    throw error;
  }
};
export const eliminarPaquete = async (id: number) => {
  try {
    const response = await axios.delete(`${API_URL}/cotizacion/paquete/${id}`);
    return response;
  } catch (error) {
    console.error("Error al eliminar el paquete:", error);
    throw error;
  }
};
export const validarPaquete = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}/cotizacion/paquete/${id}/validar`);
    return response;
  } catch (error) {
    console.error("Error actualizando el estatus del paquete:", error);
    throw error;
  }
};