import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { encryptId } from '../../../../_metronic/helpers';
import DataTable, { TableColumn } from 'react-data-table-component';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';
import { dateFormatt } from '../../../../_metronic/helpers/FormatDate';
import {
  colorStatusOpportunity,
  colorStatusTask,
} from '../../../../_metronic/helpers/CustomElements';
import { KTSVG } from '../../../../_metronic/helpers';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import { uploadFile } from '../../oportunidades/helpers/FileOpportunity';
import { EmptyResult } from '../../../../_metronic/helpers/components/EmptyResult';

type Props = {
  tareas: any;
  cargandoTareas: boolean;
};

const ListadoTabla: React.FC<Props> = ({ tareas, cargandoTareas }) => {
  const history = useHistory();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (tareas) {
      setData(tareas);
    }
    return () => {
      setData([]);
    };
  }, [tareas]);

  const columns: TableColumn<any>[] = [
    {
      name: '',
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <>
          <span
            className="badge fs-7 fw-bold mr-15 min-w-150px"
            style={{
              color: '#fff',
              backgroundColor: row.color ? row.color : '#9096EC',
            }}
          >
            {row.nombre}
          </span>
          <div
            className="badge badge-light-primary fs-7 fw-bold mr-15"
            title="Total de tareas"
          >
            {row.items.length}
          </div>
          {row.code !== 'TCANC' && row.code !== 'TCOMPL' ? (
            <div
              className="cursor-pointer badge badge-light-primary"
              title="Agregar tarea"
              onClick={() => {
                history.push(
                  `/bitacora/tarea/add/${encryptId(row.etapaId.toString())}`
                );
              }}
            >
              + Agregar tarea
            </div>
          ) : null}
        </>
      ),
      sortable: false,
      width: '250px',
    },
  ];

  return (
    <>
      {cargandoTareas ? (
        <>
          <EmptyResult title="" loading={cargandoTareas} />
        </>
      ) : (
        <>
          <DataTable
            columns={columns}
            data={data}
            responsive
            expandableRows
            expandableRowsComponent={expandedComponent}
            noDataComponent={
              <>
                <div className="d-flex align-items-center">
                  <div className="text-muted text-center fs-6 w-100 m-5">
                    Sin registros
                  </div>
                </div>
              </>
            }
            onRowExpandToggled={(event) => {
              // if (event) setReload(Math.random() * 100);
            }}
          />
        </>
      )}
    </>
  );
};

const expandedComponent = (d: any) => {
  const columns_section: any = [
    {
      id: 'nombre',
      name: (
        <div className="fw-bolder text-muted me-5 ">Nombre de la tarea</div>
      ),
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <>
          {row.oportunidad ? (
            <>
              <Link
                to={`/oportunidades_tareas/${
                  row.oportunidad?.id
                    ? encryptId(row.oportunidad.id.toString())
                    : ''
                }/edit/${encryptId(row.id.toString())}`}
              >
                {row.nombre}
              </Link>
            </>
          ) : (
            <>
              <Link to={`/bitacora/tarea/edit/${encryptId(row.id.toString())}`}>
                {`${row.nombre}`}
              </Link>
            </>
          )}
        </>
      ),
      sortable: false,
    },
    {
      id: 'tipo_tarea',
      name: <div className="fw-bolder text-muted me-5">Tipo de tarea</div>,
      selector: (row: any) => (
        <>
          {row.oportunidad ? (
            <>
              <div className="text-gray-700">Oportunidad</div>
            </>
          ) : (
            <>
              <div className="text-gray-700">{row?.tipoTarea?.name}</div>
            </>
          )}
        </>
      ),
      sortable: false,
      center: true,
    },
    {
      id: 'asignado_a',
      name: <div className="fw-bolder text-muted">Asignado a</div>,
      selector: (row: any) =>
        `${row?.user_asignado?.first_name} ${row?.user_asignado?.father_last_name}`,
      cell: (row: any) => (
        <>
          <div className="symbol symbol-30px tooltip-soft">
            {row.user_asignado ? (
              <>
                {row.user_asignado?.profile_photo ? (
                  <>
                    <img
                      src={`${process.env.REACT_APP_PUBLIC}users/${row?.user_asignado?.profile_photo}`}
                      crossOrigin="anonymous"
                      className="symbol-label fs-8 "
                      alt="user"
                    />
                    <span className="tooltiptext">{`${row?.user_asignado?.first_name} ${row?.user_asignado?.father_last_name} ${row?.user_asignado?.mother_last_name}`}</span>
                  </>
                ) : (
                  <>
                    <span className="symbol-label fs-8 fw-bold bg-light-primary text-primary fw-bold">
                      {`${row?.user_asignado?.first_name[0]}${row?.user_asignado?.father_last_name[0]}`}
                    </span>
                    <span className="tooltiptext">{`${row?.user_asignado?.first_name} ${row?.user_asignado?.father_last_name} ${row?.user_asignado?.mother_last_name}`}</span>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="text-gray-700">No asignado</div>
              </>
            )}
          </div>
        </>
      ),
      sortable: false,
      center: true,
    },

    {
      id: 'esfuerzo',
      name: <div className="fw-bolder text-muted me-5">Esfuerzo</div>,
      selector: (row: any) => 0,
      cell: (row: any) => <div className="fw-bolder text-gray-700">0 h.</div>,
      sortable: false,
      center: true,
    },
    {
      id: 'porcentaje_avance',
      name: <div className="fw-bolder text-muted">% de avance</div>,
      selector: (row: any) => row.avance,
      cell: (row: any) => (
        <div className="fw-bolder text-gray-700">{row.avance || 0}</div>
      ),
      sortable: false,
      center: true,
    },
    {
      id: 'Estatus',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row?.statusTarea?.name,
      cell: (row: any) => (
        <div
          className={`${
            row.statusTarea ? colorStatusTask(row.statusTarea.code) : ''
          }`}
        >
          {row?.statusTarea ? row.statusTarea.name : 'N/A'}{' '}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  return (
    <>
      <DataTable
        className="mb-5"
        columns={columns_section}
        data={d.data.items}
        customStyles={{
          table: {
            style: {
              width: '900px',
              minWidth: '100%',
            },
          },
        }}
        responsive
        noDataComponent={
          <>
            <div className="d-flex align-items-center ">
              <div className="row">
                <div className="col-xxl-12 col-xl-12 col-md-12 collg-12 text-center wh-0100">
                  <div className="text-muted fs-6 w-100 m-5">Sin registros</div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export { ListadoTabla };
