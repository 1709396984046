import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { encryptId, KTSVG } from '../../../../../../../_metronic/helpers';
import { styleSelect } from '../../../../../../../_metronic/helpers/SelectStyles';
import { TareaProyectoModel } from '../../../models/ProyectoModel';
import { colorStatusTask } from '../../../../../../../_metronic/helpers/CustomElements';
import {
  useGetUsersTasks,
  useGetUsersTasksBitacora,
} from '../../../../../tasks/hooks/TaskHook';
import { useSelectTipoTareas } from '../../../hooks/ProyectosHook';
import { Link } from 'react-router-dom';
import { validarTareas } from '../../../../../../service/proyectos/Proyectos';

type Props = {
  indexSection: number;
  data: TareaProyectoModel[];
  actualizarTareas: (
    tareas: TareaProyectoModel[],
    indexSection: number
  ) => void;
  tareaRemove: (id: number) => void;
};
const TaskList: React.FC<Props> = ({
  indexSection,
  data,
  actualizarTareas,
  tareaRemove,
}) => {
  const { optionsSelect: tipoTareas } = useSelectTipoTareas('TTASK', true);
  const { dataSelect: usuarios } = useGetUsersTasksBitacora();
  const [cargandoEliminacionTarea, setCargandoEliminacionTarea] = useState<
    number | null
  >(null);

  const [dataMemo, setDataMemo] = useState<TareaProyectoModel[]>(data);
  const inputRef = useRef<HTMLInputElement>(null);

  const AddRow = () => {
    let { code, label, value } = tipoTareas.find(
      (option) => option.code === 'ACT'
    );
    const newRow: TareaProyectoModel = {
      id: 0,
      referencia: numeroRandom(),
      nombre: 'Nueva tarea',
      tipoTarea: {
        id: value,
        name: label,
        code: code,
      },
      user_asignado: {
        id: 0,
        email: '',
        first_name: '',
        father_last_name: '',
        mother_last_name: '',
        phone: '',
        profile_photo: '',
      },
      statusTarea: {
        id: 0,
        name: 'No iniciada',
        code: '',
      },
      avance: 0,
    };
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = [...dataMemo, newRow];
      actualizarTareas(updatedDataMemo, indexSection);
      return updatedDataMemo;
    });
  };

  const tipoTareaChange = (selectedOption: any, referencia: number) => {
    if (selectedOption) {
      const { value, label, code } = selectedOption;
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item) => {
          if (item.referencia === referencia) {
            return {
              ...item,
              tipoTarea: {
                id: value,
                name: label,
                code: code,
              },
            };
          }
          return item;
        });
        actualizarTareas(updatedDataMemo, indexSection);
        return updatedDataMemo;
      });
    }
  };
  const usuarioChange = (selectedOption: any, referencia: number) => {
    if (selectedOption) {
      const { value, label } = selectedOption;
      setDataMemo((prevDataMemo) => {
        const updatedDataMemo = prevDataMemo.map((item) => {
          if (item.referencia === referencia) {
            return {
              ...item,
              user_asignado: {
                id: value,
                email: '',
                first_name: label,
                father_last_name: '',
                mother_last_name: '',
                phone: '',
                profile_photo: '',
              },
            };
          }
          return item;
        });
        actualizarTareas(updatedDataMemo, indexSection);
        return updatedDataMemo;
      });
    }
  };
  const numeroRandom = (): number => {
    return Math.floor(10000 + Math.random() * 90000);
  };

  const nombreChange = (nombre: string, referencia: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.referencia === referencia) {
          return {
            ...item,
            nombre: nombre,
          };
        }
        return item;
      });
      actualizarTareas(updatedDataMemo, indexSection);
      return updatedDataMemo;
    });
  };
  const handleInputBlur = (referencia: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.referencia === referencia) {
          return {
            ...item,
            nombre: 'Nueva tarea',
          };
        }
        return item;
      });
      actualizarTareas(updatedDataMemo, indexSection);
      return updatedDataMemo;
    });
  };

  useEffect(() => {
    setDataMemo(data);

    return () => {
      setDataMemo([]);
    };
  }, [data]);

  const validarTarea = async (
    id: number,
    nombre: string,
    tarea: TareaProyectoModel
  ) => {
    setCargandoEliminacionTarea(id);
    if (tarea.id) {
      await validarTareas(id)
        .then((res) => {
          const { tareaConAvance } = res.data.doc.data;
          if (tareaConAvance) {
            Swal.fire({
              title: '¡Tarea con avance!',
              html: `
                <div className="text-gray-600">
                 "La tarea tiene avance o esfuerzo registrado. Al eliminarla, esta información se perderá. ¿Deseas continuar?"
                </div>
                `,
              showCancelButton: true,
              showConfirmButton: true,
              padding: '2em',
              cancelButtonText: 'Cerrar',
              confirmButtonText: 'Aceptar',
              reverseButtons: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light me-3',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                tareaRemove(tarea.referencia);
                setCargandoEliminacionTarea(null);
              } else {
                setCargandoEliminacionTarea(null);
              }
            });
          } else {
            Swal.fire({
              title: '¿Deseas eliminar la tarea?',
              html: `
                <div className="text-gray-600">
                  Se va a eliminar la tarea "<b>${nombre}</b>".
                </div>
                `,
              showCancelButton: true,
              padding: '2em',
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Aceptar',
              reverseButtons: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light me-3',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                tareaRemove(tarea.referencia);
                setCargandoEliminacionTarea(null);
              } else {
                setCargandoEliminacionTarea(null);
              }
            });
          }
        })
        .catch((err) => {
          const {
            data: { message },
          } = err.response;
          const errorMessage = message || 'Error al eliminar la tarea.';
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setCargandoEliminacionTarea(null);
        });
    } else {
      Swal.fire({
        title: '¿Deseas eliminar la tarea?',
        html: `
            <div className="text-gray-600">
              Se va a eliminar la tarea "<b>${nombre}</b>".
            </div>
            `,
        showCancelButton: true,
        padding: '2em',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light me-3',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          tareaRemove(tarea.referencia);
          setCargandoEliminacionTarea(null);
        } else {
          setCargandoEliminacionTarea(null);
        }
      });
    }
  };

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-200px">Nombre</th>
              <th className="w-150px">Tipo de tarea</th>
              <th className="w-150px">Asignado a</th>
              <th className="w-150px">% de avance</th>
              <th className="w-100px">Estatus</th>
              <th className="w-100px"></th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={row.referencia}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-7 text-gray-600 w-100">
                        {row.id > 0 ? (
                          <>
                            <Link
                              to={`/bitacora/tarea/edit/${encryptId(
                                row.id.toString()
                              )}`}
                            >
                              {`${row.nombre}`}
                            </Link>
                          </>
                        ) : (
                          <input
                            type="text"
                            value={row.nombre}
                            className="w-100 form-control"
                            ref={inputRef}
                            maxLength={100}
                            placeholder={row.nombre}
                            onChange={(e) => {
                              let nombre = e.target.value;
                              nombreChange(nombre, row.referencia);
                            }}
                            onBlur={() => {
                              if (!row.nombre.trim()) {
                                // Validación en onBlur
                                handleInputBlur(row.referencia);
                              }
                              // if (!row.nombre) {
                              //   handleInputBlur(row.referencia);
                              // }
                            }}
                          ></input>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600  w-100">
                        {row.id > 0 ? (
                          <span className="fw-bolder">
                            {row.tipoTarea.name}
                          </span>
                        ) : (
                          <Select
                            key={row.id}
                            options={tipoTareas}
                            defaultValue={tipoTareas.find(
                              (option) => option.code === 'ACT'
                            )}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={(e) => {
                              tipoTareaChange(e, row.referencia);
                            }}
                            backspaceRemovesValue
                          />
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600 w-100">
                        {row.id > 0 ? (
                          <>
                            {row.user_asignado ? (
                              <>
                                {row.user_asignado.profile_photo ? (
                                  <>
                                    <div className="symbol symbol-30px tooltip-soft w-50">
                                      <img
                                        src={`${process.env.REACT_APP_PUBLIC}users/${row.user_asignado.profile_photo}`}
                                        title={`${row.user_asignado.first_name} ${row.user_asignado.father_last_name} ${row.user_asignado?.mother_last_name}`}
                                        crossOrigin="anonymous"
                                        className="symbol-label fs-8"
                                        alt="user"
                                      />
                                      <span className="tooltiptext">{`${row.user_asignado.first_name} ${row.user_asignado.father_last_name} ${row.user_asignado.mother_last_name}`}</span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="symbol symbol-30px tooltip-soft w-50">
                                      <span className="symbol-label fs-8 fw-bold bg-light-primary text-primary fw-bold">
                                        {row.user_asignado.first_name &&
                                          row.user_asignado.first_name[0]}
                                        {row.user_asignado.father_last_name &&
                                          row.user_asignado.father_last_name[0]}
                                      </span>
                                      <span className="tooltiptext">{`${row.user_asignado.first_name} ${row.user_asignado.father_last_name} ${row.user_asignado.mother_last_name}`}</span>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="text-gray-700">No asignado</div>
                              </>
                            )}
                          </>
                        ) : (
                          <Select
                            key={row.id}
                            options={usuarios}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={(e) => {
                              usuarioChange(e, row.referencia);
                            }}
                            backspaceRemovesValue
                          />
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600 w-50">
                        <span
                          className={`badge fs-base ${
                            row.avance > 0
                              ? 'badge-light-success'
                              : 'badge-light'
                          }`}
                        >
                          {' '}
                          {row.avance}%
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600  w-75">
                        <div
                          className={`${colorStatusTask(row.statusTarea.code)}`}
                        >
                          {row.statusTarea.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <>
                        {cargandoEliminacionTarea === row.referencia ? (
                          <>
                            <div className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2">
                              <span
                                className="indicator-progress"
                                style={{ display: 'block' }}
                              >
                                <span className="spinner-border spinner-border-sm align-middle"></span>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="btn btn-sm btn-active-light-danger btn-icon btn-light-primary ms-2"
                              onClick={(event) => {
                                validarTarea(
                                  Number(row.referencia),
                                  row.nombre,
                                  row
                                );
                              }}
                              title="Eliminar tarea"
                            >
                              <KTSVG path="/media/icons/duotune/general/gen027.svg" />
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center text-muted">
                  Sin tareas
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={6}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Tarea
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { TaskList };
