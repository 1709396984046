import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  getAllCollaborators,
  getCatFieldsCollaborators,
  getCollaboratorById,
  getDataFilterBySelectFiledCollaborator,
  getHistory,
} from "../../../service/collaborator/Collaborator";
import { getCountriesSelect } from "../../../service/country/Country";
import { CollaboratorModel, Contacto } from "../models/CollaboratorModel";
import { GenericCatalogModel, SelectFormat } from "../../../../_metronic/helpers/models/GenericModel";
import { getCatalog } from "../../../service/catalog/Catalogs";
import { GenericoModel } from "../../catalogos/generico/models/GenericoModel";
import { HistoryModel } from "../models/HistoryModel";

export const useGetAllCollaborators = (realoadGrid: number, filters: any, startIndex: number, endIndex: number) => {
  const [collaboartorsData, setCollaboratorsData] = useState<
    CollaboratorModel[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      const res = await getAllCollaborators(filters, startIndex, endIndex)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setCollaboratorsData(res.data);
        setLoading(false);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid, filters, module]);

  return { collaboartorsData, loading };
};

export const useGetCollaborator = (id: number) => {
  const initModel: CollaboratorModel = {
    id: 0,
    imagen: "",
    numero_empleado: "",
    telefono: "",
    email: "",
    celular: "",
    fecha_alta: "",
    rfc: "",
    tipo: {
      id: 0,
      name: "",
      code: "",
    },
    nss: "",
    contacto: {
      id: 0,
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      curp: "",
      lugar_nacimiento: "",
      genero: {
        id: 0,
        name: "",
        code: "",
      },
      pais: {
        id: 0,
        nombre: "",
      },
    },
    perfil: {
      id: 0,
      name: "",
      code: "",
    },
    empleos: [],
  };

  const [initValues, setInitValues] = useState<any>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      await getCollaboratorById(id)
        .then((res) => {
          if (res) {
            setInitValues(res);
            setLoadingRequest(false);
          }
        })
        .catch((error) => {
          history.push("/error/500");
        });
    };
    if (id > 0) {
      fetchPost();
    } else {
      setInitValues(initModel);
      setLoadingRequest(false);
    }
  }, [id]);

  return { initValues, loadingRequest };
};

export const useGetFieldsCollaborators = () => {
  const [listFields, setlistFields] = useState<GenericCatalogModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getCatFieldsCollaborators()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        const selectOptions = res.map((option: any) => ({
          value: option.id,
          label: `${option.name} `,
          code: option.code,
        }));
        setlistFields(selectOptions);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, []);

  return {
    listFields,
    loadingRequest,
  };
};

export const useGetDataSelectedByFieldCollaborator = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getDataFilterBySelectFiledCollaborator(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        let selectOptions;
        if(code === 'COLA_NOM'){
          selectOptions = res.map((option: any) => ({
            value: option.id,
            label: `${option?.contacto?.nombre} ${option?.contacto?.apellido_paterno} ${option?.contacto?.apellido_materno}`, 
          }));
        }else{
        selectOptions = res.map((option: any) => ({
          value: option.id,
          label: `${option?.name}`,
        }));
      }

        setDataOptionSelected(selectOptions);
        setLoadingRequest(1);
      }
    };

    if (code) {
      fetchPost();
    }
  }, [code]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};

export const useSelectCountry = () => {
  const [countries, setCountries] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCountriesSelect()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (data) {
        setCountries(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { countries, loadingRequest }
}

const formmatedDataToSelect = (data: any) => {
  const selectOptions = data.map((option: any) => ({
    value: option.id,
    label: option?.name,
  }));

  return selectOptions;
};

export const useGetProfileSelect = (
  code: string,
  realoadGrid?: number,
) => {
  const [dataPerfiles, setDataPerfiles] = useState<GenericoModel[]>([]);
  const [profileDataSelect, setProfileDataSelect] = useState<any[]>([]);

  const [profile, setProfile] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        let options: any = [];
        setDataPerfiles(res.rows);
        options = formmatedDataToSelect(res.rows);
        setProfileDataSelect((prevState) => [
          {
            value: 'new',
            label: 'Agregar perfil'
          },
          ...options,
        ]);
        setProfile(res);
        setLoadingRequest(1);
      }
    };


    fetchPost();
  }, [realoadGrid]);

  return { dataPerfiles, profileDataSelect };
};

export const useGetTypeCollabSelect = (
  code: string,
  realoadGrid?: number,
) => {
  const [dataCollabType, setDataCollabType] = useState<GenericoModel[]>([]);
  const [collabTypeDataSelect, setCollabTypeDataSelect] = useState<any[]>([]);

  const [collabType, setCollabType] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const res = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        let options: any = [];
        setDataCollabType(res.rows);
        options = formmatedDataToSelect(res.rows);
        setCollabTypeDataSelect((prevState) => [
          ...options,
        ]);
        setCollabType(res);
        setLoadingRequest(false);
      }
    };


    fetchPost();
  }, [realoadGrid]);

  return { dataCollabType, collabTypeDataSelect, loadingRequest };
};


export const useGetPositionSelect = (
  code: string,
  realoadGrid?: number,
) => {
  const [dataPuestos, setDataPuestos] = useState<GenericoModel[]>([]);
  const [positionDataSelect, setPositionDataSelect] = useState<any[]>([
    {
      value: 'new',
      label: 'Agregar puesto',
    },
  ]);
  const [position, setPosition] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        let options: any = [];
        setDataPuestos(res.rows);
        options = formmatedDataToSelect(res.rows);
        setPositionDataSelect((prevState) => [
          {
            value: 'new',
            label: 'Agregar puesto',
          },
          ...options,
        ]);
        setPosition(res);
        setLoadingRequest(1);
      }
    };


    fetchPost();
  }, [realoadGrid]);

  return { dataPuestos, positionDataSelect };
};

export const useGetDepartmentSelect = (
  code: string,
  realoadGrid?: number,
) => {
  const [dataDepartamentos, setDataDepartamentos] = useState<GenericoModel[]>([]);
  const [departmentDataSelect, setDepartmentDataSelect] = useState<any[]>([]);

  const [department, setDepartment] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        let options: any = [];
        setDataDepartamentos(res.rows);
        options = formmatedDataToSelect(res.rows);
        setDepartmentDataSelect((prevState) => [
          ...options,
        ]);
        setDepartment(res);
        setLoadingRequest(1);
      }
    };


    fetchPost();
  }, [realoadGrid]);

  return { dataDepartamentos, departmentDataSelect };
};

export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistory(search, id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};

