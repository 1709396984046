// import { TareaModel, TaskDocModel } from '../models/TaskModel';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getNoteProjectByID,
  updateNoteProject,
} from '../../../service/proyectos/Proyectos';
import { NotaProyectoModel } from '../../bitacora/proyectos/models/ProyectoModel';

export const useGetNoteProjectByID = (id: string, reload: number) => {
  const note: NotaProyectoModel = {
    id: 0,
    nombre: '',
    descripcion: '',
  };
  const [noteInit, setNoteInit] = useState<NotaProyectoModel>(note);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getNoteProjectByID(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setNoteInit(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setNoteInit(note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { noteInit };
};

export const useEditNoteProject = () => {
  const [loadingEditNote, setLoadingEditNote] = useState<boolean>(false);
  const history = useHistory();
  const editNote = async (note: NotaProyectoModel) => {
    setLoadingEditNote(false);
    try {
      await updateNoteProject(note);
      setLoadingEditNote(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingEditNote(false);
    }
  };
  return { editNote, loadingEditNote };
};
