import React, { useState, useEffect } from 'react';
import { TareaForm } from './TareaForm';

type Props = {
  tareaId: number;
  etapaId: number;
};

const View: React.FC<Props> = ({ tareaId, etapaId }) => {
  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ">
          <TareaForm
            tareaId={tareaId}
            etapaId={etapaId}
            // onRefreshHistory={onRefreshData}
            // onDisabledHistory={onDisabledHistory}
          />
        </div>
        {/* <div style={historyStyle}>
          {Number(id) > 0 && showTimerHistory && (
            <>
              <div className="flex-column flex-lg-row-auto w-100 w-xl-400px ms-lg-10">
                <History
                  data={data}
                  onSaveMessage={saveMessage}
                  disabled={historyIsEdit}
                  onShow={() => {
                    setShowHistory(!showHistory);
                    const boolean = !showHistory;
                    localStorage.setItem('isOpenHistory', boolean.toString());
                  }}
                />
              </div>
            </>
          )}
        </div> */}
        {/* {!showHistory && Number(id) > 0 && (
          <Toggle
            onShow={() => {
              setShowHistory(!showHistory);
              setShowTimerHistory(true);
              const boolean = !showHistory;
              localStorage.setItem('isOpenHistory', boolean.toString());
            }}
          />
        )} */}
      </div>
    </>
  );
};

export { View };
