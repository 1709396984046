import axios from "axios";
import { TareaBitacoraModel } from "../../pages/tasks/models/TaskModel";
import { SelectFormat } from "../../../_metronic/helpers/models/GenericModel";

const API_URL = process.env.REACT_APP_API;

export const obtenerTareaPorId = async (tarea_id: number) => {
  try {
    const response = await axios.get(`${API_URL}/bitacora/tareas/${tarea_id}`);
    const { data } = response.data.doc;
    const { id, nombre, avances, descripcion, avance, statusTarea, etapa, prioridad, user_asignado,
      tipoTarea, fecha_fin, fecha_inicio, unidadEsfuerzo, recursos,
      fecha_inicio_avance,
      fecha_fin_avance,
      esfuerzo_real_avance
    } = data;
    let fecha_inicio_init = new Date(fecha_inicio).toISOString().split('T')[0];
    let fecha_inicio_fin = new Date(fecha_fin).toISOString().split('T')[0];

    let fecha_inicio_avance_init = fecha_inicio_avance ? new Date(fecha_inicio_avance).toISOString().split('T')[0] : "";
    let fecha_fin_avance_fin = fecha_fin_avance ? new Date(fecha_fin_avance).toISOString().split('T')[0]: "";

    const formatoRecursos = recursos.map((item: any) => ({
      ...item, id_registro: item.id, id:item.colaborador.id
    }));
    const formatoAvances = avances.map((item: any) => ({
      ...item, fecha: new Date(item.fecha).toISOString().split('T')[0], id_registro: item.id, id:item.recurso.id
    }));

    const nuevaTarea: TareaBitacoraModel = {
      id: id,
      nombre: nombre,
      descripcion: descripcion,
      avance: avance,
      esfuerzo: 0,
      fecha_inicio: fecha_inicio_init,
      fecha_fin: fecha_inicio_fin,
      fecha_inicio_avance: fecha_inicio_avance_init,
      fecha_fin_avance: fecha_fin_avance_fin,
      esfuerzo_real_avance: esfuerzo_real_avance,
      statusTarea: statusTarea,
      tipoTarea: tipoTarea,
      etapa: etapa,
      prioridad: prioridad,
      oportunidad: null,
      user_asignado: user_asignado,
      etapa_id: etapa ? etapa.id: 0,
      prioridad_id: prioridad ? prioridad.id : 0,
      status_id: statusTarea ? statusTarea.id: 0,
      user_asignado_id: user_asignado ? user_asignado.id : 0,
      tipo_tarea_id: tipoTarea ? tipoTarea.id : 0,
      unidad_esfuerzo_id: unidadEsfuerzo ? unidadEsfuerzo.id : 0,
      recursos:formatoRecursos,
      unidadEsfuerzo: unidadEsfuerzo,
      avances: formatoAvances
    };
    return nuevaTarea;
  } catch (error) {
    console.error("Error al obtener las tareas:", error);
    throw error;
  }
};

export const obtenerTareasPorEtapa = async (filter: any) => {
  try {

    const { campo_id, valor } = filter;
    const data_filter = {
        campo_id, valor
    }
    const response = await axios.post(`${API_URL}bitacora/tareas/filtros/`, data_filter);
    const tareas = response.data.doc.data;
    const tareasPorEtapa = tareas.map((item: any) => {
      const tarjetas = item.items.map((element: any) => {
        return {
          avance: element.avance || 0,
          descripcion: element.descripcion,
          etapa: element.etapa,
          fecha_fin: element.fecha_fin,
          fecha_inicio: element.fecha_inicio,
          id: element.id,
          nombre: element.nombre,
          oportunidad: element.oportunidad,
          prioridad: element.prioridad,
          statusTarea: element.statusTarea,
          tipoTarea: element.tipoTarea,
          user_asignado: element.user_asignado,
          user_creador: element.user_creador,
          draggable: item.code === "TCOMPL" ? false : true,
          laneId: element.etapa.id,
        };
      })
      return {
        id: item.code,
        etapa_id: item.etapaId,
        title: item.nombre,
        code: item.code,
        color_etapa: item.color,
        label: 1,
        style: { width: 330 },
        currentPage: 1,
        disallowAddingCard: true,
        cards: tarjetas,
      }
    });
    return {
      tareas,
      tareasPorEtapa
    };
  } catch (error) {
    console.error("Error al obtener las tareas:", error);
    throw error;
  }
};

export const crearEditarTareaService = async (values: TareaBitacoraModel) => {
  try {
    const { id, recursos, avance, avances, esfuerzo, descripcion, etapa_id, fecha_fin, fecha_inicio, nombre, prioridad_id, tipo_tarea_id, user_asignado_id, unidad_esfuerzo_id } = values;
    let response = {
      data: {
        doc: {
          data: {
            message: ""
          }
        },
        message: ""
      }
    };
  
      const formatoRecursos = recursos.map((item) => ({
        id: item.id_registro,
        tarea_id: id,
        perfil_id: item.perfil?.id,
        colaborador_id: item.colaborador?.id,
        unidad_esfuerzo_id: unidad_esfuerzo_id,
        esfuerzo: item.esfuerzo,
        costo: item.costo,
      }));

      const formatoAvances = avances.map((item) => ({
        id: item.id_registro,
        perfil_id: item.perfil?.id,
        recurso_id: item.id,
        fecha: item.fecha,
        unidad_esfuerzo_id: unidad_esfuerzo_id,
        etapa_id: etapa_id,
        esfuerzo: item.esfuerzo,
        tarea_id:id
      }));

      let body = {
        nombre: nombre,
        descripcion: descripcion,
        etapa_id: Number(etapa_id),
        tipo_tarea_id: Number(tipo_tarea_id),
        prioridad_id: Number(prioridad_id),
        unidad_esfuerzo_id: Number(unidad_esfuerzo_id),
        esfuerzo: Number(esfuerzo),
        user_asignado_id: Number(user_asignado_id),
        fecha_inicio: fecha_inicio,
        fecha_fin: fecha_fin,
        id: id,
        status_id: 0,
        recursos: formatoRecursos,
        avance: avance,
        avances:formatoAvances
      }

      if(id){
        response = await axios.put(`${API_URL}/bitacora/tareas/${id}`,body);
      }else{
        response = await axios.post(`${API_URL}/bitacora/tareas/`,body);

      }
    
    return response.data;
  } catch (error) {
    console.error("Error al obtener las tareas:", error);
    throw error;
  }
};

export async function actualizarEtapaTarea(values: any) {

  try {
    const { tareaId, etapaCode } = values;
    let data = {
      tareaId,
      codeStage: etapaCode
    }
    const response = await axios.put(`${API_URL}bitacora/tareas/cambiar/etapa`, data);
    return response;
  } catch (error) {
    console.error("Error al cambiar la etapa:", error);
    throw error;
  }

  
}

export async function obtenerCamposFiltro() {
  try {

    const response = await axios.get(`${API_URL}catalogs/campos/TBL_BIT`);
    const data = response.data.doc.data;
    return data;

  } catch (error) {
    console.error("Error al obtener los campos a filtrar:", error);
    throw error;
  }
}


export async function obtenerDatosFiltros(code: string) {
  try{

    let response;
    let data;
    let res;
    let selectOptions;

    switch (code) {
        case 'BIT_NOMBRE':
            response = await axios.get(`${API_URL}bitacora/all/tareas/filtro`);
            res = response.data.doc.data;
            selectOptions = res.map((option: any) => ({
              value: option.id,
              label: option.nombre,
            }));
            data = selectOptions;
            break;
        case 'BIT_ETAPA':
            response = await axios.get(`${API_URL}catalogs/ETAR?sort=name`);
            res = response.data.doc.data.rows;
            selectOptions = res.map((option: any) => ({
              value: option.id,
              label: option.name,
            }));
            data = selectOptions;
            break;
        case 'BIT_ASING':
            response = await axios.get(`${API_URL}tareas/getUser/byRole`);
            res = response.data.doc.data;
            selectOptions = res.map((option: any) => ({
              value: option.id,
              label: `${option.first_name} ${option.father_last_name}`,
            }));
            data = selectOptions;
            break;
        default:  
            break;
    }
    return data;

  } catch (error) {
    console.error("Error al obtener los campos a filtrar:", error);
    throw error;
  }

}

export async function obtenerPerfiles() {
  try {
    const response = await axios.get(`${API_URL}catalogs/PASOC`);
    const data = response.data.doc.data.rows;
    let select: SelectFormat[] = [];
    data.forEach((item: any) => {
      const selectCustom = {
        id: Number(item.id),
        value: item.id,
        label: `${item.name}`,
        data: item,
      };
      select.push(selectCustom);
    });
    return select;

  } catch (error) {
    console.error("Error al obtener los campos a filtrar:", error);
    throw error;
  }


}

export async function obtenerRecursos(id: number) {
  try {
    const response = await axios.get(`${API_URL}colaboradores/profile/${id}`);
    const data = response.data.doc.data.rows;
    let select: SelectFormat[] = [];
    data.forEach((item: any) => {
      const selectCustom = {
        id: Number(item.id),
        value: item.id,
        label: `${item.contacto.nombre} ${item.contacto.apellido_paterno} `,
        data: item,
      };
      select.push(selectCustom);
    });
    return select;

  } catch (error) {
    console.error("Error al obtener los campos a filtrar:", error);
    throw error;
  }


}