import { FC } from 'react';
import { PageTitle, PageLink } from '../../../../_metronic/layout/core';
import { View } from './components/View';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../../_metronic/helpers';

const Tareas: FC = () => {
  const { etapa_id, id } = useParams<{ etapa_id: string; id: string }>();
  const tareaId: number = Number(decryptId(id));
  const etapaId: number = Number(decryptId(etapa_id));
  let titulo: string = tareaId ? `Editar tarea` : `Nueva tarea`;

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Bitácora',
      path: '/bitacora/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{titulo}</PageTitle>
      <View tareaId={tareaId} etapaId={etapaId} />
    </>
  );
};

export { Tareas };
