import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SubAccountDocModel, SubAccountModel } from '../models/SubAccountModel';
import { createHistoryMessageAccount, createHistoryMessageSubaccount, getAccountById, getHistorySubaccount, getSubaccountById, insertAddress, insertSubAccount, updateAddress, updateSubaccount } from '../../../service/account/Account';
import { AccountModel } from '../../account/models/AccountModel';
import { HistoryModel } from '../../../../_metronic/helpers/models/HistoryModel';
import { AddressModel } from '../../../../_metronic/helpers/models/AddressModel';
import { SimpleAlert } from '../../../../_metronic/helpers';


export const useGetOne = (id: number) => {

    const initModel: SubAccountModel = {
        id: 0,
        nombre: "",
        telefono: "",
        sitio_web: "",
        tamano: null,
        proveedor_id: "",
        company_id: 0,
        industria_id: 0
    };

    const [initValues, setInitValues] = useState<SubAccountModel>(initModel);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getSubaccountById(id)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setInitValues(res);
                setLoadingRequest(1);
            }
        };
        if (id > 0) {
            fetchPost();
        } else {
            setInitValues(initModel);
            setLoadingRequest(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return { initValues, loadingRequest };
};

export const useEditSubaccount = () => {
    const [loadingEditSubccount, setLoadingEditSubaccount] = useState<boolean>(false);
    const history = useHistory();
    const editSubaccount = async (subaccount: SubAccountModel, subaccountId: number, accountId: string) => {
        setLoadingEditSubaccount(false);
      try {
          const res = await updateSubaccount(subaccount, subaccountId, accountId);
          const { data: { status, message } } = res;
          SimpleAlert(message, 3000, status)
        setLoadingEditSubaccount(true);
      } catch (error) {
        history.push('/error/500');
        setLoadingEditSubaccount(false);
      }
    };
    return { editSubaccount, loadingEditSubccount };
};

export const useGetHistorySubaccount = (
    reaload: number,
    search: string,
    id: string,
) => {
    const [data, setData] = useState<HistoryModel[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0);
            const res = await getHistorySubaccount(search, id)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setData(res);
                setLoadingRequest(1);
            }
        };
        if (reaload && Number(id) > 0) {
            fetchPost();
        }
    }, [reaload]);

    return { data, loadingRequest };
};


export const useCreateSubaccount = () => {

    const [loadingInsertSubaccount, setLoadingInsertSubaccount] = useState<boolean>(false);
    const history = useHistory();
    const insertSubaccount = async (subName: string, accountId: string) => {
        setLoadingInsertSubaccount(true);
        let res;
      try {
        res = await insertSubAccount(subName, accountId);
        SimpleAlert(res?.data?.message, 3000, 'success')
        setLoadingInsertSubaccount(false);
      } catch (error: any) {
        history.push('/error/500');
        if(error && error.response){
          const { message } = error.response.data;
          SimpleAlert(message, 3000, 'error')
        }
        setLoadingInsertSubaccount(false);
      }
    };
    return { insertSubaccount, loadingInsertSubaccount };
};

export const useCreateAddress = () => {

    const [loadingInsertAddress, setLoadingInsertAddress] = useState<boolean>(false);
    const history = useHistory();
    const createAddress = async (address: AddressModel, accountId: string) => {
        setLoadingInsertAddress(false);
        let res;
      try {
        if(address.id > 0){
          res = await updateAddress(address, accountId)

        } else {
          res =await insertAddress(address, accountId);
        }
        SimpleAlert(res.data.message, 3000, 'success');
        setLoadingInsertAddress(true);
      } catch (error) {
        history.push('/error/500');
        setLoadingInsertAddress(false);
      }
    };
    return { createAddress, loadingInsertAddress };
};

export const useInsertMessageSubaccount = () => {
    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
    const history = useHistory();
    const insertData = async (message: string, accountId: string) => {
        setLoadingInsert(false);
      try {
        await createHistoryMessageSubaccount(message, accountId);
        setLoadingInsert(true);
      } catch (error) {
        history.push('/error/500');
        setLoadingInsert(false);
      }
    };
    return { insertData, loadingInsert };
};