import React, { useState, useEffect, useRef } from 'react';
import { TaskModel } from '../../tasks/models/TaskModel';
import { KTSVG, encryptId } from '../../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import {
  dateFormatt,
  dateFormattTime,
} from '../../../../_metronic/helpers/FormatDate';

type Props = {
  tasks: TaskModel[];
  onSaveTask: (message: string) => void;
  active: boolean;
  isEdit: boolean;
};

const TaskList: React.FC<Props> = ({ tasks, onSaveTask, active, isEdit }) => {
  const [taskMemo, setTaskMemo] = useState<TaskModel[]>(tasks);
  const [taskName, setTaskName] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const AddTaskRow = () => {
    const newTask: TaskModel = {
      id: 0,
      nombre: 'Nueva Tarea',
    };
    setTaskMemo((prevTasks) => [...prevTasks, newTask]);
  };

  const sendTask = () => {
    if (taskName.trim() !== '') {
      onSaveTask(taskName);
      setTaskName('');
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [taskMemo]);

  useEffect(() => {
    setTaskMemo(tasks);
  }, [tasks]);

  useEffect(() => {
    if (active) {
      AddTaskRow();
    }
  }, [active]);

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendTask();
    }
  };
  const handleInputBlur = () => {
    setTaskMemo((prevTasks) => prevTasks.filter((task) => task.id !== 0));
    setTaskName('');
  };

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-200px">Nombre</th>
              <th className="w-100px">Asignado a</th>
              <th className="w-100px">Fecha inicial</th>
              <th className="w-100px">Fecha final</th>
              <th className="w-100px">Etapa</th>
            </tr>
          </thead>
          <tbody className="">
            {taskMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      {row.id === 0 ? (
                        <>
                          <input
                            type="text"
                            value={taskName}
                            className="w-100 border-0"
                            ref={
                              index === taskMemo.length - 1 ? inputRef : null
                            }
                            onChange={(e) => setTaskName(e.target.value)}
                            onKeyDown={onEnterPress}
                            style={{ outline: 'none' }}
                            placeholder={row.nombre}
                            onBlur={handleInputBlur}
                          ></input>
                          <small>Presione enter para guardar</small>
                        </>
                      ) : (
                        <>
                          {!isEdit ? (
                            <Link
                              className="text-primary"
                              to={`/oportunidades_tareas/${
                                row.oportunidad?.id
                                  ? encryptId(row.oportunidad.id.toString())
                                  : ''
                              }/edit/${encryptId(row.id.toString())}`}
                            >
                              {row.nombre}
                            </Link>
                          ) : (
                            row.nombre
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      <div className="symbol symbol-25px tooltip-soft">
                        {row.user_asignado && (
                          <>
                            {row.user_asignado.profile_photo ? (
                              <>
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC}users/${row.user_asignado.profile_photo}`}
                                  crossOrigin="anonymous"
                                  className="symbol-label fs-7 "
                                  alt="user"
                                />
                                <span className="tooltiptext">{`${row.user_asignado.first_name} ${row.user_asignado.father_last_name} ${row.user_asignado.mother_last_name}`}</span>
                              </>
                            ) : (
                              <>
                                <span className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold">
                                  {row.user_asignado.first_name &&
                                  row.user_asignado.father_last_name ? (
                                    <>{`${row.user_asignado.first_name[0]}${row.user_asignado.father_last_name[0]}`}</>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                                <span className="tooltiptext">{`${row.user_asignado.first_name} ${row.user_asignado.father_last_name} ${row.user_asignado.mother_last_name}`}</span>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.fecha_inicio
                        ? dateFormatt(row.fecha_inicio)
                        : row.fecha_inicio}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.fecha_fin
                        ? dateFormatt(row.fecha_fin)
                        : row.fecha_fin}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012 text-muted">
                      {row.etapa && row.etapa.name}
                    </div>
                  </div>
                </td>
              </tr>
            ))}

            {!isEdit && (
              <tr>
                <td colSpan={5}>
                  <div className="d-flex align-items-center">
                    <div
                      className="btn btn-light-primary btn-sm mb-10"
                      onClick={AddTaskRow}
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr075.svg"
                        className="svg-icon-5"
                      />
                      Agregar tarea
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { TaskList };
