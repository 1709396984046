import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { SimpleAlert } from '../../../../_metronic/helpers';
import { useGetNoteByID, useEditNote } from '../hooks/NoteHook';

type Props = {
  id: string;
  contactoId: string;
  onRefreshHistory: () => void;
};

const noteSchema = Yup.object().shape({
  nombre: Yup.string()
    .trim()
    .test(
      'not-only-spaces',
      'El nombre no puede contener solo espacios.',
      (value) => value?.trim() !== ''
    )
    .required('Nombre es requerido.'),
  descripcion: Yup.string().required('Descripción requerido.'),
});

const NoteForm: React.FC<Props> = ({ id, contactoId, onRefreshHistory }) => {
  const [reloadInfoCurrentTask, setReloadInfoCurrentTask] = useState(
    Math.random() * 40
  );
  const { noteInit } = useGetNoteByID(id, reloadInfoCurrentTask);
  const { editNote, loadingEditNote } = useEditNote();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const NoteForm = useFormik({
    initialValues: {
      ...noteInit,
    },
    validationSchema: noteSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(async () => {
        await editNote(values, contactoId);
        setLoading(false);
      }, 1000);
    },
  });

  return (
    <div className="card mb-6 mb-xl-9">
      <div className="card-body">
        <form
          id="kt_account_form"
          className="form"
          action="#"
          noValidate
          onSubmit={NoteForm.handleSubmit}
        >
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-0">
              <div className="w-100 pe-5">
                <input
                  className="form-control mb-2 w-100 border-0"
                  style={{
                    width: '100%',
                    padding: '15px',
                    fontSize: '24px',
                    boxSizing: 'border-box',
                    marginBottom: '20px',
                  }}
                  data-kt-element="input"
                  placeholder="Nombre *"
                  maxLength={40}
                  {...NoteForm.getFieldProps('nombre')}
                ></input>
                {NoteForm.touched.nombre && NoteForm.errors.nombre && (
                  <div className="fv-plugins-message-container text-danger ms-3 w-100 mb-3">
                    <span role="alert">{NoteForm.errors.nombre}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex mt-5">
                  <label className="form-label w-auto">Creado por:</label>
                </div>
                <div
                  className="d-flex"
                  style={{ alignItems: 'center', width: '100%' }}
                >
                  <div className="symbol symbol-30px">
                    <span className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold">
                      {NoteForm.values.user_creador?.first_name &&
                        NoteForm.values.user_creador?.first_name[0]}
                      {NoteForm.values.user_creador?.father_last_name &&
                        NoteForm.values.user_creador?.father_last_name[0]}
                    </span>
                  </div>
                  <span className="ms-3 ">
                    {NoteForm.values.user_creador?.first_name &&
                      NoteForm.values.user_creador?.first_name}{' '}
                    {NoteForm.values.user_creador?.father_last_name &&
                      NoteForm.values.user_creador?.father_last_name}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex mt-5">
                  <label className=" form-label w-auto">
                    Fecha de creación:
                  </label>
                </div>
                <span>{NoteForm.values.created_at}</span>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex mt-5">
                  <label className="form-label w-auto">
                    Fecha de modificación:
                  </label>
                </div>
                <span>{NoteForm.values.updated_at}</span>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="w-100 d-flex mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Descripción
                    </label>
                  </div>
                  <textarea
                    className="form-control mb-3 w-100"
                    data-kt-element="input "
                    rows={5}
                    style={{ resize: 'none' }}
                    placeholder=""
                    maxLength={255}
                    {...NoteForm.getFieldProps('descripcion')}
                  ></textarea>
                  {NoteForm.touched.descripcion && NoteForm.errors.descripcion && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">{NoteForm.errors.descripcion}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-10">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading}
            >
              {!loading && <span className="indicator-label">Guardar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export { NoteForm };
