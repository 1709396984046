import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';

const BitacoraPage: FC = () => {
  const breadCrumbs: Array<PageLink> = [];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Bitácora</PageTitle>
      <View />
    </>
  );
};

export { BitacoraPage };
