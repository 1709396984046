import { FC } from 'react';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import { View } from './components/View';

const SurchargePage: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Catálogos',
            path: `/catalogos/`,
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        Recargos
      </PageTitle>
      <View />
    </>
  );
};

export { SurchargePage };
