import { FC } from 'react';
import { PageTitle, PageLink } from '../../../../_metronic/layout/core';
import { View } from './components/View';

const SubCategoryPackagePage: FC = () => {
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Catálogos',
      path: `/catalogos/`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Subcategoría de paquetes</PageTitle>
      <View />
    </>
  );
};

export { SubCategoryPackagePage };
