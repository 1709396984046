import React, { useState, useLayoutEffect, useRef } from 'react';
import { KTSVG } from './KTSVG';
import { HistoryModel } from '../models/HistoryModel';
import { dateFormattTime, formatDateTime } from '../FormatDate';
import { Toggle } from '../../partials/layout/explore/Toggle';

type Props = {
  data: HistoryModel[];
  onSaveMessage: (message: string) => void;
  disabled: boolean;
  onShow: () => void;
};

const History: React.FC<Props> = ({
  data,
  onSaveMessage,
  disabled = false,
  onShow,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const messagesRef = useRef<HTMLDivElement>(null);

  const sendMessage = () => {
    if (message.trim() !== '') {
      setLoading(true);
      onSaveMessage(message);
      setMessage('');
    }
  };
  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };
  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage();
    }
  };

  useLayoutEffect(() => {
    scrollToBottom();
    setLoading(false);
  }, [data]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          {/* <div className="card-title flex-grow-1" style={{ minWidth: 0 }}>
          <div className={'position-relative w-100'}>
            <input
              type="text"
              placeholder="Historial"
              autoComplete="off"
              className={'form-control '}
            />
            <span
              className={
                'btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
              }
              data-kt-password-meter-control={'visibility'}
            >
              <i className={'bi bi-search fs-2'}></i>
            </span>
          </div>
        </div> */}

          {/* <div className="card-toolbar">
          <a
            className={`btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary`}
            onClick={() => {}}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen007.svg"
              className="svg-icon-1"
            />
          </a>
        </div> */}

          <div className="card-toolbar">
            <a
              className={`btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary`}
              onClick={() => {
                setShowButton(true);
                onShow();
              }}
            >
              <KTSVG
                path={'/media/icons/duotune/arrows/arr080.svg'}
                className={'svg-icon-1'}
              />
            </a>
          </div>
        </div>

        <div className="card-body" id="kt_activities_body">
          <div
            id="kt_activities_scroll"
            ref={messagesRef}
            className="position-relative scroll-y me-n5 pe-5"
            style={{ maxHeight: '550px', minHeight: '550px' }}
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_activities_body"
            data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
            data-kt-scroll-offset="5px"
          >
            {data.length === 0 ? (
              <span className="text-gray-500">
                No hay historial para mostrar.
              </span>
            ) : (
              data.map((item, index) => (
                <div className="timeline-label" key={index}>
                  {(() => {
                    switch (item.tipo?.code) {
                      case 'RISK':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-success fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Riesgo</b>:
                                {/* Agregó un nuevo <b>Riesgo</b>: */}
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'TASK':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-info fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Tarea</b>:
                                {/* Agregó una nueva <b>Tarea</b>: */}
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'MIT':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-info fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                {/* Agregó una nueva <b>mitigación</b>: */}
                                <b>Mitigación</b>:
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'NOTE':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-danger fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Notas</b>:
                                {/* Agregó una nueva <b>nota</b>: */}
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'HOPO':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-danger fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                Cambio el estatus de la <b>oportunidad</b> a:
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'TASKSTS':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-danger fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                Cambio el estatus de la <b>tarea</b> a:
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'RISKSTS':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-danger fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                Cambio el estatus del <b>riesgo</b> a:
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'MITISTS':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-danger fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                Cambio el estatus de la <b>mitigación</b> a:
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'HIST':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-warning fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                Realizó una modificación del campo:
                              </span>
                              <span className="text-gray-700">
                                <span className="fw-bolder">
                                  {item.campo?.name}{' '}
                                </span>
                                {' de '}
                                <span className="text-primary">
                                  {item.valor_anterior}
                                </span>{' '}
                                {' a '}
                                <span className="text-primary">
                                  {item.valor_nuevo}
                                </span>
                                .
                              </span>
                            </div>
                          </div>
                        );
                      case 'DOC':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-success fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Documento</b>:
                                {/* Agregó un nuevo <b>documento</b>: */}
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'SECC':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-primary fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Sección</b>:
                                {/* Agregó un nuevo <b>documento</b>: */}
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'ADDR':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-info fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Dirección</b>:
                                {/* Agregó una nueva <b>dirección</b>: */}
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'SBACC':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-success fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Subcuenta</b>:
                                {/* Agregó una nueva <b>Subcuenta</b>: */}
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'IMG':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-info fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                {item.comentario}
                              </span>
                              {/* <span className="text-primary">
                                {item.comentario}
                              </span> */}
                            </div>
                          </div>
                        );
                      case 'PRODUCT':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-success fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                {item.comentario}
                              </span>
                              {item.valor_anterior && (
                                <>
                                  <span className="text-gray-700">
                                    {/* <span className="fw-bolder">
                                  {item.campo?.name}{' '}
                                </span> */}
                                    {' de '}
                                    <span className="text-primary">
                                      {item.valor_anterior}
                                    </span>{' '}
                                    {' a '}
                                    <span className="text-primary">
                                      {item.valor_nuevo}
                                    </span>
                                    .
                                  </span>
                                </>
                              )}
                              {/* <span className="text-primary">
                                {item.comentario}
                              </span> */}
                            </div>
                          </div>
                        );
                      case 'SERVI':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-success fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                {item.comentario}
                              </span>
                              {item.valor_anterior && (
                                <>
                                  <span className="text-gray-700">
                                    {/* <span className="fw-bolder">
                                  {item.campo?.name}{' '}
                                </span> */}
                                    {' de '}
                                    <span className="text-primary">
                                      {item.valor_anterior}
                                    </span>{' '}
                                    {' a '}
                                    <span className="text-primary">
                                      {item.valor_nuevo}
                                    </span>
                                    .
                                  </span>
                                </>
                              )}
                              {/* <span className="text-primary">
                                {item.comentario}
                              </span> */}
                            </div>
                          </div>
                        );
                      case 'PROV':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-success fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Proveedor</b>:
                                {/* Agregó una nueva <b>Subcuenta</b>: */}
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'CTO':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-info fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Contacto</b>:
                                {/* Agregó una nueva <b>Subcuenta</b>: */}
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                      case 'JOB':
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-9"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-success fs-1"></i>
                            </div>
                            <div className="timeline-content d-flex ps-3 flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                <b>Empleo</b>:
                              </span>
                              <span className="text-primary">
                                {item.comentario}.
                              </span>
                            </div>
                          </div>
                        );
                      default:
                        return (
                          <div className="timeline-item pb-5">
                            <div className="timeline-label fw-bolder text-gray-800 fs-6"></div>
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-primary fs-1"></i>
                            </div>
                            <div className="fw-mormal timeline-content text-muted ps-3 d-flex flex-column">
                              <span className="fw-bolder text-gray-700">
                                {item.user?.first_name}{' '}
                                {item.user?.father_last_name}
                              </span>
                              <span className=" text-gray-500 fs-8">
                                {dateFormattTime(item.created_at)}
                              </span>
                              <span className="text-gray-700">
                                Agregó un nuevo <b>comentario</b>:
                              </span>
                              <span className="text-primary">
                                {item.comentario}
                              </span>
                            </div>
                          </div>
                        );
                    }
                  })()}
                </div>
              ))
            )}
          </div>
        </div>
        <div className="card-footer pt-4">
          <textarea
            disabled={disabled}
            className="form-control form-control-flush mb-3"
            rows={1}
            style={{ resize: 'none' }}
            data-kt-element="input"
            placeholder="Escribe un comentario..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
            maxLength={255}
          ></textarea>

          <div className="d-flex flex-stack">
            <div className="d-flex align-items-center me-2"></div>
            <button
              className="btn btn-sm btn-primary"
              type="button"
              disabled={disabled}
              data-kt-element="send"
              onClick={sendMessage}
            >
              {!loading && <span className="indicator-label">Enviar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Enviando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { History };
