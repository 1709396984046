import React, { useRef } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import {
  uploadFile,
  downloadFile,
  typeIconFile,
    removeFile,
} from '../../helpers/Documents';
import { dateFormatt } from '../../../../../_metronic/helpers/FormatDate';
import { ContactDocModel } from '../../models/ContactoModel'

type Props = {
  contacto: number;
  documents: ContactDocModel[];
  onRefreshData: () => void;
};

const DocsList: React.FC<Props> = ({ contacto, documents, onRefreshData }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const onOpenFile = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    fileRef?.current?.click();
  };

  return (
    <>
      <div className="row mb-3">
        {documents.length
          ? documents.map((item) => {
              return (
                <div className="d-flex flex-column mb-0" key={item.id}>
                  <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-30px me-5">
                        <img
                          src={toAbsoluteUrl(
                            `/media/svg/files/${typeIconFile(
                              item.extension ? item.extension : ''
                            )}.svg`
                          )}
                          alt=""
                        />
                      </div>
                      <div className="fw-semibold">
                        <a
                          className="fs-8 fw-bold text-gray-900 text-hover-primary large-text"
                          href="#"
                          onClick={(event) => {
                            downloadFile(
                              item.id ? item.id : 0,
                              item.nombre_final ? item.nombre_final : '',
                              event
                            );
                          }}
                        >
                          {item.nombre_final}
                        </a>
                        <div className="text-gray-500 fs-9 ">
                          {dateFormatt(
                            item.created_at ? item.created_at?.toString() : ''
                          )}
                          <a href="#" className="fs-7 ms-1">
                            {item.user?.nombre_completo}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <a
                        className="badge badge-light-danger  text-hover-danger fw-bold px-4 py-3 cursor-pointer"
                        title="Eliminar archivo"
                        onClick={async (event) => {
                          await removeFile(item.id ? item.id : 0, event).then(
                            () => {
                              onRefreshData();
                            }
                          );
                        }}
                      >
                        <KTSVG
                          path="/media/icons/duotune/files/fil007.svg"
                          className="svg-icon-4"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div className="center-items bg-light-primary border-primary border border-dashed p-3">
        <a
          href="#"
          className="text-gray-800 text-hover-primary d-flex flex-column"
        >
          <div
            className="symbol symbol-55px mb-2 center-items"
            onClick={onOpenFile}
          >
            <img src={toAbsoluteUrl('/media/svg/files/upload.svg')} alt="" />
          </div>
          <a
            className="fs-7 cursor-pointer fw-bold text-gray-400 mt-auto text-hover-primary"
            onClick={onOpenFile}
            title="Subir archivo"
          >
            {'Subir archivo'}
          </a>
          <input
            style={{ display: 'none' }}
            ref={fileRef}
            type="file"
            name="documentos"
            // accept=".xls, .xlsx"
            onChange={async (event: any) => {
              if (event.currentTarget.files) {
                let files = event.currentTarget.files;
                if (files.length) {
                  await uploadFile(files, contacto).then(() => {
                    onRefreshData();
                  });
                }
              }
            }}
          />
        </a>
      </div>
    </>
  );
};

export { DocsList };
