import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Collapse } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { TaskList } from './TaskList';
import { SeccionProyectosModel } from '../../../models/ProyectoModel';
import { validarSeccionTareas } from '../../../../../../service/proyectos/Proyectos';

type Props = {
  sections: SeccionProyectosModel[];
  updateSectionTitle: (id: number, title: string) => void;
  handleTitleBlur: (id: number) => void;
  sectionRemove: (id: number) => void;
  tareaRemove: (id: number) => void;
};

type OpenSections = {
  [key: number]: boolean;
};

const SectionsList: React.FC<Props> = ({
  sections,
  updateSectionTitle,
  handleTitleBlur,
  sectionRemove,
  tareaRemove,
}) => {
  const [cargandoEliminacion, setCargandoEliminacion] = useState<number | null>(
    null
  );
  const [openSections, setOpenSections] = useState<OpenSections>({});
  const [sectionsList, setSectionsList] = useState<SeccionProyectosModel[]>([]);

  const toggleSection = (index: number) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const validarSeccion = async (
    id: number,
    nombre: string,
    section: SeccionProyectosModel
  ) => {
    setCargandoEliminacion(id);

    if (section.esNuevo) {
      Swal.fire({
        title: '¿Deseas eliminar la sección?',
        html: `
              <div className="text-gray-600">
                Se va a eliminar la sección "<b>${nombre}</b>" con las tareas relacionadas.
              </div>
              `,
        showCancelButton: true,
        padding: '2em',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light me-3',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          sectionRemove(id);
          setCargandoEliminacion(null);
        } else {
          setCargandoEliminacion(null);
        }
      });
    } else {
      await validarSeccionTareas(id)
        .then((res) => {
          const { existeTareasConAvance } = res.data.doc.data;
          if (existeTareasConAvance) {
            Swal.fire({
              title: '¡Tareas con avance!',
              html: `
                <div className="text-gray-600">
                  "La sección que deseas eliminar contiene tareas con avance o esfuerzo registrado. Al eliminarla, toda la información asociada se perderá. ¿Deseas continuar?"
                </div>
                `,
              showCancelButton: true,
              showConfirmButton: true,
              padding: '2em',
              cancelButtonText: 'Cerrar',
              confirmButtonText: 'Aceptar',
              reverseButtons: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light me-3',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                sectionRemove(id);
                setCargandoEliminacion(null);
              } else {
                setCargandoEliminacion(null);
              }
            });
          } else {
            Swal.fire({
              title: '¿Deseas eliminar la sección?',
              html: `
                <div className="text-gray-600">
                  Se va a eliminar la sección "<b>${nombre}</b>" con las tareas relacionadas.
                </div>
                `,
              showCancelButton: true,
              padding: '2em',
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Aceptar',
              reverseButtons: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light me-3',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                sectionRemove(id);
                setCargandoEliminacion(null);
              } else {
                setCargandoEliminacion(null);
              }
            });
          }
        })
        .catch((err) => {
          const {
            data: { message },
          } = err.response;
          const errorMessage = message || 'Error al eliminar la sección.';
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setCargandoEliminacion(null);
        });
    }
  };

  useEffect(() => {
    setSectionsList(sections);
    return () => {
      setSectionsList([]);
    };
  }, [sections]);

  return (
    <>
      {sectionsList.map((section: SeccionProyectosModel, index: number) => {
        return (
          <div className="card mb-6 mb-xl-9" key={section.id}>
            <div
              className="card-header cursor-pointer"
              onClick={() => {
                toggleSection(section.id);
              }}
              aria-controls={`collapseSection${section.id}`}
            >
              <div className="card-title w-75 m-0">
                <input
                  type="text"
                  value={section.nombre}
                  placeholder="Nombre de sección"
                  style={{ border: 'none', paddingLeft: 0 }}
                  onChange={(e) =>
                    updateSectionTitle(section.id, e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                  onBlur={() => handleTitleBlur(section.id)}
                  className="form-control w-100 fw-bolder m-0 fs-3"
                />
              </div>
              <div className="d-flex align-items-center">
                <div className="text-center me-5 ms-3">
                  {cargandoEliminacion === section.id ? (
                    <>
                      <div className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2">
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          <span className="spinner-border spinner-border-sm align-middle"></span>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="btn btn-sm btn-active-light-danger btn-icon btn-light-primary ms-2"
                        onClick={(event) => {
                          event.stopPropagation();
                          validarSeccion(section.id, section.nombre, section);
                        }}
                        title="Eliminar sección"
                      >
                        <KTSVG path="/media/icons/duotune/general/gen027.svg" />
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={`align-self-center`}
                  style={{
                    transform: openSections[section.id]
                      ? 'translateX(10%) rotate(-180deg)'
                      : '',
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <KTSVG
                    path={'/media/icons/duotune/arrows/arr072.svg'}
                    className={`svg-icon-1`}
                  />
                </div>
              </div>
            </div>
            <Collapse in={openSections[section.id]}>
              <div id={`collapseSection${section.id}`}>
                <div className="card-body border-top p-9">
                  <TaskList
                    indexSection={section.id}
                    data={section.tareas}
                    actualizarTareas={section.actualizarSeccion}
                    tareaRemove={tareaRemove}
                  />
                </div>
              </div>
            </Collapse>
          </div>
        );
      })}
    </>
  );
};

export { SectionsList };
