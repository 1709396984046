import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GenericoModel } from "../models/GenericModel";

import {
  // getCategoriesGrid,
  // getDataCategoryById,
  // getDataSubCategoryById,
  addSubcategoryProducts,
  getAllSubCategoriesProducts,
} from "../../../../service/products/Products";

import { getSubCategoriesGrid, getDataSubCategoryById } from "../../../../service/catalog/SubCategoriaProyectos"; 
import { getCategoriesGrid, getDataCategoryById } from "../../../../service/catalog/CategoriaProyectos";
import { toast } from "react-toastify";
import { SimpleAlert } from "../../../../../_metronic/helpers";

// export const useGetAll = (loading: number) => {
//   const [dataCategories, setDataCategories] = useState<GenericoModel[]>([]);
//   const [count, setCount] = useState(1);
//   const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
//   const history = useHistory();

//   useEffect(() => {
//     const fetchPost = async () => {
//       setLoadingRequest(true);
//       const data = await getSubCategoriesGrid()
//         .then()
//         .catch((error) => {
//           setLoadingRequest(false);

//           history.push("/error/500");
//         });
//       if (data) {
//         setDataCategories(data.rows);
//         setCount(data.count);
//         setLoadingRequest(false);
//       }
//     };
//     fetchPost();
//   }, [loading]);
//   return { dataCategories, count, loadingRequest };
// };
const formmatedDataToSelect = (data: any) => {
  const selectOptions = data.map((option: any) => ({
    value: option.id,
    label: option.nombre,
  }));

  return selectOptions;
};

export const useGetCategoriesSelect = () => {
  const [dataCatSelect, setDataCatSelect] = useState<GenericoModel[]>([]);

  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCategoriesGrid()
        .then()
        .catch((error) => {
          let resMessageToast: string = "";
          const {
            data: { message },
          } = error.response;
          resMessageToast = `${message}`;
          toast.error(resMessageToast, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
      if (data) {
        let options: any = [];
        options = formmatedDataToSelect(data.rows);
        setDataCatSelect(options);
      }
    };
    fetchPost();
  }, []);
  return { dataCatSelect };
};
export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    codigo: "",
    nombre: "",
    orden: 0,
  };
  const [initValues, setInitValues] = useState<GenericoModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getDataCategoryById(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
  }, [id]);

  return { initValues };
};

// --------CATALOGO SUBCATEGORIAS PORYECTOS
export const useGetAllSub = (loading: number) => {
  const [dataCategories, setDataCategories] = useState<GenericoModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const data = await getSubCategoriesGrid()
        .then()
        .catch((error) => {
          setLoadingRequest(false);

          history.push("/error/500");
        });
      if (data) {
        setDataCategories(data.rows);
        setCount(data.count);
        setLoadingRequest(false);
      }
    };
    fetchPost();
  }, [loading]);
  return { dataCategories, count, loadingRequest };
};

export const useGetSubOne = (id: number) => {
  const initModel = {
    id: 0,
    codigo: "",
    nombre: "",
    orden: 0,
    categoria_id: 0,
  };
  const [initValues, setInitValues] = useState<GenericoModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getDataSubCategoryById(id)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        res.orden = res.orden ? res.orden : 0;
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
  }, [id]);

  return { initValues };
};

export const useCreateSubcategoryProducts = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const create = async (subcategory: any, categoryId: number) => {
    setLoading(false);
    setErrorRequest(false);
    try {
      const response = await addSubcategoryProducts(subcategory, categoryId);
      setLoading(true);
      setErrorRequest(false);
      SimpleAlert(response.message, 3000, response.status);
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, "error");
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push("/error/500");
      }
    }
  };
  return { create, loading, errorRequest };
};
