import { useState, useEffect } from 'react';
import { getEstados, getMunicipios } from '../../../service/account/Account';
import {
  getSubCuentas,
  getContactos,
  getContactoById,
  getFilterContactos,
  getHistory,
  createHistoryMessage,
  getContactosFilter,
  getNotesByContactId,
  addNoteContact,
  getAddressByContactId,
  updateAddress,
  insertAddress,
  getDocumentsByContactId
} from '../../../service/contacto/Contacto';
import { getAccountAll } from '../../../service/account/Account';
import { AddressContactModel, ContactDocModel, ContactoModel, SubAccountModel } from '../models/ContactoModel';
import { AccountModel } from '../../account/models/AccountModel';
import { HistoryModel } from '../models/HistoryModel';
import { useHistory } from 'react-router-dom';
import { NoteModel } from '../../notes/models/NoteModel';
import { SimpleAlert } from '../../../../_metronic/helpers';

type Option = {
  value: any;
  label: string;
};

const formmatedDataToSelect = (data: any) => {
  const selectOptions = data.map((option: any) => ({
    value: option.id,
    label: option?.apellido_paterno
      ? `${option.nombre} ${option.apellido_paterno} ${option.apellido_materno}`
      : `${option.nombre}`,
  }));

  return selectOptions;
};

const formmatedDataSelectToCollab = (data: any) => {
  const selectOptions = data.map((option: any) => ({
    value: option.id,
    name: option?.nombre,
    label: option?.apellido_paterno
      ? `${option.nombre} ${option.apellido_paterno} ${option?.telefono ?? ''} ${option?.email ?? ''}`
      : `${option.nombre}`,
  }));

  return selectOptions;
};


export const useGetAllCuentas = (reloadGrid: number) => {
  const [dataCuenta, setDataCuenta] = useState<AccountModel[]>([]);
  const [cuentaDataSelect, setCuentaDataSelect] = useState<any[]>([
    {
      value: 'new',
      label: 'Agregar Cuenta',
    },
  ]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAccountAll()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataCuenta(res.data);
        const options = formmatedDataToSelect(res.data);
        setCuentaDataSelect((prevState) => [
          {
            value: 'new',
            label: 'Agregar Cuenta',
          },
          ...options,
        ]);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };

    if (reloadGrid) {
      fetchPost();
    }
  }, [reloadGrid]);

  return { dataCuenta, count, loadingRequest, cuentaDataSelect };
};
export const useGetAllSubCuentas = (id_cuenta: number, reloadInfo: number) => {
  const [dataSub, setDataSub] = useState<SubAccountModel[]>([]);
  const [subCuentaDataSelect, setSubCuentaDataSelect] = useState<any[]>([
    {
      value: 'new',
      label: 'Agregar Subcuenta',
    },
  ]);

  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getSubCuentas(id_cuenta)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataSub(res.data);
        const options = formmatedDataToSelect(res.data);
        setSubCuentaDataSelect((prevState) => [
          {
            value: 'new',
            label: 'Agregar Subcuenta',
          },
          ...options,
        ]);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    if (id_cuenta > 0) {
      fetchPost();
    } else {
      setDataSub([]);
      setSubCuentaDataSelect([
        {
          value: 'new',
          label: 'Agregar Subcuenta',
        },
      ]);
    }
  }, [id_cuenta, reloadInfo]);

  return { dataSub, count, loadingRequest, subCuentaDataSelect };
};
export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any
) => {
  const [data, setData] = useState<ContactoModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getContactos(startIndex, endIndex, filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.data);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    // fetchPost();
    if (realoadGrid) {
      fetchPost();
    }
  }, [endIndex, realoadGrid]);

  return { data, count, loadingRequest };
};
export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    email: '',
    telefono: '',
    telefono_movil: '',
    telefono_fijo: '',
    extension: '',
    fecha_nacimiento: '',
    puesto: '',
    departamento: {
      id: 0,
      name: '',
      code: '',
    },
    cuenta: {
      id: 0,
      nombre: '',
    },
    subCuenta: {
      id: 0,
      nombre: '',
    },
    departamento_id: '',
    cuenta_id: '',
    sub_cuenta_id: '',
    curp: '' ,
    lugar_nacimiento:  '',
    genero: null,
    pais: null,
  };
  const [initValues, setInitValues] = useState<ContactoModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getContactoById(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { initValues };
};

export const useGetState = () => {
  const [estadosList, setEstadosList] = useState<any>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getEstados()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setEstadosList(res.data);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { estadosList };
};

export const useGetMunicipality = (stateId: number) => {
  const [municipalityList, setMunicipalityList] = useState<any>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getMunicipios(stateId)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setMunicipalityList(res.data);
      }
    };

    if (stateId > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  return { municipalityList };
};

export const useGetContactosFilter = (
  realoadGrid: number,
  cuenta: number,
  subcuenta: number,
  location: number
) => {
  const [dataContactos, setDataContactos] = useState<ContactoModel[]>([]);
  const [contDataSelect, setContDataSelect] = useState<any[]>([
    {
      value: 'new',
      label: 'Agregar Contacto',
    },
  ]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getFilterContactos(cuenta, subcuenta, location)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        let options: any = [];
        setDataContactos(res.data);
        options = formmatedDataToSelect(res.data);
        setContDataSelect((prevState) => [
          {
            value: 'new',
            label: 'Agregar Contacto',
          },
          ...options,
        ]);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };

    fetchPost();
  }, [realoadGrid, cuenta, subcuenta]);

  return { dataContactos, contDataSelect };
};

export const useGetContactosCollab = (
  realoadGrid: number,
  cuenta: number,
  subcuenta: number,
  location: number
) => {
  const [dataContactos, setDataContactos] = useState<ContactoModel[]>([]);
  const [contDataSelect, setContDataSelect] = useState<any[]>([
    {
      value: 'new',
      label: 'Agregar Contacto',
    },
  ]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const res = await getFilterContactos(cuenta, subcuenta, location)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        let options: any = [];
        setDataContactos(res.data);
        options = formmatedDataSelectToCollab(res.data);
        setContDataSelect((prevState) => [
          {
            value: 'new',
            label: 'Agregar Contacto',
          },
          ...options,
        ]);
        setCount(res.count);
        setLoadingRequest(false);
      }
    };

    fetchPost();
  }, [realoadGrid]);

  return { dataContactos, contDataSelect, loadingRequest };
};

export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistory(search, id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};
export const useInsertMessage = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: string) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessage(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useGetFilterContactos = (
  realoadGrid: number,
  cuenta: number,
  subcuenta: number,
  location: number
) => {
  const [dataContactos, setDataContactos] = useState<ContactoModel[]>([]);
  const [contInfoSelect, setContInfoSelect] = useState<any[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getContactosFilter(cuenta, subcuenta, location)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        let options: any = [];
        setDataContactos(res.data);
        options = formmatedDataToSelect(res.data);
        setContInfoSelect((prevState) => [...options]);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };

    // if (cuenta > 0 || subcuenta > 0) {

    // }
    fetchPost();
  }, [realoadGrid, cuenta, subcuenta]);

  return { dataContactos, contInfoSelect };
};

export const useGetNotasContactos = (id: string, reaload: number) =>{
  const [notes, setNotes] = useState<NoteModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByContactId(id)
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingNote(false);
      }
    };
    if (Number(id) > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reaload]);

  return { notes, loadingNote };
}

export const useCreateNote = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const createNote = async (note: NoteModel, contactId: number) => {
    setLoadingCreateNote(false);
    try {
      const res = await addNoteContact(note, contactId);
      setLoadingCreateNote(true);
      const { status, message } = res?.data;
      SimpleAlert(message, 3000, status)
    } catch (error: any) {
      const { status, message } = error?.response?.data;
      SimpleAlert(message, 3000, status)
      setLoadingCreateNote(false);
    }
  };
  return { createNote, loadingCreateNote };
};

export const useGetAddressByContactId = (id: string, reaload: number) => {
    const [address, setAddress] = useState<AddressContactModel[]>([]);
    const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

    const history = useHistory();
    useEffect(() => {
        setLoadingAddress(false);
        const fetchPost = async () => {
            try {
                const res = await getAddressByContactId(id)
                if (res) {
                    setAddress(res);
                    setLoadingAddress(true);
                }
            } catch (error) {
                history.push('/error/500');
                setLoadingAddress(false);
            }
        };
        if (Number(id) > 0) {
            fetchPost();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, reaload]);

    return { address, loadingAddress };
};

export const useCreateAddress = () => {

    const [loadingInsertAddress, setLoadingInsertAddress] = useState<boolean>(false);
    const history = useHistory();
    const createAddress = async (address: AddressContactModel, contactId: string) => {
        setLoadingInsertAddress(false);
        let res;
        try {
            if (address.id > 0) {
                res = await updateAddress(address, contactId)

            } else {
                res = await insertAddress(address, contactId);
            }
            SimpleAlert(res.data.message, 3000, 'success');
            setLoadingInsertAddress(true);
        } catch (error) {
            history.push('/error/500');
            setLoadingInsertAddress(false);
        }
    };
    return { createAddress, loadingInsertAddress };
};

export const useGetDocumentsByContactId = (
    contactId: number,
    reload: number
) => {
    const [dataDocuments, setDataDocuments] = useState<ContactDocModel[]>([]);
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
    const history = useHistory();
    useEffect(() => {
        const fetchPost = async () => {
            setLoadingDocuments(false);
            const res = await getDocumentsByContactId(contactId)
                .then()
                .catch((error) => {
                    history.push('/error/500');
                });
            if (res) {
                setDataDocuments(res);
                setLoadingDocuments(true);
            }
        };
        if (contactId > 0) {
            fetchPost();
        }
    }, [reload]);

    return { dataDocuments, loadingDocuments };
};
