import React, { useState, useEffect } from 'react';
import { NoteForm } from './NoteForm';

type Props = {
  id: string;
  proyectoId: string;
};

const View: React.FC<Props> = ({ id, proyectoId }) => {
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);

  const onRefreshData = () => {
    setReloadGrid(Math.random() * 40);
  };

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ">
          <NoteForm id={id} />
        </div>
      </div>
    </>
  );
};

export { View };
