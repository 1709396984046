import React from 'react';
import { HomeTaskModel } from '../models/HomeModel';
import { toAbsoluteUrl, encryptId } from '../../../../_metronic/helpers';
import { colorStatusTask } from '../../../../_metronic/helpers/CustomElements';
import { randomClass } from '../../../../_metronic/helpers/CustomElements';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { clearLine } from 'readline';

type Props = {
  tasks: HomeTaskModel[];
  loadingListTaks: boolean;
};

const CardTask: React.FC<Props> = ({ tasks, loadingListTaks }) => {
  const columns: any = [
    {
      id: 'id',
      name: <div className="fw-bolder text-muted me-5"></div>,
      selector: (row: any) => row.id,
      cell: (row: any) => (
        <div className="timeline-badge">
          <i
            className={`fa fa-genderless text-primary fs-1 ${randomClass()}`}
          ></i>
        </div>
      ),
      width: '80px',
      sortable: false,
    },
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <>
          {row.oportunidad ? (
            <>
              <Link
                to={`/oportunidades_tareas/${
                  row.oportunidad?.id
                    ? encryptId(row.oportunidad.id.toString())
                    : ''
                }/edit/${encryptId(row.id.toString())}`}
              >
                {row.nombre}
              </Link>
            </>
          ) : (
            <>
              <Link to={`/bitacora/tarea/edit/${encryptId(row.id.toString())}`}>
                {`${row.nombre}`}
              </Link>
            </>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'estatus',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row.statusTarea.name,
      cell: (row: any) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column fs-012">
            <div className={`d-flex  ${colorStatusTask(row.statusTarea.code)}`}>
              {row.statusTarea.name}
            </div>
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      id: 'prioridad',
      name: <div className="fw-bolder text-muted me-5">Prioridad</div>,
      selector: (row: any) =>
        row.prioridad ? row.prioridad.name : 'Sin prioridad',
      sortable: true,
      cell: (row: any) => (
        <>
          <div className="text-gray-700">
            {row.prioridad ? row.prioridad.name : 'Sin prioridad'}
          </div>
        </>
      ),
    },
    {
      id: 'etapa',
      name: <div className="fw-bolder text-muted me-5">Etapa</div>,
      selector: (row: any) => row.etapa.name,
      cell: (row: any) => (
        <>
          <div className="text-gray-700">
            {row.etapa ? row.etapa.name : 'Sin Etapa'}
          </div>
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      <div className="card card-flush h-md-100">
        <div className={`card-body d-flex flex-column`}>
          <>
            <div className="fs-1 fw-bold text-gray-800">
              <span className="me-2">
                <span className="fs-015">{'Tareas Pendientes'}</span>
              </span>
            </div>
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {!loadingListTaks ? (
                <DataTable
                  columns={columns}
                  data={tasks}
                  expandableRowsHideExpander
                  responsive
                  customStyles={{
                    // table: {
                    //   style: {
                    //     overflowY: 'auto',
                    //   },
                    // },
                    rows: {
                      style: {
                        borderBottom: '1px dashed #e4e6ef !important',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      },
                    },
                    headRow: {
                      style: {
                        borderBottom: '1px dashed  #e4e6ef',
                      },
                    },
                    pagination: {
                      style: {
                        borderTopStyle: 'none',
                      },
                    },
                  }}
                  paginationComponentOptions={{
                    rowsPerPageText: 'Registros por página',
                    rangeSeparatorText: 'de',
                  }}
                  noDataComponent={
                    <>
                      <div className="d-flex align-items-center mt-20">
                        <div className="mt-20">
                          <h3 className="font-weight-mormal fs-5 timeline-content text-muted ">
                            No existen registros de tareas pendientes
                          </h3>
                          <div className="d-flex align-items-center">
                            <img
                              className="mx-auto  h-200px"
                              src={toAbsoluteUrl(
                                '/media/illustrations/sketchy-1/bg-task-empty.png'
                              )}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  }
                ></DataTable>
              ) : (
                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                  <div className="text-center">
                    <h1 className="fw-semibold text-gray-800 text-center lh-lg">
                      <span
                        className="indicator-progress text-primary"
                        style={{ display: 'block' }}
                      >
                        <div className="text-center mt-3">
                          <span className="spinner-border spinner-border-lg align-middle ms-2"></span>
                        </div>
                      </span>
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export { CardTask };
