import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuillEditor: React.FC = () => {
  const [content, setContent] = useState<string>('');

  const saveContent = () => {
    console.log(content); // Este HTML puede ser guardado
  };

  return (
    <div className="card mb-6 mb-xl-9">
      <div className="card-header"></div>
      <div className="card-body border-top p-9">
        <ReactQuill value={content} onChange={setContent} />
        <button onClick={saveContent}>Guardar</button>
      </div>
    </div>
  );
};

export default QuillEditor;
