import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { styleSelect } from '../../../../_metronic/helpers/SelectStyles';
import {
  maskPhoneNumber,
  cleanPhoneNumber,
} from '../../../../_metronic/helpers';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';
import { useEditSubaccount, useGetOne } from '../hooks/SubaccountHook';
import { useGetAllCuentas } from '../../contacto/hooks/ContactoHook';

type Props = {
  id: string;
  accountId: string;
  onRefreshHistory: () => void;
};
const subAccountSchema = Yup.object().shape({
  nombre: Yup.string().required('Nombre requerido.'),
  sitio_web: Yup.string().url('Debe ser una URL válida'),
});

const SubsubAccountForm: React.FC<Props> = ({
  id,
  accountId,
  onRefreshHistory,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // SERVICES
  const [reloadInfoCuenta, setReloadInfoCuenta] = useState(Math.random() * 40);
  const { cuentaDataSelect } = useGetAllCuentas(reloadInfoCuenta);

  const { initValues, loadingRequest } = useGetOne(Number(id));
  const { catalog: industrias } = useSelectGeneric('IND');
  const { editSubaccount, loadingEditSubccount } = useEditSubaccount();
  // FORM DATA
  const subAccountForm = useFormik({
    initialValues: initValues,
    validationSchema: subAccountSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      if (Number(values.id) > 0) {
        editSubaccount(values, Number(id), accountId);
      }
    },
  });

  // HELPERS
  const showMaskPhoneNumber = (values: any) => {
    let value = values.target.value.toString();
    value = cleanPhoneNumber(value);
    const maskedPhoneNumber = maskPhoneNumber(value);
    subAccountForm.setFieldValue('telefono', maskedPhoneNumber);
  };
  // EFFECTS
  useEffect(() => {
    if (loadingEditSubccount) {
      history.goBack();
    }
  }, [loadingEditSubccount]);
  return (
    <>
      <form
        id="kt_account_form"
        className="form mb-10"
        action="#"
        onSubmit={subAccountForm.handleSubmit}
      >
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-0">
            <div className="w-100 pe-5">
              <input
                className="form-control mb-2 w-100 border-0"
                style={{
                  width: '100%',
                  padding: '15px',
                  fontSize: '24px',
                  boxSizing: 'border-box',
                  marginBottom: '20px',
                }}
                data-kt-element="input"
                placeholder="Nombre *"
                {...subAccountForm.getFieldProps('nombre')}
              ></input>
              {subAccountForm.touched.nombre && subAccountForm.errors.nombre && (
                <div className="fv-plugins-message-container text-danger ms-3 w-100 mb-3">
                  <span role="alert">{subAccountForm.errors.nombre}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className="required form-label w-auto">Cuenta</label>
              </div>
              <Select
                options={cuentaDataSelect}
                styles={styleSelect}
                placeholder={'Seleccione'}
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{'No se encontraron registros'}</span>;
                }}
                value={
                  subAccountForm.values.cuenta_id
                    ? cuentaDataSelect.find(
                        (option) =>
                          option.value === subAccountForm.values.cuenta_id
                      )
                    : null
                }
                isDisabled={true}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className="form-label w-auto">Teléfono</label>
              </div>
              <input
                className="form-control mb-3"
                placeholder="Ejemplo: 81 1726 1744"
                value={maskPhoneNumber(subAccountForm.values.telefono || '')}
                prefix=""
                onChange={showMaskPhoneNumber}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">Industria</label>
              </div>
              <select
                className="form-select mb-3"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                {...subAccountForm.getFieldProps('industria_id')}
              >
                <option value={0}>Seleccione</option>
                {industrias.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">Sitio Web</label>
              </div>
              <input
                maxLength={250}
                {...subAccountForm.getFieldProps('sitio_web')}
                className="form-control mb-3"
                placeholder="Ejemplo: https://www.dominio.com"
              />
              {subAccountForm.touched.sitio_web &&
                subAccountForm.errors.sitio_web && (
                  <div className="fv-plugins-message-container text-danger w-100 mb-3">
                    <span role="alert">{subAccountForm.errors.sitio_web}</span>
                  </div>
                )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">Tamaño</label>
              </div>
              <NumericFormat
                name="tamano"
                className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                value={subAccountForm.values.tamano}
                decimalScale={0}
                decimalSeparator="."
                maxLength={9}
                fixedDecimalScale
                placeholder="Ejemplo: 10 empleados"
                prefix=""
                thousandSeparator=""
                onValueChange={(values) => {
                  const { value } = values;
                  subAccountForm.setFieldValue('tamano', value ? value : '');
                }}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">Referido por</label>
              </div>
              <input
                maxLength={100}
                {...subAccountForm.getFieldProps('nombre_referido')}
                className="form-control mb-3"
                placeholder="Ejemplo: Adrián Rodriguez"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">No. de proveedor</label>
              </div>
              <input
                maxLength={100}
                {...subAccountForm.getFieldProps('proveedor_id')}
                className="form-control mb-3"
                placeholder="Ejemplo: 19237188912023"
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={loading}
          >
            {!loading && <span className="indicator-label">Guardar</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Espere por favor...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  );
};
export { SubsubAccountForm };
