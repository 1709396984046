import React from 'react';
import { encryptId, Pagination } from '../../../../_metronic/helpers';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import { VendedorModel } from '../models/VendedorModel';
import { TableList } from '../../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../../_metronic/helpers/components/EmptyResult';
import { Link } from 'react-router-dom';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onRemoveRegister: (row: any) => void;
  onPageChange: (pageNumber: number) => void;
  onEditRegister: (row: any) => void;
  // onSeeDetail: (row: any) => void;
  loadingGrid: boolean;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onRemoveRegister,
  onPageChange,
  onEditRegister,
  // onSeeDetail,
  loadingGrid,
}) => {
  const Vendedor: Array<VendedorModel> = data;

  // setTimeout(() => {
  //   MenuComponent.reinitialization();
  // }, 1000);
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  MenuComponent.reinitialization();

  const columns: any = [
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.nombre,
      cell: (row: any) => <div className="text-gray-700">{row.nombre}</div>,
      sortable: true,
    },
    {
      id: 'apellido_paterno	',
      name: <div className="fw-bolder text-muted me-5">Apellido Paterno</div>,
      selector: (row: any) => row.apellido_paterno,
      cell: (row: any) => (
        <div className="text-gray-700">{row.apellido_paterno}</div>
      ),
      sortable: true,
    },
    {
      id: 'apellido_materno',
      name: <div className="fw-bolder text-muted me-5">Apellido Materno</div>,
      selector: (row: any) => row.apellido_materno,
      cell: (row: any) => (
        <div className="text-gray-700">{row.apellido_materno}</div>
      ),
      sortable: true,
    },
    {
      id: 'correo',
      name: (
        <div className="fw-bolder text-muted me-5">Correo Electrónico </div>
      ),
      selector: (row: any) => row.email,
      cell: (row: any) => (
        <div className="text-gray-700 fw-bolder">{row.email}</div>
      ),
      sortable: true,
    },
    {
      id: 'telefono',
      name: <div className="fw-bolder text-muted me-5">Teléfono</div>,
      selector: (row: any) => row.telefono,
      cell: (row: any) => (
        <div className="text-gray-700 fw-bolder">{row.telefono}</div>
      ),
      sortable: true,
    },
    {
      id: 'tipo',
      name: <div className="fw-bolder text-muted me-5">Tipo de Vendedor</div>,
      selector: (row: any) => row.tipoVendedor?.name,
      cell: (row: any) => (
        <span className="badge badge-light-primary w-50">
          {row.tipoVendedor?.name}
        </span>
      ),
      sortable: true,
    },
    {
      name: <div className="fw-bolder text-muted me-5">Acciones</div>,
      cell: (row: any) => <>{dropdownRender(row)}</>,
    },
  ];

  const dropdownRender = (row: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  onEditRegister(row.id);
                }}
              >
                Editar
              </a>

              {userHasRole(['ADMIN', 'DIR', 'CMA', 'TEC', 'SUP']) && (
                <a
                  className="menu-link px-3"
                  data-kt-users-table-filter="delete_row"
                  href="#/"
                  onClick={() => {
                    onRemoveRegister(row);
                  }}
                >
                  Desactivar
                </a>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`row`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100 w-100`}>
            {!loadingGrid ? (
              <TableList data={Vendedor} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={loadingGrid} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
