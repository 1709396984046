import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { encryptId, toAbsoluteUrl } from "../../../../_metronic/helpers";
type Props = {
  board: any;
  onRefreshData: () => void;
};

const CardList: FC<Props> = ({ board, onRefreshData }) => {
  const history = useHistory();
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(board.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, board.length);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          {board.slice(startIndex, endIndex).map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="col-md-6 col-lg-6 col-xxl-4 col-xl-6 mb-4 mt-4"
              >
                <div
                  className="card border borders-card-board cursor-pointer"
                  onClick={() =>
                    history.push(
                      `/recursos_humanos/colaboradores/edit/${encryptId(
                        item.id.toString()
                      )}`
                    )
                  }
                >
                  <div className="card-body d-flex flex-center flex-column p-9 ">
                    <div className="d-flex justify-content-between w-100 mb-5">
                      <div>
                        <a className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">
                          <div className="large-text">
                          {item.contacto?.nombre &&
                          item.contacto?.apellido_paterno 
                            ? `${item.contacto?.nombre} ${item.contacto?.apellido_paterno} ${item.contacto?.apellido_materno}`
                            : "No registrado"}
                            </div>
                        </a>
                        <div className="fw-bold text-gray-400 mb-6">
                          <div className="large-text">
                            {item.perfil?.name
                              ? item.perfil?.name
                              : "No registrado"}
                          </div>
                        </div>
                        <div className="fs-5 fw-bolder text-gray-700">
                          {item.celular
                            ? item.celular
                            : "No registrado"}
                        </div>
                      </div>
                      <div
                        className="symbol symbol-40px"
                        style={{ borderRadius: "8px" }}
                      >
                        {
                        item?.imagen ? (
                        <img 
                        alt="Pic" 
                        src={`${process.env.REACT_APP_PUBLIC}colaborador/${item?.imagen}`}
                        title={item.nombre}
                          crossOrigin="anonymous"
                          className="w-100"
                          style={{ borderRadius: '3px' }}
                        />
                      ) : (
                          <span
                            className={`symbol-label bg-light-primary text-${item.color} fs-5 fw-bolder`}
                          >
                            {item.contacto?.nombre && item.contacto?.apellido_paterno
                              ? `${item.contacto?.nombre.charAt(
                                  0
                                )}${item.contacto?.apellido_paterno.charAt(0)}`
                              : "NR"}{" "}
                            {/* "NR" for "No registrado" */}
                          </span>
                        )  
                        }
                      </div>
                    </div>
                    <div className="d-flex justify-content-end w-100">
                      <div className="rounded min-w-125px py-3 mx-3 px-4 mb-3">
                        <div className="fw-bold text-gray-400">
                          {/* {item.dateUpdate} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row justify-content-center">
          <nav aria-label="Navegación de página">
            <ul className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => onPageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export { CardList };
