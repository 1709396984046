import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { DropdownFilter } from '../../../../../_metronic/partials/content/dropdown/DropdownFilter';
import { CreateModal } from '../_modals/CreateModal';
import { ListGrid } from './ListGrid';
import { RemoveModal } from '../_modals/RemoveModal';
import { GenericoModel } from '../models/GenericModel';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import { useGetAll } from '../hooks/ProyectosHook';

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };
  const [loadingGrid, setLoadingGrid] = useState(true);
  // const [codeCatalog, setCodeCatalog] = useState(code_catalogo);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [visivilityActiveModal, setVisivilityActiveModal] = useState(false);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [register, setRegister] = useState<GenericoModel>({
    id: 0,
    codigo: '',
    nombre: '',
    orden: 0,
  });

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [filterName, setFilterName] = useState('');
  const [typeData, setTypeData] = useState(1);
  const [idRow, setIdRow] = useState<number>(0);

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { dataCategories, count, loadingRequest } = useGetAll(reloadGrid);

  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  const addRegister = () => {
    setTitleModal('Agregar registro');
    setVisivilityModal(!visivilityModal);
  };
  const removeRegister = (row: any) => {
    setRegister(row);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };
  const editRegister = (id: number) => {
    setIdRow(id);
    setTitleModal('Editar registro');
    setVisivilityModal(!visivilityModal);
  };

  const activeRegister = (row: any) => {
    setRegister(row);
    setVisivilityActiveModal(!visivilityActiveModal);
  };
  const handleFilter = (name: string) => {
    setLoadingGrid(true);
    if (name) {
      setFilterName(name);
    } else {
      setFilterName('');
    }
    setReloadGrid(Math.random() * 40);
  };
  const handleFilterClean = () => {
    setLoadingGrid(true);
    setFilterName('');
    setReloadGrid(Math.random() * 40);
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column">
                <div className="space-items-between"></div>
              </div>

              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={() => {
                    addRegister();
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>

            <ListGrid
              data={dataCategories}
              loadingGrid={loadingRequest}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              valueFilter={filterName}
              onRemoveRegister={removeRegister}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
              onEditRegister={editRegister}
            />
          </div>
        </div>
      </div>
      <CreateModal
        id_row={idRow}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setTypeData(1);
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
          setIdRow(0);
        }}
      ></CreateModal>
      <RemoveModal
        code_catalogo={'hola'}
        show={visivilityRemoveModal}
        register={register}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
            const newPage = Math.ceil(
              (count - 1 <= 0 ? 1 : count - 1) / pageSize
            );
            setCurrentPage(newPage);
          }
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
    </>
  );
};

export { View };
