import React, { useState, useEffect } from 'react';
import { History } from '../../../../_metronic/helpers/components/History';
import { PreloadView } from '../../../../_metronic/helpers';
import { NoteForm } from './NoteForm';

type Props = {
  id: string;
  contactoId: string;
};

const View: React.FC<Props> = ({ id, contactoId }) => {
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);
  const [search, setSearch] = useState<string>('');
 
  const onRefreshData = () => {
    setReloadGrid(Math.random() * 40);
  };

  return (
    <>
      {id ? (
        <div className="d-flex flex-column flex-xl-row">
          <div className="flex-lg-row-fluid ">
            <NoteForm
              id={id}
              contactoId={contactoId}
              onRefreshHistory={onRefreshData}
            />
          </div>

        </div>
      ) : (
        <PreloadView></PreloadView>
      )}
    </>
  );
};

export { View };
