import React from 'react';
import { KTSVG } from '../../../../_metronic/helpers';

type Props = {
  nombre: string;
  claseTarjeta: string;
  icono: string;
  onClickCard: () => void;
  onClickAdd: () => void;
};

const Tarjeta: React.FC<Props> = ({
  nombre,
  claseTarjeta,
  icono,
  onClickCard,
  onClickAdd,
}) => {
  return (
    <>
      <div
        className={`border col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn btn-active-primary px-6 py-8 rounded-2 me-7 mb-7 position-relative ${claseTarjeta}`}
        onClick={onClickCard}
      >
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex flex-column align-items-start">
            <KTSVG path={icono} className="svg-icon-3x d-block my-2" />
            <div className="mt-2">{nombre}</div>
          </div>
          <div
            className="d-block my-2 position-absolute top-0 end-0"
            onClick={(e) => {
              e.stopPropagation();
              onClickAdd();
            }}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen035.svg"
              className="svg-icon-2x"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { Tarjeta };
