import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  addValueSurcharge,
  updValueSurcharge,
} from '../../../service/surcharge/Surcharger';
import { toast } from 'react-toastify';
import { useGetOne } from '../hooks/SurchargeHook';
import { NumericFormat } from 'react-number-format';
import { useSelectComponentFormat } from '../../catalogos/generico/hooks/genericoHook';
import Select from 'react-select';
import { styleSelectPackages } from '../../../../_metronic/helpers/SelectStyles';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const { optionsSelect: monto } = useSelectComponentFormat('TCOIN', true);
  const { optionsSelect: currencyUnit } = useSelectComponentFormat(
    'UMON',
    true
  );
  const [tipoMonto, setTipoMOnto] = useState<any | null>(0);

  const genericSchema = Yup.object().shape({
    codigo: Yup.string().required('Código es requerido'),
    nombre: Yup.string().required('Nombre es requerido.'),
    valor: Yup.number().min(1, 'Valor es requerido.'),
    tipo_id: Yup.number().min(1, 'Tipo es requerido.'),
    unidad_moneda_id: Yup.number().min(1, 'Tipo de moneda es requerido.'),
  });

  useEffect(() => {
    if (initValues.id) {
      const filteType = monto.filter(
        (item) => item.value === initValues.tipo_id
      );
      setTipoMOnto(filteType[0]);
    }
  }, [initValues]);

  const formGeneric = useFormik({
    initialValues: initValues,
    validationSchema: genericSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      if (tipoMonto.code === 'TCPOR') {
        values.valor = Number(values.valor) / 100;
      }

      setTimeout(() => {
        if (values.id > 0) {
          updValueSurcharge(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              formGeneric.resetForm();
              setTipoMOnto(null);
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        } else {
          addValueSurcharge(values)
            .then((res) => {
              formGeneric.resetForm();
              const {
                data: { message },
              } = res;
              toast.success(message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
              setLoading(false);
              handleClose(true);
              setTipoMOnto(null);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              toast.error(resMessageToast, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      }, 1000);
    },
  });

  const handleSelect = (selectedOption: any) => {
    formGeneric.setFieldValue('valor', 0);
    const { value } = selectedOption;
    setTipoMOnto(selectedOption);
    formGeneric.setFieldValue('tipo_id', value);
  };

  const changeCurrencyUnit = (selectedOption: any) => {
    const { value } = selectedOption;
    formGeneric.setFieldValue('unidad_moneda_id', value);
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        formGeneric.resetForm();
        handleClose(false);
        setTipoMOnto(0);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formGeneric.resetForm();
              setTipoMOnto(0);
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formGeneric.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              {formGeneric.status && (
                <div className="alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formGeneric.status}
                  </div>
                </div>
              )}
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Código</label>
                </div>
                <input
                  placeholder="Código"
                  {...formGeneric.getFieldProps('codigo')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="codigo"
                  autoComplete="off"
                />
                {formGeneric.touched.codigo && formGeneric.errors.codigo && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">{formGeneric.errors.codigo}</span>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Nombre</label>
                </div>
                <input
                  placeholder="Nombre"
                  {...formGeneric.getFieldProps('nombre')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="nombre"
                  autoComplete="off"
                />
                {formGeneric.touched.nombre && formGeneric.errors.nombre && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">{formGeneric.errors.nombre}</span>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    {' '}
                    Tipo de moneda
                  </label>
                </div>
                <Select
                  options={currencyUnit}
                  value={
                    formGeneric.values.unidad_moneda_id
                      ? currencyUnit.find(
                          (option) =>
                            option.value === formGeneric.values.unidad_moneda_id
                        )
                      : null
                  }
                  styles={styleSelectPackages}
                  placeholder={'Seleccione'}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{'No se encontraron registros'}</span>;
                  }}
                  onChange={changeCurrencyUnit}
                />
                {formGeneric.touched.unidad_moneda_id &&
                  formGeneric.errors.unidad_moneda_id && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">
                        {formGeneric.errors.unidad_moneda_id}
                      </span>
                    </div>
                  )}
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Tipo</label>
                </div>
                <Select
                  options={monto}
                  styles={styleSelectPackages}
                  menuPosition={'fixed'}
                  placeholder={'Seleccione'}
                  isSearchable={true}
                  noOptionsMessage={() => (
                    <span>No se encontraron registros</span>
                  )}
                  onChange={(selectedOption) => {
                    handleSelect(selectedOption);
                  }}
                  value={tipoMonto}
                />
                {formGeneric.errors.tipo_id && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">{formGeneric.errors.tipo_id}</span>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7">
                {tipoMonto ? (
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2">Valor</label>
                  </div>
                ) : null}
                {tipoMonto && tipoMonto.code === 'TCPOR' ? (
                  <NumericFormat
                    className="form-control fs-7"
                    value={formGeneric.values.valor}
                    thousandSeparator={false}
                    allowNegative={false}
                    suffix={'%'}
                    // prefix={tipoMonto?.code === "TCMFIJ" ? "$ " : ""}
                    allowLeadingZeros={false}
                    isAllowed={({ value }) => {
                      const numericValue = parseFloat(value);

                      if (tipoMonto?.value === 934) {
                        if (numericValue < 0 || numericValue > 100) {
                          return false;
                        }
                        const integerValue = parseInt(value);
                        if (integerValue < 10 && value.length > 1) {
                          return false;
                        }
                        if (integerValue < 100 && value.length > 2) {
                          return false;
                        }
                        return true;
                      } else if (tipoMonto?.value === 935) {
                        return !isNaN(numericValue) && numericValue >= 0;
                      }
                      return true;
                    }}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      formGeneric.setFieldValue('valor', floatValue || 0);
                    }}
                  />
                ) : null}

                {tipoMonto && tipoMonto?.code === 'TCMFIJ' ? (
                  <NumericFormat
                    className="form-control fs-7"
                    thousandSeparator={true}
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    value={formGeneric.values.valor}
                    isAllowed={(values: any) => {
                      const { floatValue } = values;
                      const integerPart = Math.floor(floatValue);
                      return integerPart.toString().length <= 9;
                    }}
                    onValueChange={(values: any) => {
                      const { floatValue } = values;
                      formGeneric.setFieldValue('valor', floatValue || 0);
                    }}
                  />
                ) : null}

                {formGeneric.touched.valor && formGeneric.errors.valor && (
                  <div className="fv-plugins-message-container text-danger w-100 ms-5">
                    <span role="alert">{formGeneric.errors.valor}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formGeneric.resetForm();
                  setTipoMOnto(null);
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {id_row > 0 ? 'Actualizar' : 'Guardar'}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
