import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useCreateNote } from '../hooks/ContactoHook';
import type { NoteContactModel } from '../models/ContactoModel';

type Props = {
  show: boolean;
  handleClose: (refresh: boolean) => void;
  contacto_id: number;
};

const NoteSchema = Yup.object().shape({
  nombre: Yup.string()
    .trim()
    .test(
      'not-only-spaces',
      'El nombre no puede contener solo espacios.',
      (value) => value?.trim() !== ''
    )
    .required('Nombre es requerido.'),
  descripcion: Yup.string().required('Este campo es requerido.'),
});

const CreateNoteModal: React.FC<Props> = ({
  show,
  handleClose,
  contacto_id,
}) => {
  const [loading, setLoading] = useState(false);
  const { createNote, loadingCreateNote } = useCreateNote();

  const initValues: NoteContactModel = {
    id: 0,
    nombre: '',
    descripcion: '',
  };

  const noteForm = useFormik({
    initialValues: initValues,
    validationSchema: NoteSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(async () => {
        await createNote(values, contacto_id);
        setLoading(false);
      }, 1000);
    },
  });

  // EFFECTS
  useEffect(() => {
    if (loadingCreateNote) {
      handleClose(true);
      setLoading(false);
      noteForm.resetForm();
    }
  }, [loadingCreateNote]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-800px"
      show={show}
      onHide={() => {
        noteForm.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">Nueva nota</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              noteForm.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={noteForm.handleSubmit}
          >
            <div className="row wh-0100 mb-3">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
                <input
                  className="form-control mb-2 w-100 border-0 padd-left input-name-form"
                  data-kt-element="input"
                  placeholder="Nombre *"
                  maxLength={40}
                  {...noteForm.getFieldProps('nombre')}
                ></input>
                {noteForm.touched.nombre && noteForm.errors.nombre && (
                  <div className="fv-plugins-message-container text-danger w-100">
                    <span role="alert">{noteForm.errors.nombre}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Descripción
                    </label>
                  </div>
                  <textarea
                    className="form-control mb-3 w-100"
                    data-kt-element="input "
                    rows={5}
                    style={{ resize: 'none' }}
                    placeholder=""
                    maxLength={255}
                    {...noteForm.getFieldProps('descripcion')}
                  ></textarea>
                  {noteForm.touched.descripcion && noteForm.errors.descripcion && (
                    <div className="fv-plugins-message-container text-danger w-100">
                      <span role="alert">{noteForm.errors.descripcion}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  noteForm.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateNoteModal };
