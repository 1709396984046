import { FC } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import { View } from './View';

const HumanResourcesSettingsPage: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Catálogos',
            path: `/catalogos/`,
            isSeparator: false,
            isActive: false,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        Configurar número empleado
      </PageTitle>
      <View />
    </>
  );
};

export { HumanResourcesSettingsPage };
