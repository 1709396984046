import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CategoryModel } from "../models/QuotationModel";
import { toast } from "react-toastify";
import {
  getCategoriesPackage,
  insertCategoryPackage,
  getAllPackages,
  obtenerTodosPaquetes,
} from "../../../service/products/Products";
import { MODULS } from "../components/View";
import { GenericCatalogModel } from "../../../../_metronic/helpers/models/GenericModel";
import {
  eliminarPaquete,
  estatusPaquete,
  getCatFieldsPackages,
  getDataFilterBySelectFiledPackages,
} from "../../../service/packages/Packages";

export const useGetCategoryPack = (
  realoadGrid: number,
  filters: any,
  module: number
) => {
  const [dataCatPack, setDataCatPack] = useState<CategoryModel[]>([]);
  const [loadingRequestPack, setLoadingRequestPack] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequestPack(0);
      const res = await getCategoriesPackage(filters)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataCatPack(res.data);
        setLoadingRequestPack(1);
      }
    };
    if (module === MODULS.PAQUETES) {
      fetchPost();
    }
  }, [realoadGrid, filters, , module]);

  return { dataCatPack, loadingRequestPack };
};

export const useCreateCategoryPackage = () => {
  const [loadingCreateCategoryPack, setLoadingCreateCategoryPack] =
    useState<boolean>(false);
  const [isCorrectInsertCPA, setIsCorrectInsertCPA] = useState<boolean>(false);

  const createCategoryPackage = async (category: CategoryModel) => {
    setLoadingCreateCategoryPack(true);
    try {
      await insertCategoryPackage(category).then(() => {
        setLoadingCreateCategoryPack(false);
        setIsCorrectInsertCPA(true);
      });
    } catch (err: any) {
      setIsCorrectInsertCPA(false);
      let resMessageToast: string = "";
      const {
        data: { message },
      } = err.response;
      resMessageToast = `${message}`;
      toast.error(resMessageToast, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setLoadingCreateCategoryPack(false);
      throw resMessageToast;
    }
  };
  return {
    createCategoryPackage,
    loadingCreateCategoryPack,
    isCorrectInsertCPA,
  };
};

export const useGetAllPack = (
  realoadGrid: number,
  // startIndex: number,
  // endIndex: number,
  // filters: any
  filters: any,
  module: number
) => {
  const [dataPackages, setDataPackages] = useState<CategoryModel[]>([]);
  const [loadingRequestPackages, setLoadingRequestPackages] =
    useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequestPackages(true);
      const res = await getAllPackages(filters)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataPackages(res.data);
        setLoadingRequestPackages(false);
      }
    };
    if (module === MODULS.PAQUETES) {
      fetchPost();
    }
  }, [realoadGrid, filters, module]);

  return { dataPackages, loadingRequestPackages };
};
export const useObtenerTodosPaquetes = (
  realoadGrid: number,
  // startIndex: number,
  // endIndex: number,
  // filters: any
  filters: any,
  module: number
) => {
  const [dataPackages, setDataPackages] = useState<CategoryModel[]>([]);
  const [loadingRequestPackages, setLoadingRequestPackages] =
    useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequestPackages(true);
      const res = await obtenerTodosPaquetes(filters)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setDataPackages(res.data);
        setLoadingRequestPackages(false);
      }
    };
    if (module === MODULS.PAQUETES) {
      fetchPost();
    }
  }, [realoadGrid, filters, module]);

  return { dataPackages, loadingRequestPackages };
};

export const useGetDataSelectedByFieldPackages = (code: string) => {
  const [dataOptionSelected, setDataOptionSelected] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getDataFilterBySelectFiledPackages(code)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        const data = code === "PAQ_NOM" ? res?.rows : res;

        const selectOptions = data.map((option: any) => ({
          value: option.id,
          label: option?.name ? option?.name : option?.nombre,
        }));

        setDataOptionSelected(selectOptions);
        setLoadingRequest(1);
      }
    };

    if (code) {
      fetchPost();
    }
  }, [code]);

  return {
    dataOptionSelected,
    loadingRequest,
  };
};

export const useGetFieldsPackages = () => {
  const [listFields, setlistFields] = useState<GenericCatalogModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getCatFieldsPackages()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        const selectOptions = res.map((option: any) => ({
          value: option.id,
          label: `${option.name} `,
          code: option.code,
        }));
        setlistFields(selectOptions);
        setLoadingRequest(1);
      }
    };
    fetchPost();
  }, []);

  return {
    listFields,
    loadingRequest,
  };
};


export const useEstatusPaquete = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isEstatusCorrect, setIsEstatusCorrect] = useState<boolean>(false);

  const cambiarEstatusPaquete = async (id: number, estatus: number) => {
    setLoading(true);
    try {

      await estatusPaquete(id, estatus)
        .then((res) => {
          setIsEstatusCorrect(true);
          toast.success(`${res.data.message}`, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {
          const {
            data: { message },
          } = err.response;
          const errorMessage = message || "Error al cambiar el estado del paquete.";
          // Notificación de error
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });

    }catch(err: any){

      toast.error(err.message || "Error inesperado al cambiar el estado del paquete.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } finally {
      setLoading(false);
    }

  }
  return { cambiarEstatusPaquete, loading, isEstatusCorrect };
};


export const useEliminarPaquete = () => {
  const [isDeletedCorrect, setIsDeletedCorrect] = useState<boolean>(false);

  const eliminarPaquetes = async (id: number) => {
    try {
      await eliminarPaquete(id)
        .then((res) => {
          setIsDeletedCorrect(true);
          toast.success(`${res.data.message}`, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {
          setIsDeletedCorrect(false);
          let resMessageToast: string = "";
          const {
            data: { message },
          } = err.response;
          resMessageToast = `${message}`;
          toast.error(resMessageToast, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } catch (err: any) {
      setIsDeletedCorrect(false);
      toast.error(err.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return { eliminarPaquetes, isDeletedCorrect };
};

