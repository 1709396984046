import React, { useState, useEffect } from 'react';
import { Pagination } from '../../../../../_metronic/helpers';
import { MenuComponent } from '../../../../../_metronic/assets/ts/components/MenuComponent';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import { KTSVG } from '../../../../../_metronic/helpers';
import { Tooltip } from '@mui/material';
import { encryptId } from '../../../../../_metronic/helpers';
import { TableList } from '../../../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../../../_metronic/helpers/components/EmptyResult';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  valueFilter: string;
  onEditRegister: (row: any) => void;
  onRemoveRegister: (row: any) => void;
  onPageChange: (pageNumber: number) => void;
  loadingGrid: boolean;
  code: string;
};

const ListGrid: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onEditRegister,
  onRemoveRegister,
  valueFilter,
  onPageChange,
  loadingGrid,
  code,
}) => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  const [isCatStage, setIsCatStage] = useState<boolean>(false);

  useEffect(() => {
    setIsCatStage(false);
    if (code === 'ETP') {
      setIsCatStage(true);
    }
  }, [code]);
  useEffect(() => {
    MenuComponent.reinitialization();
  }, [loadingGrid]);

  const columns: any = [
    {
      id: 'codigo',
      name: <div className="fw-bolder text-muted me-5">Código</div>,
      selector: (row: any) => row.code,
      cell: (row: any) => (
        <>
          <div className="d-flex align-items-center">
            {code === 'ETP' ? (
              <>
                <div
                  className={`d-flex justify-content-start flex-column ${
                    row.code === 'PRN' ||
                    row.code === 'CGA' ||
                    row.code === 'CPE' ||
                    row.code === 'COTI'
                      ? 'text-primary'
                      : ''
                  } `}
                >
                  {row.code}
                </div>
              </>
            ) : code === 'ETAR' ? (
              <>
                <div
                  className={`d-flex justify-content-start flex-column ${
                    row.code === 'TPEND' ||
                    row.code === 'TEPRO' ||
                    row.code === 'TCOMPL' ||
                    row.code === 'TCANC'
                      ? 'text-primary'
                      : ''
                  } `}
                >
                  {row.code}
                </div>
              </>
            ) : (
              <>
                <div className={`d-flex justify-content-start flex-column`}>
                  {row.code}
                </div>
              </>
            )}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      id: 'color',
      name: <div className="fw-bolder text-muted me-5">Color</div>,
      selector: (row: any) => row.color,
      omit: code !== 'ETAR' && !isCatStage,
      cell: (row: any) => (
        <>
          {code === 'ETAR' && (
            <>
              <td>
                <div className="d-flex align-items-center">
                  <div className={`d-flex justify-content-start flex-column `}>
                    <i
                      className="fa fa-genderless fs-1"
                      style={{ color: row?.etapaColorTarea?.color }}
                    ></i>
                  </div>
                </div>
              </td>
            </>
          )}

          {isCatStage && (
            <td>
              <div className="d-flex align-items-center">
                <div className={`d-flex justify-content-start flex-column `}>
                  <i
                    className="fa fa-genderless fs-1"
                    style={{ color: row?.etapaColor?.color }}
                  ></i>
                </div>
              </div>
            </td>
          )}
        </>
      ),
      sortable: false,
    },
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.name,
      cell: (row: any) => (
        <>
          <div className="d-flex align-items-center">
            {code === 'ETP' ? (
              <>
                <div
                  className={`d-flex justify-content-start flex-column ${
                    row.code === 'PRN' ||
                    row.code === 'CGA' ||
                    row.code === 'CPE' ||
                    row.code === 'COTI'
                      ? 'text-primary'
                      : ''
                  } `}
                >
                  {row.name}
                </div>
              </>
            ) : code === 'ETAR' ? (
              <>
                <div
                  className={`d-flex justify-content-start flex-column ${
                    row.code === 'TPEND' ||
                    row.code === 'TEPRO' ||
                    row.code === 'TCOMPL' ||
                    row.code === 'TCANC'
                      ? 'text-primary'
                      : ''
                  } `}
                >
                  {row.name}
                </div>
              </>
            ) : (
              <>
                <div className={`d-flex justify-content-start flex-column`}>
                  {row.name}
                </div>
              </>
            )}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      id: 'orden',
      name: <div className="fw-bolder text-muted me-5">Orden</div>,
      selector: (row: any) => row.order,
      cell: (row: any) => (
        <>
          <div className="d-flex align-items-center">
            {code === 'ETP' ? (
              <>
                <div
                  className={`d-flex justify-content-start flex-column ${
                    row.code === 'PRN' ||
                    row.code === 'CGA' ||
                    row.code === 'CPE' ||
                    row.code === 'COTI'
                      ? 'text-primary'
                      : ''
                  } `}
                >
                  {row.order}
                </div>
              </>
            ) : code === 'ETAR' ? (
              <>
                <div
                  className={`d-flex justify-content-start flex-column ${
                    row.code === 'TPEND' ||
                    row.code === 'TEPRO' ||
                    row.code === 'TCOMPL' ||
                    row.code === 'TCANC'
                      ? 'text-primary'
                      : ''
                  } `}
                >
                  {row.order}
                </div>
              </>
            ) : (
              <>
                <div className={`d-flex justify-content-start flex-column`}>
                  {row.order}
                </div>
              </>
            )}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: <div className="fw-bolder text-muted me-5">Acciones</div>,
      cell: (row: any) => (
        <>
          {code === 'ETP' ? (
            <>
              {row.code === 'PRN' ||
              row.code === 'CGA' ||
              row.code === 'COTI' ||
              row.code === 'CPE' ? (
                <>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: 'pre-wrap' }}>
                          {row.code === 'PRN'
                            ? 'Prospección'
                            : row.code === 'CGA'
                            ? 'Venta'
                            : row.code === 'CPE'
                            ? 'Venta perdida'
                            : 'Cotización'}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <span
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        <a
                          href="#/"
                          className="btn btn-light btn-active-light-primary btn-sm"
                        >
                          <div style={{ fontSize: '11px' }}>Información</div>
                          <KTSVG
                            path="/media/icons/duotune/general/gen045.svg"
                            className="svg-icon-5 svg-icon-gray-500 me-1"
                          />
                        </a>
                      </span>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <>{dropdownRender(row)}</>
              )}
            </>
          ) : code === 'ETAR' ? (
            <>
              {row.code === 'TPEND' ||
              row.code === 'TEPRO' ||
              row.code === 'TCOMPL' ||
              row.code === 'TCANC' ? (
                <>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: 'pre-wrap' }}>
                          {row.code === 'TPEND'
                            ? 'Pendiente'
                            : row.code === 'TEPRO'
                            ? 'En proceso'
                            : row.code === 'TCOMPL'
                            ? 'Completada'
                            : 'Cancelada'}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <span
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        <a
                          href="#/"
                          className="btn btn-light btn-active-light-primary btn-sm"
                        >
                          <div style={{ fontSize: '11px' }}>Información</div>
                          <KTSVG
                            path="/media/icons/duotune/general/gen045.svg"
                            className="svg-icon-5 svg-icon-gray-500 me-1"
                          />
                        </a>
                      </span>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <>{dropdownRender(row)}</>
              )}
            </>
          ) : code === 'TTASK' ? (
            <>
              {row.code === 'ACT' ||
              row.code === 'SESS' ||
              row.code === 'CIT' ? (
                <>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: 'pre-wrap' }}>
                          {row.code === 'ACT'
                            ? 'Actividad'
                            : row.code === 'SESS'
                            ? 'Sesión'
                            : 'Cita'}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      <span
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        <a
                          href="#/"
                          className="btn btn-light btn-active-light-primary btn-sm"
                        >
                          <div style={{ fontSize: '11px' }}>Información</div>
                          <KTSVG
                            path="/media/icons/duotune/general/gen045.svg"
                            className="svg-icon-5 svg-icon-gray-500 me-1"
                          />
                        </a>
                      </span>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <>{dropdownRender(row)}</>
              )}
            </>
          ) : (
            <>{dropdownRender(row)}</>
          )}
        </>
      ),
      sortable: false,
    },
  ];

  const dropdownRender = (row: any) => {
    return (
      <>
        {/* {userHasRole(['ADMIN']) && code != 'TMO' && ( */}
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  onEditRegister(row.id);
                }}
              >
                Editar
              </a>

              {userHasRole(['ADMIN', 'DIR', 'CMA', 'TEC', 'SUP']) &&
                code !== 'TMO' &&
                code !== 'TDR' &&
                code !== 'TVE' &&
                code !== 'UMON' && (
                  <a
                    className="menu-link px-3"
                    data-kt-users-table-filter="delete_row"
                    href="#/"
                    onClick={() => {
                      onRemoveRegister(row);
                    }}
                  >
                    {code === 'TOP' ? 'Desactivar' : 'Eliminar'}
                  </a>
                )}
            </div>
          </div>
        </div>
        {/* )} */}
      </>
    );
  };

  return (
    <>
      <div className={`row gx-5 gx-xl-10`}>
        <div className="col-xl-12">
          <div className={`card mb-0 mb-xxl-8 h-100`}>
            {!loadingGrid ? (
              <TableList data={data} columns={columns}></TableList>
            ) : (
              <EmptyResult title="" loading={loadingGrid} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { ListGrid };
