import React, { useState, useEffect, useRef } from 'react';
import { PackageModel } from '../models/QuotationModel';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { encryptId, formatPrice, KTSVG } from '../../../../_metronic/helpers';
import { formattedCurrency } from '../../../../_metronic/helpers/FormatCurrency';
import { useEliminarPaquete, useEstatusPaquete } from '../hooks/PackagesHook';
import { validarPaquete } from '../../../service/packages/Packages';

type Props = {
  items: PackageModel[];
  onRefreshData: () => void;
  active: boolean;
  isEdit: boolean;
  categoriId: number;
};

const PackageList: React.FC<Props> = ({
  items,
  active,
  isEdit,
  categoriId,
  onRefreshData,
}) => {
  const [cargandoEliminacion, setCargandoEliminacion] = useState<number>(0);
  const [taskMemo, setTaskMemo] = useState<PackageModel[]>(items);
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 40);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showModalSupplier, setShowModalSupplier] = useState<boolean>(false);
  const [idSection, setIdSection] = useState<number>(0);
  const { cambiarEstatusPaquete, isEstatusCorrect } = useEstatusPaquete();
  const { eliminarPaquetes, isDeletedCorrect } = useEliminarPaquete();

  useEffect(() => {
    setTaskMemo(items);
  }, [items]);

  useEffect(() => {
    if (isDeletedCorrect) {
      onRefreshData();
    }
  }, [isDeletedCorrect]);

  useEffect(() => {
    if (isEstatusCorrect) {
      onRefreshData();
    }
  }, [isEstatusCorrect]);

  const eliminarPaq = async (id: number, nombre: any) => {
    setCargandoEliminacion(id);
    await validarPaquete(id)
      .then((res) => {
        const { existeEnCotizaciones } = res.data.doc.data;
        if (existeEnCotizaciones) {
          let conceptos = 'cotizaciones';

          Swal.fire({
            title: 'Paquete en uso!',
            html: `
                <div className="text-gray-600">
                  "<b>${nombre}</b>", no puede ser eliminado porque está en uso en ${conceptos}.
                </div>
                `,
            showCancelButton: true,
            showConfirmButton: false,
            padding: '2em',
            cancelButtonText: 'Cerrar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            } else {
              setCargandoEliminacion(0);
            }
          });
        } else {
          Swal.fire({
            title: '¿Deseas eliminar el paquete?',
            html: `
                <div className="text-gray-600">
                  Se va a eliminar el paquete "<b>${nombre}</b>", una vez
                  eliminado ya no se podrá recuperar.
                </div>
                `,
            showCancelButton: true,
            padding: '2em',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              eliminarPaquetes(Number(id));
            } else {
              setCargandoEliminacion(0);
            }
          });
        }
      })
      .catch((err) => {
        const {
          data: { message },
        } = err.response;
        const errorMessage =
          message || 'Error al cambiar el estado del producto.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setCargandoEliminacion(0);
      });
  };

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-200px fw-bolder">Nombre</th>
              <th className="w-100px fw-bolder">Foto</th>
              <th className="w-200px fw-bolder">Clasificación</th>
              <th className="w-150px fw-bolder">Precio venta</th>
              <th className="w-150px fw-bolder">Impuesto</th>
              <th className="w-150px fw-bolder">Precio venta final</th>
              <th className="w-100px fw-bolder">Acciones</th>
            </tr>
          </thead>
          <tbody className="">
            {taskMemo.map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      <Link
                        to={`/cotizaciones/paquetes/edit/${encryptId(
                          row.id.toString()
                        )}`}
                      >
                        {`${row.nombre}`}
                      </Link>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      <div className="symbol symbol-25px tooltip-soft">
                        {row.package_photo ? (
                          <img
                            src={`${process.env.REACT_APP_PUBLIC}paquete/${row?.package_photo}`}
                            title={row.nombre}
                            crossOrigin="anonymous"
                            className="symbol-label fs-5 "
                            alt="user"
                          />
                        ) : (
                          <KTSVG
                            path="/media/icons/duotune/general/gen006.svg"
                            className="symbol-label fs-5 "
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex text-center flex-column">
                      <div className="text-gray-700">
                        {row.clasificacion
                          ? row.clasificacion.name
                          : 'Sin unidad de moneda'}
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ minWidth: '150px', overflowX: 'auto' }}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.precio_sin_impuestos) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.moneda
                            ? `/ ${row.moneda.code}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ minWidth: '150px', overflowX: 'auto' }}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.impuestos) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.moneda
                            ? `/ ${row.moneda.code}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ minWidth: '150px', overflowX: 'auto' }}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.precio_unitario) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.moneda
                            ? `/ ${row.moneda.code}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="">
                    <>
                      <div
                        className={`btn btn-sm btn-active-light-primary btn-icon ${
                          row.activo ? 'btn-light-success ' : 'btn-light-danger'
                        }`}
                        onClick={(event) => {
                          let titulo = '';
                          let html = '';
                          let nuevoEstatus = 0;

                          if (row.activo) {
                            titulo = '¿Deseas desactivar el paquete?';
                            html = `
                                <div className="text-gray-600">
                                  Se desactivará el paquete "<b>${row.nombre}</b>", una vez
                                  desactivado ya no se podrá utilizar en futuras cotizaciones.
                                </div>
                                `;
                            nuevoEstatus = 0;
                          } else {
                            titulo = '¿Deseas activar el paquete?';
                            html = `
                                <div className="text-gray-600">
                                  Se activará el paquete "<b>${row.nombre}</b>", una vez
                                  activado se podrá utilizar en cotizaciones.
                                </div>
                                `;
                            nuevoEstatus = 1;
                          }

                          Swal.fire({
                            title: titulo,
                            html: html,
                            showCancelButton: true,
                            padding: '2em',
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Aceptar',
                            reverseButtons: true,
                            customClass: {
                              confirmButton: 'btn btn-primary',
                              cancelButton: 'btn btn-light me-3',
                            },
                          }).then((result) => {
                            if (result.isConfirmed) {
                              cambiarEstatusPaquete(
                                Number(row.id),
                                Number(nuevoEstatus)
                              );
                            }
                          });
                        }}
                        title="Activar/Desactivar paquete"
                      >
                        <KTSVG path="/media/icons/duotune/general/gen019.svg" />
                      </div>
                    </>
                    <>
                      {cargandoEliminacion === row.id ? (
                        <>
                          <div className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2">
                            <span
                              className="indicator-progress"
                              style={{ display: 'block' }}
                            >
                              <span className="spinner-border spinner-border-sm align-middle"></span>
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2"
                            onClick={(event) => {
                              eliminarPaq(Number(row.id), row.nombre);
                            }}
                            title="Eliminar paquete"
                          >
                            <KTSVG path="/media/icons/duotune/general/gen027.svg" />
                          </div>
                        </>
                      )}
                    </>
                    {/* <>
                      <div
                        className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2"
                        onClick={(event) => {
                          eliminarPaq(Number(row.id), row.nombre);
                        }}
                        title="Eliminar paquete"
                      >
                        <KTSVG path="/media/icons/duotune/general/gen027.svg" />
                      </div>
                    </> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export { PackageList };
