import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ServiceModel } from '../models/QuotationModel';
import { KTSVG, encryptId, formatPrice } from '../../../../_metronic/helpers';
import Swal from 'sweetalert2';
import {
  useInsertServiceShortcut,
  useUpdateServiceShortcut,
  useUploadImageServ,
  useUploadDocServ,
  useRemoveService,
  useEstatusServicio,
} from '../hooks/ServicesHook';
import { CreateSupplierModal } from '../_modals/CreateSupplierModal';
import { validarServicio } from '../../../service/services/Services';
import { toast } from 'react-toastify';

type Props = {
  items: ServiceModel[];
  onRefreshData: () => void;
  onRefreshDataSupplier: () => void;
  categoriId: number;
  isNewService: boolean;
  catUnidadMoneda: any;
  catUnidadMedida: any;
  catTipoImpuesto: any;
  catProveedores: any;
};

const ServiceList: React.FC<Props> = ({
  items,
  categoriId,
  onRefreshData,
  isNewService,
  onRefreshDataSupplier,
}) => {
  const [cargandoEliminacion, setCargandoEliminacion] = useState<number>(0);
  const [taskMemo, setTaskMemo] = useState<ServiceModel[]>(items);
  const [taskName, setTaskName] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [showModalSupplier, setShowModalSupplier] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<number>(0);
  const { removeServices, isDeletedCorrect } = useRemoveService();
  const { cambiarEstatusServicio, isEstatusCorrect } = useEstatusServicio();
  const [removePesoSign, setRemovePesoSign] = useState<boolean>(false);

  useEffect(() => {
    if (isDeletedCorrect) {
      onRefreshData();
    }
  }, [isDeletedCorrect]);

  useEffect(() => {
    if (isEstatusCorrect) {
      onRefreshData();
    }
  }, [isEstatusCorrect]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [isNewService]);

  useEffect(() => {
    if (inputRef.current && document.activeElement === inputRef.current) {
      inputRef.current.classList.add('input-scroll-animation');
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.classList.remove('input-scroll-animation');
        }
      }, 500); // 0.5 segundos, igual a la duración de la animación CSS
    }
  }, [inputRef.current]);

  useEffect(() => {
    setTaskMemo(items);
  }, [items]);

  const eliminarServicio = async (id: number, nombre: string) => {
    setCargandoEliminacion(id);

    await validarServicio(id)
      .then((res) => {
        const { existeEnPaquetes, existeEnCotizaciones } = res.data.doc.data;
        if (existeEnPaquetes || existeEnCotizaciones) {
          let conceptos = '';
          if (existeEnPaquetes) {
            conceptos += 'paquetes';
          }
          if (existeEnPaquetes && existeEnCotizaciones) {
            conceptos += ' y ';
          }
          if (existeEnCotizaciones) {
            conceptos += 'cotizaciones';
          }

          Swal.fire({
            title: 'Servicio en uso!',
            html: `
                <div className="text-gray-600">
                  "<b>${nombre}</b>", no puede ser eliminado porque está en uso en ${conceptos}.
                </div>
                `,
            showCancelButton: true,
            showConfirmButton: false,
            padding: '2em',
            cancelButtonText: 'Cerrar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
            } else {
              setCargandoEliminacion(0);
            }
          });
        } else {
          Swal.fire({
            title: '¿Deseas eliminar el servicio?',
            html: `
                <div className="text-gray-600">
                  Se va a eliminar el servicio "<b>${nombre}</b>", una vez
                  eliminado ya no se podrá recuperar.
                </div>
                `,
            showCancelButton: true,
            padding: '2em',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light me-3',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              removeServices(Number(id));
            } else {
              setCargandoEliminacion(0);
            }
          });
        }
      })
      .catch((err) => {
        const {
          data: { message },
        } = err.response;
        const errorMessage =
          message || 'Error al cambiar el estado del producto.';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setCargandoEliminacion(0);
      });
  };

  return (
    <>
      <div className="table-responsive min-h-200px me-n5 pe-5">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-400 fs-7">
              <th className="w-200px fw-bolder">Nombre</th>
              <th className="w-100px fw-bolder">Foto</th>
              <th className="w-150px fw-bolder">Precio de venta</th>
              <th className="w-150px fw-bolder">Tipo de impuesto</th>
              <th className="w-150px fw-bolder">Impuesto</th>
              <th className="w-150px fw-bolder">Precio venta final</th>
              <th className="w-100px fw-bolder">Acciones</th>
            </tr>
          </thead>
          <tbody className="">
            {taskMemo.map((row: ServiceModel, index: number) => (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-muted">
                      <Link
                        to={`/cotizaciones/servicios/edit/${encryptId(
                          row.id.toString()
                        )}`}
                      >
                        {row.nombre}
                      </Link>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column fs-012">
                      <div className="symbol symbol-25px tooltip-soft">
                        {row.imagen ? (
                          <img
                            src={`${process.env.REACT_APP_PUBLIC}servicio/${row?.imagen}`}
                            title={row.nombre}
                            crossOrigin="anonymous"
                            className="symbol-label fs-5 "
                            alt="servicio"
                          />
                        ) : (
                          <KTSVG
                            path="/media/icons/duotune/general/gen006.svg"
                            className="symbol-label fs-5 "
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </td>

                <td style={{ minWidth: '150px', overflowX: 'auto' }}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {row.tipoPrecioVenta?.code === 'TPVF' ? (
                            <>
                              {' '}
                              {`${formatPrice(
                                row.precio_fijo_venta || 0,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </>
                          ) : (
                            <>
                              {' '}
                              {`${formatPrice(
                                row.precio_objetivo_calculado_venta || 0,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </>
                          )}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.unidad_moneda_venta
                            ? `/ ${row.unidad_moneda_venta}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div className="text-gray-700 fw-bolder">
                        {row.aplica_impuesto_precio_venta ? (
                          <>
                            {row.impuesto_siglas && row.impuesto_siglas}

                            {row.impuesto_porcentaje &&
                              ` ${row.impuesto_porcentaje * 100} %`}
                          </>
                        ) : (
                          <>
                            <div className="badge badge-light">
                              Sin impuesto
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.monto_impuesto_precio_venta) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.unidad_moneda_venta
                            ? `/ ${row.unidad_moneda_venta}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <div>
                        <span className="text-gray-700 fw-bolder">
                          {`${formatPrice(
                            Number(row.precio_venta) || 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </span>
                        <span className="text-gray-600 ms-3 fs-8">
                          {row.unidad_moneda_venta
                            ? `/ ${row.unidad_moneda_venta}`
                            : 'Sin unidad de moneda'}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <>
                      <div
                        className={`btn btn-sm btn-active-light-primary btn-icon ${
                          row.activo ? 'btn-light-success ' : 'btn-light-danger'
                        }`}
                        onClick={(event) => {
                          let titulo = '';
                          let html = '';
                          let nuevoEstatus = 0;

                          if (row.activo) {
                            titulo = '¿Deseas desactivar el servicio?';
                            html = `
                                <div className="text-gray-600">
                                  Se desactivará el servicio "<b>${row.nombre}</b>", una vez
                                  desactivado ya no se podrá utilizar en futuros paquetes y cotizaciones.
                                </div>
                                `;
                            nuevoEstatus = 0;
                          } else {
                            titulo = '¿Deseas activar el servicio?';
                            html = `
                                <div className="text-gray-600">
                                  Se activará el servicio "<b>${row.nombre}</b>", una vez
                                  activado se podrá utilizar paquetes y cotizaciones.
                                </div>
                                `;
                            nuevoEstatus = 1;
                          }

                          Swal.fire({
                            title: titulo,
                            html: html,
                            showCancelButton: true,
                            padding: '2em',
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Aceptar',
                            reverseButtons: true,
                            customClass: {
                              confirmButton: 'btn btn-primary',
                              cancelButton: 'btn btn-light me-3',
                            },
                          }).then((result) => {
                            if (result.isConfirmed) {
                              cambiarEstatusServicio(
                                Number(row.id),
                                Number(nuevoEstatus)
                              );
                            }
                          });
                        }}
                        title="Activar/Desactivar servicio"
                      >
                        <KTSVG path="/media/icons/duotune/general/gen019.svg" />
                      </div>
                    </>
                    <>
                      {cargandoEliminacion === row.id ? (
                        <>
                          <div className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2">
                            <span
                              className="indicator-progress"
                              style={{ display: 'block' }}
                            >
                              <span className="spinner-border spinner-border-sm align-middle"></span>
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="btn btn-sm btn-active-light-primary btn-icon btn-light-primary ms-2"
                            onClick={(event) => {
                              eliminarServicio(Number(row.id), row.nombre);
                            }}
                            title="Eliminar servicio"
                          >
                            <KTSVG path="/media/icons/duotune/general/gen027.svg" />
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CreateSupplierModal
        show={showModalSupplier}
        typeProveedor={2}
        handleClose={(refresh?: boolean) => {
          setShowModalSupplier(!showModalSupplier);
          if (refresh) {
            onRefreshDataSupplier();
            onRefreshData();
          }
        }}
        itemSelected={itemSelected}
      ></CreateSupplierModal>
    </>
  );
};

export { ServiceList };
