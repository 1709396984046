import React, { useState, useEffect } from 'react';
import { Pagination } from '../../../../_metronic/helpers';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import { ContactoModel } from '../../contacto/models/ContactoModel';
import { KTSVG } from '../../../../_metronic/helpers';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { sendEmail } from '../../../service/email/Email';
import { DetailModal } from '../../plantillas/_modals/DetailModal';
import { TableList } from '../../../../_metronic/helpers/components/TableList';
import { EmptyResult } from '../../../../_metronic/helpers/components/EmptyResult';
type Props = {
  data: any;
  template: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  loadingGrid: boolean;
  onCleanFilter: () => void;
};

const ListGrid: React.FC<Props> = ({
  data,
  template,
  count,
  currentPage,
  totalPages,
  onPageChange,
  loadingGrid,
  onCleanFilter,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [rowId, setRowId] = useState<number>(0);
  const allItemsSelected =
    data.length > 0 && selectedItems.length === data.length;

  // const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [visivilityModalDetail, setVisivilityModalDetail] = useState(false);

  const [platillasList, setPlantillasList] = useState<
    { value: any; label: string }[] | undefined
  >();
  const Contacto: Array<ContactoModel> = data;

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // Si seleccionas todos, agregar todos los elementos a selectedItems
      setSelectedItems(Contacto.map((row: any) => row.id));
    } else {
      // Si deseleccionas todos, limpiar selectedItems
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (itemId: any) => {
    const updatedSelectedItems = [...selectedItems];
    if (updatedSelectedItems.includes(itemId)) {
      // Si ya está seleccionado, deseleccionarlo
      const index = updatedSelectedItems.indexOf(itemId);
      updatedSelectedItems.splice(index, 1);
    } else {
      // Si no está seleccionado, seleccionarlo
      updatedSelectedItems.push(itemId);
    }
    setSelectedItems(updatedSelectedItems);
  };

  const obtenerDatosSeleccionados = () => {
    setTimeout(() => {
      setLoading(true);
      const correosSeleccionados = Contacto.filter((row) =>
        selectedItems.includes(row.id)
      ).map((row) => row.email);

      if (correosSeleccionados.length && selectedOption) {
        sendEmail(correosSeleccionados, selectedOption)
          .then((res) => {
            const {
              data: { message },
            } = res;
            toast.success(message, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            setSelectAll(false);
            setSelectedItems([]);
            setSelectedOption(null);
            setLoading(false);
            setRowId(0);
            onCleanFilter();
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            setLoading(false);
            toast.error(resMessageToast, {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          });
      } else {
        if (!Contacto.length) {
          toast.error(
            'No es posible realizar esta acción ya que no existe información de contactos',
            {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            }
          );
          setLoading(false);

          return false;
        }

        if (!selectedOption && correosSeleccionados.length) {
          toast.error('Debes seleccionar una plantilla', {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setLoading(false);
          return false;
        }
        if (selectedOption && !correosSeleccionados.length) {
          toast.error('Debes seleccionar al menos un contacto', {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          setLoading(false);
          return false;
        }

        if (!selectedOption && !correosSeleccionados.length) {
          toast.error(
            'Debes seleccionar al menos un contacto y una plantilla',
            {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            }
          );
          setLoading(false);
          return false;
        }
      }
    }, 1000);
  };

  useEffect(() => {
    if (template) {
      const selectOptions = template.map((option: any) => ({
        value: option?.id,
        label: `${option.nombre} `,
      }));
      setPlantillasList(selectOptions);
    }
  }, [template]);

  const handleSelect = (selectedOption: any) => {
    const { value } = selectedOption;
    setRowId(value);
    setSelectedOption(selectedOption);
  };

  const columns: any = [
    {
      id: 'cuenta',
      name: <div className="fw-bolder text-muted me-5">Cuenta</div>,
      selector: (row: any) => row.cuenta?.nombre || 'No registrado',
      cell: (row: any) => (
        <div className="fw-bold large-text text-primary">
          {row.cuenta ? row.cuenta?.nombre : 'No registrado'}
        </div>
      ),
      sortable: true,
    },
    {
      id: 'subcuenta',
      name: <div className="fw-bolder text-muted me-5">Subcuenta</div>,
      selector: (row: any) => (row.subCuenta ? row.subCuenta?.nombre : 'S/D'),
      cell: (row: any) => (
        <div className="ext-muted arge-text">
          {row.subCuenta ? row.subCuenta?.nombre : 'S/D'}
        </div>
      ),
      sortable: true,
    },
    {
      id: 'departamento',
      name: <div className="fw-bolder text-muted me-5">Departamento</div>,
      selector: (row: any) =>
        row.departamento ? row.departamento?.name : 'No registrado',
      cell: (row: any) => (
        <div className="text-muted">
          {row.departamento ? row.departamento?.name : 'No registrado'}
        </div>
      ),
      sortable: true,
    },

    {
      id: 'puesto',
      name: <div className="fw-bolder text-muted me-5">Puesto</div>,
      selector: (row: any) => (row.puesto ? row.puesto : 'No registrado'),
      cell: (row: any) => (
        <div className="text-muted large-text">
          {row.puesto ? row.puesto : 'No registrado'}
        </div>
      ),
      sortable: true,
    },
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.nombre,
      cell: (row: any) => (
        <div className="text-muted large-text">{row.nombre}</div>
      ),
      sortable: true,
    },
    {
      id: 'paterno',
      name: <div className="fw-bolder text-muted me-5">Apellido Paterno</div>,
      selector: (row: any) => row.apellido_paterno,
      cell: (row: any) => (
        <div className="text-muted large-text">{row.apellido_paterno}</div>
      ),
      sortable: true,
    },
    {
      id: 'materno',
      name: <div className="fw-bolder text-muted me-5">Apellido Materno</div>,
      selector: (row: any) => row.apellido_materno,
      cell: (row: any) => (
        <div className="text-muted large-text">{row.apellido_materno}</div>
      ),
      sortable: true,
    },
    {
      name: (
        <input
          className="form-check-input"
          name=""
          type="checkbox"
          id="select-all"
          checked={allItemsSelected}
          onChange={handleSelectAll}
          // id={`item-${row.id}`}
          // checked={selectedItems.includes(row.id)}
          // onChange={() => handleSelectItem(row.id)}
        />
      ),
      cell: (row: any) => checkboxes(row),
      sortable: false,
    },
  ];

  const btnCheckboxes = (row: any) => {
    return (
      <>
        <input
          className="form-check-input"
          name=""
          type="checkbox"
          id={`item-${row.id}`}
          checked={selectedItems.includes(row.id)}
          onChange={() => handleSelectItem(row.id)}
        />
      </>
    );
  };
  const checkboxes = (row: any) => {
    return (
      <>
        <input
          className="form-check-input"
          name=""
          type="checkbox"
          id={`item-${row.id}`}
          checked={selectedItems.includes(row.id)}
          onChange={() => handleSelectItem(row.id)}
        />
      </>
    );
  };

  return (
    <>
      <div className="card-body py-3 mt-5">
        <div className="row">
          <div className="col-md-4">
            <label className="form-label fw-bold">Plantilla </label>

            <div>
              <Select
                placeholder={'Seleccione'}
                options={platillasList}
                value={selectedOption}
                onChange={handleSelect}
                noOptionsMessage={() => {
                  return <span>No hay plantillas</span>;
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="center-data-btn">
              <div>
                <a
                  href="#/"
                  className={'btn btn-sm btn-light-primary'}
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-flip="top-end"
                  onClick={() => {
                    if (selectedOption) {
                      setVisivilityModalDetail(true);
                    } else {
                      toast.error('Debes seleccionar una plantilla', {
                        position: 'top-right',
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                      });
                    }
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen045.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Ver plantilla
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="center-data">
              <div>
                {/* <button
                  type="button"
                  className="btn btn-sm btn-primary mt-3"
                  data-kt-menu-dismiss="true"
                  onClick={obtenerDatosSeleccionados}
                >
                  Enviar
                </button> */}
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  // data-kt-users-modal-action="submit"
                  onClick={obtenerDatosSeleccionados}
                  disabled={loading}
                >
                  {!loading && (
                    <span className="indicator-label">Enviar correo</span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
            {/* <button onClick={obtenerDatosSeleccionados}>Enviar</button> */}
          </div>
        </div>
        {/* <div className="table-responsive min-h-00px">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-5">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="w-150px">Cuenta</th>
                <th className="w-150px">Subcuenta</th>
                <th className="w-150px">Departamento</th>
                <th className="w-150px">Puesto</th>
                <th className="w-200px">Nombre</th>
                <th className="w-200px">Apellido Paterno</th>
                <th className="w-200px">Apellido Materno</th>
                <th className="text-center">
                  {Contacto.length ? (
                    <input
                      className="form-check-input"
                      name=""
                      type="checkbox"
                      id="all"
                      title="Seleccionar todos los contactos"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  ) : null}
                </th>
              </tr>
            </thead>
            <tbody>
              {loadingGrid ? (
                <tr>
                  <td colSpan={6}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : 
              count <= 0 ? (
                <tr>
                  <td colSpan={6}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              ) : Contacto.length ? (
                Contacto.map((row: any) => (
                  <tr key={row.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.cuenta?.nombre}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.subCuenta ? row.subCuenta?.nombre : 'S/D'}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.departamento?.name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {row.cargo}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {`${row.nombre} `}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {`${row.apellido_paterno} `}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex text-center">
                        <div className="d-flex justify-content-start flex-column fs-6">
                          {`${row.apellido_materno} `}
                        </div>
                      </div>
                    </td>

                    <td>
                      <input
                        className="form-check-input"
                        name=""
                        type="checkbox"
                        id={`item-${row.id}`}
                        checked={selectedItems.includes(row.id)}
                        onChange={() => handleSelectItem(row.id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className="d-flex align-items-center">
                      <div className="text-muted text-center fs-6 w-100 m-5">
                        Sin datos
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          ></Pagination>
        </div> */}
        <div className={`row gx-5 gx-xl-10`}>
          <div className="col-xl-12">
            <div className={`card mb-0 mb-xxl-8 h-100`}>
              <TableList data={Contacto} columns={columns}></TableList>

              {/* {!loadingGrid ? (
                <TableList data={Contacto} columns={columns}></TableList>
              ) : (
                <EmptyResult title="" loading={loadingGrid} />
              )} */}
            </div>
          </div>
        </div>
      </div>
      <DetailModal
        id_row={rowId}
        title={'Vista previa'}
        show={visivilityModalDetail}
        handleClose={(updateGrid: boolean) => {
          setVisivilityModalDetail(false);
        }}
      ></DetailModal>
    </>
  );
};

export { ListGrid };
