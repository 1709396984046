// vamos a copiar la estructura del formulario de otro componente y lo adecuamos a lo que ocupemos

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useGetCollaborator, useGetTypeCollabSelect, useSelectCountry } from "../hooks/CollaboratorHook";
import { useSelectGeneric, useSelectGenericPlus } from "../../catalogos/generico/hooks/genericoHook";
import { PatternFormat } from "react-number-format";
import { styleContactSelect, styleSelect } from "../../../../_metronic/helpers/SelectStyles";
import { CreateContactModal } from "./_modals/CreateContactModal";
import { useHistory } from "react-router-dom";
import { addUpdCollaborator } from "../../../service/collaborator/Collaborator";
import { useGetContactosCollab } from "../../contacto/hooks/ContactoHook";
import { KTSVG } from "../../../../_metronic/helpers";
import { Collapse, Form } from 'react-bootstrap-v5';
import { JobsList } from "./sections/JobsList";
import { useGetOneCompany } from "../../settings_erp/hooks/HumanResourcesHook";

// por ahora ya creamos la estructurta, ahora vamos a crear la ruta para poder ver el formulario

// por ahora ya creamos la estructura del formulario ahora vamos a adapatarlo a nuestras necesidades

type Props = {
  id_row: number;
  onRefreshData: () => void;
  section: string; 
};

const CollaboratorForm: React.FC<Props> = ({
  id_row,
  onRefreshData,
  section,
}) => {
  const [loading, setLoading] = useState(false);
  const [openSection, setOpenSection] = useState<boolean>(false);
  const [showContactError, setShowContactError] = useState<boolean>(true);
  const [notShowCurpField, setNotShowCurpField] = useState<boolean>(false);
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [selectProfileAdding, setSelectProfileAdding] =
    useState<boolean>(false);
  const [showModalContact, setShowModalContact] = useState<boolean>(false);
  const [jobsList, setJobsList] = useState<any[]>([]);
  const { catalog: genres, loadingRequest: loadingGen } = useSelectGenericPlus("GEN");
  const { countries, loadingRequest: loadingCountry } = useSelectCountry()
  const { initValues, loadingRequest } = useGetCollaborator(id_row);
  const history = useHistory();
  const [cuentaId, setCuentaId] = useState<number>(0);
  const [subcuentaId, setSubcuentaId] = useState<number>(0);
  const [reloadContact, setReloadContact] = useState<number>(
    Math.random() * 40
  );
  const [reloadCollabType, setReloadCollabType] = useState<number>(
    Math.random() * 40
  );
  const [reloadCompany, setReloadCompany] = useState<number>(
    Math.random() * 40
  );
  const { data: company } = useGetOneCompany(reloadCompany);
  const [contactValue, setcontactValue] = useState<any | null>(null);
  const [generateEmployeeNumber, setGenerateEmployeeNumber] = useState<boolean>(false);
  const [tipoCollabValue, setTipoCollabValue] = useState<any | null>(null);
  const [profileValue, setProfileValue] = useState<any | null>(null);
  const [genderValue, setGenderValue] = useState<any | null>(null);
  const [countryValue, setCountryValue] = useState<any | null>(null);
  const [statusValues, setStatusValues] = useState<any | null>(null);


  const location_get = 1;
  const statusCodes = [
    'IN_PROCESS',
    'IN_TRAMIT',
    'ACTIVE'
  ];


  const { contDataSelect, dataContactos, loadingRequest: loadingContact } = useGetContactosCollab(
    reloadContact,
    cuentaId,
    subcuentaId,
    location_get
  );

  const { collabTypeDataSelect, loadingRequest: loadingTypeCollab} = useGetTypeCollabSelect('TCOLLAB', reloadCollabType);
  const [previewImage, setPreviewImage] = useState<string>(
    process.env.PUBLIC_URL + '/media/avatars/photo.png'
  );


  const handleSelectContact = (selectedOption: any) => {

    if (selectedOption) {
      const { value } = selectedOption;

      if (value === "new") {
        setShowModalContact(true);
        setcontactValue(null);
        formCollaborator.setFieldValue("contacto_id", 0);
      }else{

      formCollaborator.setFieldValue("contacto_id", value);
      
      setShowContactError(false)
      
      setcontactValue({value, label: selectedOption?.name});
      }
    } else{
      formCollaborator.setFieldValue("contacto_id", null);
      setcontactValue(null)
    }
  };

  const changeTypeCollab = (selectedOption: any) => {
    setTipoCollabValue(selectedOption)
    
    if (selectedOption) {
      const { value } = selectedOption;
    
      formCollaborator.setFieldValue('tipo_id', value)
    } else {
      formCollaborator.setFieldValue('tipo_id', null);
    }
  };

  const changeGender = (selectedOption: any) => {
    setGenderValue(selectedOption)

    if (selectedOption) {
      const { value } = selectedOption;

      formCollaborator.setFieldValue('genero_id', value);
    } else {
      formCollaborator.setFieldValue('genero_id', null);
    }
  };

  const changeCountry = (selectedOption: any) => {
    setCountryValue(selectedOption)
    if (selectedOption) {
      const { value } = selectedOption;

      formCollaborator.setFieldValue('pais_id', value);
    } else {
      formCollaborator.setFieldValue('pais_id', null);
    }
  };

  const handleJobListEvent = (data: any) => {
    // Manejar los datos recibidos del componente hijo
    if(data?.length > 0){
    const {perfil} = data[data?.length-1]
    
    if(perfil){
    setProfileValue({
      value: perfil?.id,
      label: perfil?.name
    })
  }
  }
    formCollaborator.setFieldValue('empleos', data);
  };


  // en esta variable vamos a inicializar los datos que espera el formulario de colaboradores este sera el body del formulario

  const ColaboradorSchema = Yup.object().shape({
    contacto_id: Yup
    .number()
    .required('Este campo es requerido'),

    numero_empleado: Yup.string()
    .notRequired()
    /* .test(
      "isRequired",
      "Campo requerido.",
      function (value: any){
        
        return (!generateEmployeeNumber && !value) ? false : true;
      }
    ) */,
    curp: Yup.string()
    .nullable(true)
    .notRequired()
    .test(
      "isEmpty",
      "Campo requerido.",
      function (value: any){

        if(notShowCurpField){
          return true
        }else{
          return value ? true : false
        }
      }
    )
    .test(
      "isMinorLength",
      "El CURP debe tener 18 caracteres",
      function (value: any){
        if(notShowCurpField){
          return true
        }else{
          return value?.length < 18 ? false : true
        }
      }
    ),
    email: Yup.string()
    .nullable(true)
    .notRequired()
    .email("Formato de correo electrónico inválido."),

    celular: Yup.string().trim()
    .nullable(true)
    .notRequired()
    .max(13, 'El número de celular no puede exceder los 10 dígitos'),
    telefono: Yup.string().trim()
    .nullable(true)
    .notRequired()
    .max(13, 'El número de teléfono no puede exceder los 10 dígitos'),
    rfc: Yup.string()
    .nullable()
    .notRequired()
    .test(
      "isMinorLength",
      "El RFC debe tener 13 caracteres",
      function (value: any){
        
          return value?.length < 13 ? false : true
        
      }
    ),
   fecha_alta : Yup.date()
   .required('Campo requerido')
   .test('is-future-date', 'La fecha de alta no puede ser anterior a la fecha actual.', 
    function(value: any) {
    const today = new Date(new Date().toISOString().split('T')[0]);
    
    if(value){
      return  value.getTime() >= today.getTime();
    }
    return value
    // Comparar si la fecha es mayor o igual a hoy
}),
   nss: Yup.string()
   .nullable()
   .notRequired()
   .test(
     "isMinorLength",
     "El No. de Seguridad Social debe tener 11 caracteres",
     function (value: any){
       
         return value?.length < 11 ? false : true
       
     }
   ),
  });

  //EFFECTS
  useEffect(() => {
    if (Number(id_row) > 0 && initValues) {

      const { empleos, contacto, tipo, perfil, url, status, company} = initValues;
      const { genero, pais } = contacto;
      setJobsList(empleos);

      formCollaborator.setFieldValue('id', id_row)
      setGenerateEmployeeNumber(company?.generar_num_empleado);

      setPreviewImage(url)

      if (contacto?.id) {

        setcontactValue({
          value: contacto.id,
          label: contacto.nombre
        });

        if(genero?.id){

          setGenderValue({
            value: genero.id,
            label: genero.name
          })

        }

        if(pais?.id){
          setCountryValue({
            value: pais.id,
            label: pais.nombre
          })

        }
        
      }

      if(tipo?.id){
        setTipoCollabValue({
          value: tipo.id,
          label: tipo.name
        })

      }

      if (perfil?.id) {
        setProfileValue({
          value: perfil.id,
          label: perfil.name
        });

        formCollaborator.setFieldValue(
          "perfil_id",
          perfil.id
        );
      }

      formCollaborator.setFieldValue(
        "nss",
        initValues.nss
      );

      formCollaborator.setFieldValue(
        "fecha_alta",
        initValues.fecha_alta
      )

      formCollaborator.setFieldValue(
        "telefono",
        initValues.telefono
      )

      formCollaborator.setFieldValue(
        "celular",
        initValues.celular
      )

      formCollaborator.setFieldValue(
        "email",
        initValues.email
      )


      formCollaborator.setFieldValue(
        "numero_empleado",
        initValues.numero_empleado
      )

      let colorStatus : string = '';

      switch(status?.code){

        case statusCodes[0]:
        colorStatus= 'danger'
          break;

        case statusCodes[1]:
        colorStatus = 'info'
        break;

        case statusCodes[2]:
        colorStatus = 'success';
        break;
        
      }

      setStatusValues({
        label: status?.name,
        color: colorStatus
      })

    }
  
  }, [initValues]);


   useEffect(() => {

    if(contactValue && dataContactos){

      setShowContactError(false)

      const { value } = contactValue;
      const contactSelected = dataContactos.find(contact => contact.id === value);
      
      formCollaborator.setFieldValue('contacto_id', value);
      formCollaborator.setFieldValue("apellido_paterno",contactSelected?.apellido_paterno)
      formCollaborator.setFieldValue("apellido_materno", contactSelected?.apellido_materno)
      formCollaborator.setFieldValue('curp', contactSelected?.curp ?? '');
      setNotShowCurpField(contactSelected?.curp ? true : false)
      formCollaborator.setFieldValue('fecha_nacimiento', contactSelected?.fecha_nacimiento)
      formCollaborator.setFieldValue('genero_id', contactSelected?.genero?.id);
      formCollaborator.setFieldValue('pais_id', contactSelected?.pais?.id);

    }
    
  }, [contactValue, dataContactos]); 

  useEffect(()=>{

    if(tipoCollabValue){
    const { value } = tipoCollabValue;
  
    formCollaborator.setFieldValue('tipo_id', value)
    }

  }, [tipoCollabValue])

  useEffect(()=>{
    if(company){
      setGenerateEmployeeNumber(company?.generar_num_empleado)
    }
  }, [company])


  //   inicializamos formulario con sus atributos
  const formCollaborator = useFormik({
    initialValues: initValues,
    validationSchema: ColaboradorSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        const { contacto_id, nss, genero_id, curp, pais_id, tipo_id, perfil_id, 
          fecha_nacimiento} = values
        
        values.contacto= {
          id: contacto_id,
          nss,
          genero: {
            id: genero_id
          },
          curp,
          pais:{
            id: pais_id
          },
          fecha_nacimiento
        }
         values.perfil= {
          id: perfil_id
        } 

        values.tipo = {
          id: tipo_id
        }
        
        addUpdCollaborator(values)
          .then((res) => {
            const {
              data: { message },
            } = res;
            history.goBack();
            toast.success(message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            formCollaborator.resetForm();
            setLoading(false);
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            setLoading(false);
            setSubmitting(false);
            toast.error(resMessageToast, {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          });
      }, 1000);
    },
  });

  const handleTelephoneNumberChange = (values: any) => {
    formCollaborator.setFieldValue("telefono", values.target.value);
  };

  const handlePhoneNumberChange = (values: any) => {

        formCollaborator.setFieldValue("celular", values.target.value);
    
  };

  return (
    <>
      {!loadingRequest && (
        <>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formCollaborator.handleSubmit}
            noValidate
          >
            <div className="card mb-6 mb-xl-9">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                  <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                  <div
                    className="image-input image-input-outline me-10"
                    data-kt-image-input="true"
                  >
                    <label htmlFor="imageUpload">
                      <img
                        src={previewImage ? previewImage : ''}
                        className={`image-input-wrapper w-175px h-175px cursor-pointer `}
                        crossOrigin="anonymous"
                        alt="Colaborador"
                      />
                    </label>
                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Cambiar imagen"
                    >
                      <i className="bi bi-pencil-fill fs-7"></i>
                      <input
                        type="file"
                        id="imageUpload"
                        name="img"
                        accept=".png, .jpg, .jpeg"
                        onChange={(event) => {
                          if (event.currentTarget.files) {
                            let file = event.currentTarget.files[0];
                            let reader = new FileReader();
                            reader.onload = () => {
                              const csv: string = reader.result as string;
                              setPreviewImage(csv);
                            };
                            reader.readAsDataURL(file);
                            formCollaborator.setFieldValue('imagen', file);
                          }
                        }}
                      />
                    </label>
                  </div>
                  <div className="flex-grow-1">
                  <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9 p-0">
                  
                  <div className="w-100">
                              <Select
                                options={contDataSelect}
                                styles={styleContactSelect}
                                placeholder={"Seleccionar contacto /nuevo *"}
                                isSearchable={true}
                                noOptionsMessage={() => {
                                  return <span>{"No se encontraron registros"}</span>;
                                }}
                                onChange={(event) => handleSelectContact(event)}
                                onBlur={formCollaborator.handleBlur}
                                value={contactValue}
                                isLoading={loadingContact}
                                backspaceRemovesValue
                              />
                             {formCollaborator.touched.contacto &&
                        formCollaborator.errors.contacto_id && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">
                              {formCollaborator.errors.contacto_id}
                            </span>
                          </div>
                        )}
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 p-0">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Apellido Paterno
                        </label>
                      </div>
                      <input
                        className="form-control"
                        data-kt-element="input"
                        disabled={true}
                        placeholder="Apellido paterno"
                        maxLength={15}
                        {...formCollaborator.getFieldProps('apellido_paterno')}
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 p-0">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Apellido Materno
                        </label>
                      </div>
                      <input
                        className="form-control"
                        disabled={true}
                        data-kt-element="input"
                        placeholder="Apellido materno"
                        maxLength={15}
                        {...formCollaborator.getFieldProps('apellido_materno')}
                      ></input>
                    </div>
                  </div>
 
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 p-0">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                        <label
                          className={`fw-bold fs-6 mb-2 w-auto ${/* !generateEmployeeNumber ? 'required' : */ ''}`}>N&uacute;mero de empleado
                        </label>
                      </div>
                      <input
                        className="form-control "
                        data-kt-element="input"
                        disabled={generateEmployeeNumber}
                        placeholder="Número de empleado"
                        maxLength={20}
                        {...formCollaborator.getFieldProps("numero_empleado")}
                        onChange={(event) => {
                          const value = event.target.value.replace(/[^A-Za-z0-9]/g, '');
                          formCollaborator.setFieldValue('numero_empleado', value);
                        }}
                      ></input>
                       {formCollaborator.touched.numero_empleado &&
                        formCollaborator.errors.numero_empleado && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">
                              {formCollaborator.errors.numero_empleado}
                            </span>
                          </div>
                        )} 
                    </div>
                  </div>
                  </div>
                      </div>
                      </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Tipo de colaborador
                        </label>
                      </div>
                      <Select
                        value={tipoCollabValue}
                        options={collabTypeDataSelect}
                        styles={styleSelect}
                        placeholder={'Seleccione'}
                        isSearchable={true}
                        isLoading={loadingTypeCollab}
                        noOptionsMessage={() => {
                          return <span>{'No se encontraron registros'}</span>;
                        }}
                        onChange={changeTypeCollab}
                        isClearable
                        backspaceRemovesValue
                        onBlur={formCollaborator.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          G&eacute;nero
                        </label>
                      </div>
                      <Select
                        value={genderValue}
                        options={genres}
                        styles={styleSelect}
                        placeholder={'Seleccione'}
                        isSearchable={true}
                        noOptionsMessage={() => {
                          return <span>{'No se encontraron registros'}</span>;
                        }}
                        onChange={changeGender}
                        isLoading={loadingGen}
                        isClearable
                        backspaceRemovesValue
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className={`${notShowCurpField ? '' : 'required'} fw-bold fs-6 mb-2 w-auto`}>
                          CURP
                        </label>
                      </div>
                      <input
                        className="form-control"
                        data-kt-element="input"
                        placeholder="CURP"
                        style={{ textTransform: 'uppercase' }}
                        maxLength={18}
                        disabled={notShowCurpField}
                        {...formCollaborator.getFieldProps('curp')}
                        onChange={(event) => {
                          const value = event.target.value.replace(/[^A-Za-z0-9]/g, '');
                          formCollaborator.setFieldValue('curp', value);
                        }}
                      ></input>
                      {formCollaborator.touched.contacto &&
                        formCollaborator.errors.curp && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">
                              {formCollaborator.errors.curp}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Correo institucional
                        </label>
                      </div>
                      <input
                        {...formCollaborator.getFieldProps("email")}
                        className="form-control"
                        placeholder="Correo institucional"
                        type="email"
                        name="email"
                        maxLength={30}
                      />
                      {formCollaborator.touched.email && formCollaborator.errors.email && (
                        <div className="fv-plugins-message-container text-danger w-100">
                          <span role="alert">{formCollaborator.errors.email}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Celular
                        </label>
                      </div>
                      <PatternFormat
                        {...formCollaborator.getFieldProps("celular")}
                        className="form-control"
                        format="## #### #### ##### ####"
                        name="celular"
                        placeholder="Celular"
                        onChange={handlePhoneNumberChange}
                      />
                      {formCollaborator.touched.celular && formCollaborator.errors.celular && (
                        <div className="fv-plugins-message-container text-danger w-100">
                          <span role="alert">{formCollaborator.errors.celular}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Teléfono
                        </label>
                      </div>
                      <PatternFormat
                        {...formCollaborator.getFieldProps("telefono")}
                        className="form-control"
                        format="## #### #### ##### ####"
                        name="telefono"
                        placeholder="Télefono"
                        onChange={handleTelephoneNumberChange}
                      />
                      {formCollaborator.touched.telefono &&
                        formCollaborator.errors.telefono && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">
                              {formCollaborator.errors.telefono}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Fecha de nacimiento
                        </label>
                      </div>
                      <input
                        type="date"
                        className={`form-control`}
                        id="fecha_nacimiento"
                        name="fecha_nacimiento"
                        value={
                          formCollaborator.values.fecha_nacimiento
                            ? new Date(formCollaborator.values.fecha_nacimiento)
                              .toISOString()
                              .split('T')[0]
                            : ''
                        }
                        max={new Date()
                          .toISOString()
                          .split('T')[0]}
                        onChange={formCollaborator.handleChange}
                        onBlur={formCollaborator.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Nacionalidad
                        </label>
                      </div>
                      <Select
                        value={countryValue}
                        options={countries}
                        styles={styleSelect}
                        placeholder={'Seleccione'}
                        isSearchable={true}
                        noOptionsMessage={() => {
                          return <span>{'No se encontraron registros'}</span>;
                        }}
                        onChange={changeCountry}
                        isLoading={loadingCountry}
                        isClearable
                        backspaceRemovesValue
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          RFC
                        </label>
                      </div>
                      <input
                        className="form-control "
                        data-kt-element="input"
                        placeholder="RFC"
                        style={{ textTransform: 'uppercase' }}
                        maxLength={13}
                        {...formCollaborator.getFieldProps('rfc')}
                        onChange={(event) => {
                          const value = event.target.value.replace(/[^A-Za-z0-9]/g, '');
                          formCollaborator.setFieldValue('rfc', value);
                        }}
                      ></input>
                      {formCollaborator.touched.rfc &&
                        formCollaborator.errors.rfc && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">
                              {formCollaborator.errors.rfc}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  { id_row > 0 && (
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className=" form-label w-auto">Estado</label>
                      </div>
                      <div className={`mt-2 badge badge-light-${statusValues.color}`}>
                        {statusValues.label}
                      </div>
                    </div>
                  </div>
                  )}
                      </div>
                    </div>
                  </div> 
                  
              
            <div className="card mb-6 mb-xl-9">
            <div className="card-body">
              <div
                className="card-header cursor-pointer"
                onClick={() => {
                  setOpenSection(!openSection);
                }}
                aria-controls="collapseJobs"
                aria-expanded={openSection}
              >
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">
                    Empleos</h3>
                </div>
                <div className="d-flex align-items-center">
                    <div className={formCollaborator.touched.fecha_alta &&
                      formCollaborator.errors.fecha_alta ? 'form-control is-invalid' : ''}>
                      <div
                    className={`align-self-center`}
                    style={{
                      transform: openSection
                        ? 'translateX(10%) rotate(-180deg)'
                        : '',
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    
                    <KTSVG
                      path={'/media/icons/duotune/arrows/arr072.svg'}
                      className={`svg-icon-1`}
                    />
                    </div>
                  </div>
                </div>
              </div>

              <Collapse in={openSection}>
                <div id="collapseJobs">
                  <div className="card-body border-top p-5">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Fecha de alta
                            </label>
                          </div>
                          <div className="mt-3">
                            <div className="w-100">
                              <input
                                type="date"
                                className={`form-control ${
                                  formCollaborator.touched.fecha_alta &&
                                  formCollaborator.errors.fecha_alta
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="fecha_alta"
                                name="fecha_alta"
                                value={
                                  formCollaborator.values.fecha_alta
                                    ? new Date(formCollaborator.values.fecha_alta)
                                      .toISOString()
                                      .split('T')[0]
                                    : ''
                                }
                                min={new Date()
                                  .toISOString()
                                  .split('T')[0]}
                                onChange={formCollaborator.handleChange}
                                onBlur={formCollaborator.handleBlur}
                              />
                              {formCollaborator.touched.fecha_alta &&
                                formCollaborator.errors.fecha_alta && (
                                  <div className="invalid-feedback">
                                    {formCollaborator.errors.fecha_alta}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              No. Seguridad Social 
                            </label>
                          </div>
                          <div className="mt-3">
                            <div className="mt-2 w-100">
                            <input
                        className="form-control"
                        data-kt-element="input"
                        placeholder="No. Seguridad Social"
                        style={{ textTransform: 'uppercase' }}
                        maxLength={11}
                        {...formCollaborator.getFieldProps('nss')}
                        onChange={(event) => {
                          const value = event.target.value.replace(/[^0-9]/g, '');
                          formCollaborator.setFieldValue('nss', value);
                        }}
                      ></input>
                      {formCollaborator.touched.nss &&
                        formCollaborator.errors.nss && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">
                              {formCollaborator.errors.nss}
                            </span>
                          </div>
                        )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3 col-xl-4 col-xxl-4">
                      <div className="w-100">
                      <div className="w-100 d-flex">
                      <Form.Control type="text" placeholder={profileValue?.label ?? `Sin Perfil`} disabled={true} className="me-2 large-text"
                      style={{border: 'none', 
                      height: 60, 
                      fontWeight: 'bold',
                      color: '#FF66C4',
                      marginTop: 20, 
                      textAlign: 'center',
                      backgroundColor: '#FDBBFC'
                       }} 
                       {...formCollaborator.getFieldProps('perfil_id')}/>
                       <style>
        {`
          input[name="perfil_id"]::placeholder {
            color: #ff66c4;
            opacity: 1;
          }
        `}
      </style>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div className="row">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <JobsList
                              data={jobsList}
                              collabId={id_row}
                              formik={formCollaborator}
                              onUpdateData={(data: any[]) => {
                                if (data) {
                                  handleJobListEvent(data)
                                }
                              }
                              }
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
            </div>

            <div className="card mb-6 mb-xl-9">
              <div className="card-body">
                <div className="row">
                  <div className="d-flex justify-content-end">
                    <button
                      type="reset"
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancelar
                      
                    </button>
                    
                    <button
                      type="submit"
                      className="btn btn-primary me-3"
                      data-kt-users-modal-action="submit"
                      disabled={loading}
                    >
                      {!loading && (
                        <span className="indicator-label">
                          {id_row > 0 ? "Actualizar" : "Guardar"}
                        </span>
                      )}
                      {loading && (
                        <span className="indicator-progress" style={{ display: "block" }}>
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    { id_row > 0 && (
                      <button
                        type="reset"
                        className="btn btn-primary"
                        disabled={true}
                        data-kt-users-modal-action="cancel"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                         {!loading && (
                        <span className="indicator-label">
                          Alta
                        </span>
                      )}
                      {loading && (
                        <span className="indicator-progress" style={{ display: "block" }}>
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                      </button>
                    )
                    }
                  </div>
                </div>
              </div>
            </div>
          </form>
          <CreateContactModal
        show={showModalContact}
        handleClose={(isNew: boolean, newContact: any) => {
          if (isNew && newContact) {
            setReloadContact(Math.random() * 50);
            const { value, label } = newContact;
           
            setcontactValue({
              value: value,
              label: label,
            });

          }
          
          setShowModalContact(!showModalContact);
        }}
      ></CreateContactModal>
        </>
      )}
    </>
  );
};
export { CollaboratorForm };
