import axios from 'axios';
import { AccountModel } from '../../pages/account/models/AccountModel';
import { NoteModel } from '../../pages/notes/models/NoteModel';
import { SubAccountModel } from '../../pages/subaccount/models/SubAccountModel';
import { AddressModel } from '../../../_metronic/helpers/models/AddressModel';
const API_URL = process.env.REACT_APP_API;

export async function getAccountAll() {
  const response = await axios.post(`${API_URL}catalogos/cuentas?sort=nombre`, {
    field_id: null,
    field_value: null,
  });
  const data = response.data.doc.data.rows;
  const count = response.data.doc.data.count;
  return {
    data: data,
    count: count,
  };
}
export async function getAccount(
  startIndex: number,
  endIndex: number,
  filters: any
) {
  let searchData = {
    field_id: null,
    field_value: null,
  };
  if (filters) {
    searchData = {
      field_id: filters.campo_id,
      field_value: filters.valor,
    };
  }
  const response = await axios.post(
    `${API_URL}catalogos/cuentas?sort=nombre`,
    searchData
  );
  // const response = await axios.post(
  //   `${API_URL}catalogos/cuentas?sort=nombre&limit=${startIndex}&page=${endIndex}`,
  //   searchData
  // );
  const data = response.data.doc.data.rows;
  const count = response.data.doc.data.count;
  return {
    data: data,
    count: count,
  };
}

export async function getAccountById(id: number) {
  try {
    const response = await axios.get(`${API_URL}catalogos/cuentas/${id}`);
    let data = response.data.doc.data;
    let accountData = {
      ...data,
      tipo_id: data.tipoCuenta ? data.tipoCuenta.id : 0,
      vendedor_comercial_id: data.vendedorComercial
        ? data.vendedorComercial.id
        : 0,
      industria_id: data.industria ? data.industria.id : 0,
      tamano_id: data.tamano ? data.tamano.id : 0,
      referido_id: data.referido ? data.referido.id : 0,
    };

    return accountData;
  } catch (error) {
    console.log('ERROR: ', error);
  }
}

export async function addCuenta(body: any) {
  const {
    nombre,
    telefono,
    calle,
    numero_interior,
    numero_exterior,
    municipio,
    estado,
    codigo_postal,
    pais,
    sitio_web,
    industriaId,
    tipocuentaId,
    colonia,
    referenciaId,
    motivo,
    vendedor_comercial_id,
    regimen_id
  } = body;

  const data = {
    nombre: nombre,
    // telefono: telefono === '52' || telefono === '(+52)' || telefono === '(+ )' || telefono === '(+  )' ? '' : telefono,
    telefono: telefono ? telefono : null,
    calle: calle,
    numero_exterior: numero_exterior,
    numero_interior: numero_interior,
    municipio: municipio,
    estado: estado,
    codigo_postal: codigo_postal,
    pais: pais,
    sitio_web: sitio_web || "",
    industria_id: industriaId,
    tipo_id: tipocuentaId,
    colonia: colonia,
    referencia_id: referenciaId === '' ? null : referenciaId,
    referencia_comentario: motivo,
    vendedor_comercial_id: vendedor_comercial_id,
    regimen_id: regimen_id
  };

  const response = await axios.post(`${API_URL}catalogos/cuentas/add`, data);
  return response;
}

export async function updateCuenta(body: any) {
  const {
    id,
    nombre,
    telefono,
    calle,
    numero_interior,
    numero_exterior,
    municipio,
    estado,
    codigo_postal,
    pais,
    sitio_web,
    industriaId,
    tipocuentaId,
    colonia,
    referenciaId,
    motivo,
    vendedor_comercial_id,
  } = body;

  const data = {
    nombre: nombre,
    telefono:
      telefono === '52' ||
      telefono === '(+52)' ||
      telefono === '(+ )' ||
      telefono === '(+  )'
        ? ''
        : telefono,
    calle: calle,
    numero_exterior: numero_exterior,
    numero_interior: numero_interior,
    municipio: municipio,
    estado: estado,
    codigo_postal: codigo_postal,
    pais: pais,
    sitio_web: sitio_web,
    industria_id: industriaId,
    tipo_id: tipocuentaId,
    colonia: colonia,
    referencia_id: referenciaId === '' ? null : referenciaId,
    referencia_comentario: referenciaId ? motivo : '',
    vendedor_comercial_id: vendedor_comercial_id,
  };

  const response = await axios.put(`${API_URL}catalogos/cuentas/${id}`, data);
  return response;
}

export async function getEstados() {
  const response = await axios.get(`${API_URL}catalogos/estados`);
  const data = response.data.doc.data.rows;
  const count = response.data.doc.data.count;
  return {
    data: data,
    count: count,
  };
}
export async function getMunicipios(estadoId: number) {
  const response = await axios.get(
    `${API_URL}catalogos/municipios/estado/${estadoId}`
  );
  const data = response.data.doc.data;
  const count = response.data.doc.data.count;
  return {
    data: data,
    count: count,
  };
}
export async function getCountries() {
  const response = await axios.get(`${API_URL}catalogos/pais`);
  const data = response.data.doc.data?.rows;
  return {
    data: data,
  };
}
export async function getInformationByCode(code: number) {
  const response = await axios.get(`${API_URL}codigosPostales/${code}`);
  const data = response.data.doc.data;
  return {
    data: data,
  };
}

export async function removeCuenta(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/cuentas/${id}`);
  return response;
}

export async function getHistoryAccount(search: string, id: string) {
  const response = await axios.get(
    `${API_URL}catalogos/cuentas/historial/${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function createHistoryMessage(message: string, id: string) {
  const response = await axios.post(
    `${API_URL}oportunidad/historial/comentario`,
    {
      oportunidadId: id,
      comentario: message,
    }
  );
  return response;
}

export async function getFilterFieldsByCode(code: string) {
  const response = await axios.get(`${API_URL}catalogs/campos/${code}`);
  const data = response.data.doc.data;
  return data;
}
export async function getDataFilterBySelectFiledAccount(code: string) {
  let response;
  let data;
  switch (code) {
    case 'CNTA_NOM':
      response = await axios.post(`${API_URL}catalogos/cuentas`, {
        field_id: null,
        field_value: null,
      });
      data = response.data.doc.data.rows;
      break;
    case 'CNTA_TYPE_ID':
      response = await axios.get(`${API_URL}catalogs/TCA?sort=name`);
      data = response.data.doc.data.rows;
      break;
    case 'CNTA_VC_ID':
      response = await axios.get(
        `${API_URL}catalogos/vendedores/list/comerciales`
      );
      data = response.data.doc.data.rows;
      break;
    case 'CNTA_IND_ID':
      response = await axios.get(`${API_URL}catalogs/IND?sort=name`);
      data = response.data.doc.data.rows;
      break;
    default:
      break;
  }

  return data;
}

export async function insertAccount(account: AccountModel) {
  const dataInsert = {
    nombre: account.nombre,
    tipo_id: Number(account.tipo_id),
    vendedor_comercial_id: Number(account.vendedor_comercial_id),
    telefono: account.telefono ? account.telefono : '',
    industria_id:
      Number(account.industria_id) > 0 ? Number(account.industria_id) : null,
    sitio_web: account.sitio_web ? account.sitio_web : '',
    tamano_id: Number(account.tamano_id) > 0 ? Number(account.tamano_id) : null,
    referido_id:
      Number(account.referido_id) > 0 ? Number(account.referido_id) : null,
    proveedor_id: account.proveedor_id ? account.proveedor_id : '',
    contacto_princ_id:
      Number(account.contactoPrincipal_id) > 0
        ? Number(account.contactoPrincipal_id)
        : null,
    regimen_id:
      Number(account.regimen_id) > 0 ? Number(account.regimen_id) : null,
    num_empleados: account.num_empleados ? Number(account.num_empleados) : '',
  };
  const response = await axios.post(
    `${API_URL}catalogos/cuentas/add/`,
    dataInsert
  );
  return response.data;;
}

export async function updateAccount(account: AccountModel, accountId: number) {

  const body = {
    nombre: account.nombre,
    tipo_id: Number(account.tipo_id),
    vendedor_comercial_id: Number(account.vendedor_comercial_id),
    telefono: account.telefono ? account.telefono : '',
    industria_id:
      Number(account.industria_id) > 0 ? Number(account.industria_id) : null,
    sitio_web: account.sitio_web ? account.sitio_web : '',
    tamano_id: Number(account.tamano_id) > 0 ? Number(account.tamano_id) : null,
    referido_id:
      Number(account.referido_id) > 0 ? Number(account.referido_id) : null,
    proveedor_id: account.proveedor_id ? account.proveedor_id : '',
    num_empleados: account.num_empleados ? Number(account.num_empleados) : '',
    contacto_princ_id:
      Number(account.contactoPrincipal_id) > 0
        ? Number(account.contactoPrincipal_id)
        : null,
    regimen_id:
      Number(account.regimen_id) > 0 ? Number(account.regimen_id) : null,
  }
  const response = await axios.put(`${API_URL}catalogos/cuentas/${accountId}`, body);
  return response.data;
}
export async function createHistoryMessageAccount(message: string, id: string) {
  const response = await axios.post(
    `${API_URL}catalogos/cuentas/historial/comentario`,
    {
      accId: id,
      comentario: message,
    }
  );
  return response;
}
export async function getNotesByAccountId(id: string) {
  const response = await axios.get(
    `${API_URL}catalogos/cuentas/notas/acc/${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function insertNoteAccount(note: NoteModel, accountId: number) {
  const response = await axios.post(`${API_URL}catalogos/cuentas/notas/add`, {
    nombre: note.nombre,
    descripcion: note.descripcion,
    cuenta_id: Number(accountId),
  });
  return response;
}

export async function getSubByAccountId(accountId: string) {
  const response = await axios.get(
    `${API_URL}catalogos/subcuentas/cuenta/${accountId}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function insertSubAccount(SubName: string, id: string) {
  const response = await axios.post(`${API_URL}catalogos/subcuentas/add`, {
    nombre: SubName,
    cuenta_id: id,
  });
  return response;
}

export async function getSubaccountById(id: number) {
  try {
    const response = await axios.get(`${API_URL}catalogos/subcuentas/${id}`);
    let data = response.data.doc.data;
    let accountData = {
      ...data,
      sitio_web: data.sitio_web || "",
      cuenta_id: data.cuenta ? data.cuenta.id : 0,
      industria_id: data.industria ? data.industria.id : 0,
    };
    return accountData;
  } catch (error) {
    console.log('ERROR: ', error);
  }
}

export async function updateSubaccount(
  subaccount: SubAccountModel,
    subaccountId: number,
    accountId: string
) {
  const response = await axios.put(
    `${API_URL}catalogos/subcuentas/${subaccountId}`,
    {
      nombre: subaccount.nombre,
      telefono: subaccount.telefono ? subaccount.telefono : null,
      industria_id:
        Number(subaccount.industria_id) > 0
          ? Number(subaccount.industria_id)
          : null,
      sitio_web: subaccount.sitio_web ? subaccount.sitio_web : null,
      tamano: Number(subaccount.tamano) > 0 ? Number(subaccount.tamano) : null,
      nombre_referido: subaccount.nombre_referido
        ? subaccount.nombre_referido
        : null,
        proveedor_id: subaccount.proveedor_id ? subaccount.proveedor_id : null,
        cuenta_id: accountId
    }
  );
  return response;
}

export async function getHistorySubaccount(search: string, id: string) {
  const response = await axios.get(
    `${API_URL}catalogos/subcuentas/historial/${id}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function createHistoryMessageSubaccount(
  message: string,
  id: string
) {
  const response = await axios.post(
    `${API_URL}catalogos/subcuentas/historial/comentario`,
    {
      sbAccId: id,
      comentario: message,
    }
  );
  return response;
}

export async function getAddressByAccountId(id: string) {
  const response = await axios.get(
    `${API_URL}catalogos/cuentas/direcciones/acc/${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function insertAddress(
  address: AddressModel,
  subaccountId: string
) {
  const response = await axios.post(
    `${API_URL}catalogos/cuentas/direcciones/`,
    {
      ...address,
      cuenta_id: subaccountId,
    }
  );
  return response;
}

export async function updateAddress(
  address: AddressModel,
  subaccountId: string
) {
  const response = await axios.put(
    `${API_URL}catalogos/cuentas/direcciones/${address.id}`,
    {
      ...address,
      cuenta_id: subaccountId,
    }
  );
  return response;
}

export async function getDocumentsByAccountId(accountId: number) {
  const response = await axios.get(
    `${API_URL}catalogos/cuentas/documento/${accountId}`
  );
  const data = response.data.doc.data;
  return data;
}

export const uploadFileToAccount = async (file: any, account: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('accId', account);

  const response = await axios.post(
    `${API_URL}catalogos/cuentas/documento`,
    formData
  );
  return response;
};
export const removeFileOfAccount = async (account: number) => {
  const response = await axios.delete(
    `${API_URL}catalogos/cuentas/documento/${account}`
  );
  return response;
};
export async function getContactByAccountId(id: string) {
  const response = await axios.get(`${API_URL}catalogos/contactos/acc/${id}`);
  const data = response.data.doc.data;
  return data;
}
