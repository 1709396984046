import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  useGetCountries,
  useGetInformationByCode,
} from "../../../app/pages/account/hooks/AccountHook";
import { ColoniaSelectModel, PostalCodeModel } from "../models/AddressModel";
import { KTSVG } from "./KTSVG";
import { Modal } from "react-bootstrap-v5";
import * as Yup from "yup";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
import { styleSelect } from "../SelectStyles";
import { EIDRM } from "constants";
import { SimpleAlert } from '../../../_metronic/helpers';

type Props = {
  show: boolean;
  isEditable: boolean;
  dataEditable: any;
  handleSubmit: (values: any) => void;
  handleClose: () => void;
};


const AddressModal: React.FC<Props> = ({
  show,
  handleClose,
  isEditable,
  dataEditable,
  handleSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(dataEditable);
  const [showBtn, setShowBtn] = useState<boolean>(false);
  const [showSelectColonias, setShowSelectColonias] = useState<boolean>(false);
  // SELECT DATA
  const [colonias, setColonias] = useState<ColoniaSelectModel[]>([]);
  const [country, setCountry] = useState<any | null | "">("");
  // VALUE SELECT
  const [colonia, setColonia] = useState<any | null | "">("");
  // VALUE INPUT
  const [inputColonia, setInputColonia] = useState<string>("");
  const [postalCode, setPostalCode] = useState<number>(0);
  const [disabledInput, setDisabledInput] = useState<boolean>(false);
  const { dataLocation, loadingPostalCodeFetch } =
    useGetInformationByCode(postalCode)
  const [zipCodeChange, setZipCodeChange] = useState(false)
  const { dataCountry } = useGetCountries();

  const AddressSchema = Yup.object().shape({
    nombre: Yup.string().required("Este campo es requerido."),
    calle: Yup.string().required("Este campo es requerido."),
    numero_exterior: Yup.number()
      .min(1, "Este campo es requerido.")
      .required("Este campo es requerido.")
      .typeError("Debe ingresar un número."),
    codigo_postal: Yup.number()
      .min(1, "Este campo es requerido.")
      .required("Este campo es requerido.")
      .typeError("Debe ingresar un número."),
  });

  const formAddress = useFormik({
    initialValues: initialValues,
    validationSchema: AddressSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
        setTimeout(() => {
        handleSubmit(values);
        setLoading(false)
      }, 1000);
     
  }
  });

  const handleSelect = (selectedOption: any) => {
    const { value } = selectedOption;    
    setCountry(selectedOption);
    formAddress.setFieldValue("pais", value);
  };

  const handleSelectColonia = (selectedOption: any) => {
    const { value } = selectedOption;
    if (value != "Otra") {
      setColonia(selectedOption);
      formAddress.setFieldValue("otro", false)
      formAddress.setFieldValue("colonia", value);
    } else {
      setShowBtn(true);
      setColonia(null);
      setShowSelectColonias(false);
      formAddress.setFieldValue("otro", true)
      formAddress.setFieldValue("colonia", null);
      setInputColonia("");
    }
  };

  const handleCleanFormAtt = () => {
    formAddress.setFieldValue("colonia", "");
    formAddress.setFieldValue("estado", "");
    formAddress.setFieldValue("ciudad", "");
    /* formAddress.setFieldValue("calle", "");
    formAddress.setFieldValue("numero_exterior", "");
    formAddress.setFieldValue("numero_interior", ""); */
  };

  const handleCleanData = () => {
    setDisabledInput(false);
    setShowBtn(false);
    setShowSelectColonias(false);
    setColonia("");
    setInputColonia("")
    setColonias([]);
    setPostalCode(0);
    setInputColonia("");
    setCountry("");
  };

  useEffect(() => {    
    if (isEditable) {
      const { id, nombre,codigo_postal, pais, calle,numero_exterior,numero_interior,colonia } = dataEditable;             
      setInitialValues(dataEditable)   
      formAddress.setFieldValue("id", id);
      formAddress.setFieldValue("nombre", nombre);
      formAddress.setFieldValue("calle", calle);
      formAddress.setFieldValue("numero_exterior", numero_exterior);
      formAddress.setFieldValue("numero_interior", numero_interior);
      formAddress.setFieldValue("codigo_postal", codigo_postal);              
      formAddress.setFieldValue("pais", pais);
      setPostalCode(parseInt(codigo_postal));
      setInputColonia(colonia)
      formAddress.setFieldValue("colonia", colonia)
      setCountry({
        value: pais.trim(),
        label: pais.trim(),
      });
      setColonia({
        value: colonia,
        label: colonia,
      });   
    } else {
      formAddress.setFieldValue("id", 0);
      formAddress.setFieldValue("nombre", '');
      formAddress.setFieldValue("calle", '');
      formAddress.setFieldValue("numero_exterior", '');
      formAddress.setFieldValue("numero_interior", '');
      formAddress.setFieldValue("codigo_postal", '');
      setPostalCode(0);
      setCountry('');
    }
  }, [show]);

  useEffect(() => {
    if (dataLocation) {
      setZipCodeChange(false)
      const {
        codigo_postal: { estado, municipio, colonias },
      } = dataLocation;

      formAddress.setFieldValue("estado", estado);
      formAddress.setFieldValue("ciudad", municipio);
      setDisabledInput(true);

      if (colonias.length) {
        colonias.push("Otra");
        const selectOptions = colonias.map((option: any) => ({
          value: option,
          label: option,
        }));
        setColonias(selectOptions);

        if (isEditable) {          
          const validate_colonia = colonias.some((item: any) => {
            return item === initialValues.colonia;
          });
          if (validate_colonia) {
            // setColonias(selectOptions);
            setColonia({
              value: initialValues.colonia,
              label: initialValues.colonia,
            });
            formAddress.setFieldValue("colonia", initialValues.colonia);
            setShowSelectColonias(true);
          } else {

            if(initialValues.otro){
              setShowSelectColonias(false);
              setInputColonia(initialValues.colonia)
              formAddress.setFieldValue("colonia", initialValues.colonia);
              setShowBtn(true);
            } else {
              setInputColonia("");
              setColonia(null)
              formAddress.setFieldValue("colonia", "");
              setShowSelectColonias(true);
              setShowBtn(true);
            }
          }
        } else {
          setShowSelectColonias(true);
          setColonias(selectOptions);
        }
      }
    } else {
      if(zipCodeChange && !loadingPostalCodeFetch){
        showLocationWarning()
        setZipCodeChange(false)
      }
      
      setInputColonia("");
      formAddress.setFieldValue('colonia',"")
      setColonias([]);
      setShowSelectColonias(false)
      setShowBtn(false)
      setDisabledInput(false);
      handleCleanFormAtt();
    }
  }, [dataLocation]);

  useEffect(() => {
    if(zipCodeChange && !loadingPostalCodeFetch && !dataLocation){
      showLocationWarning()
        setInputColonia("");
      setColonia(null)
      setZipCodeChange(false)
      setColonias([]);
      setShowSelectColonias(false);
      setShowBtn(false)
      setDisabledInput(false);
      handleCleanFormAtt();
    }
  }, [loadingPostalCodeFetch])

  useEffect(() => {
    if (!show) {
      formAddress.resetForm();
      setInitialValues({});
      setDisabledInput(false);
      setPostalCode(0)
      setColonia(null)
      setShowSelectColonias(false);
    }
  }, [show]);

  const showLocationWarning = () => {
    if(!dataLocation && !loadingPostalCodeFetch){
      SimpleAlert('No existe información para ese Código Postal', 3000, 'warning')
    }
  }

 
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog mw-800px"
      show={show}
      onHide={() => {
        formAddress.resetForm();
        handleClose();
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="text-muted">{"Dirección"}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formAddress.resetForm();
              handleClose();
              handleCleanData();
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body mx-5 padding-top-00">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formAddress.handleSubmit}
          >
            <div className="d-flex flex-column flex-xl-row flex-row-fluid mt-5">
              <div className="w-100 d-flex">
                <div className="w-100 pe-5">
                  <div>
                    {/* NOMBRE */}
                    <div className="row g-9 mb-7">
                      <div className="col-md-12 fv-row fv-plugins-icon-container">
                        <input
                          className="form-control mb-2 w-100 border-0"
                          style={{
                            width: "100%",
                            padding: "15px",
                            fontSize: "24px",
                            boxSizing: "border-box",
                            marginBottom: "20px",
                          }}
                          data-kt-element="input"
                          placeholder="Nombre *"
                          {...formAddress.getFieldProps("nombre")}
                        ></input>
                        {formAddress.touched.nombre &&
                          formAddress.errors.nombre && (
                            <div className="fv-plugins-message-container text-danger w-100">
                              <span role="alert">
                                {formAddress.errors.nombre}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row g-9 mb-7">
                      {/* PAIS */}
                      <div className="col-md-4 fv-row fv-plugins-icon-container">
                        <label className="fs-6 fw-bold mb-2">País</label>
                        <Select
                          options={dataCountry}
                          styles={styleSelect}
                          placeholder={"Seleccione"}
                          isSearchable={true}
                          noOptionsMessage={() => {
                            return <span>{"No se encontraron registros"}</span>;
                          }}
                          onChange={handleSelect}
                          value={country}
                        />
                      </div>
                      {/* CALLE */}
                      <div className="col-md-4 fv-row fv-plugins-icon-container">
                        <label className="fs-6 fw-bold mb-2 required">
                          Calle
                        </label>
                        <input
                          {...formAddress.getFieldProps("calle")}
                          className="form-control"
                          name="calle"
                        />
                        {formAddress.touched.calle && formAddress.errors.calle && (
                          <div className="fv-plugins-message-container text-danger w-100">
                            <span role="alert">{formAddress.errors.calle}</span>
                          </div>
                        )}
                      </div>
                      {/* NO. EXTERIOR */}
                      <div className="col-md-4 fv-row fv-plugins-icon-container">
                        <label className="fs-6 fw-bold mb-2 required">
                          Número exterior
                        </label>
                        <NumericFormat
                          className="form-control"
                          {...formAddress.getFieldProps("numero_exterior")}
                          onBlur={(event) => {}}
                        />
                        {formAddress.touched.numero_exterior &&
                          formAddress.errors.numero_exterior && (
                            <div className="fv-plugins-message-container text-danger w-100">
                              <span role="alert">
                                {formAddress.errors.numero_exterior}
                              </span>
                            </div>
                          )}
                      </div>
                      {/* NO. INTERIOR */}
                      <div className="col-md-4 fv-row fv-plugins-icon-container">
                        <label className=" fs-6 fw-bold mb-2">
                          Número interior
                        </label>
                        <NumericFormat
                          className="form-control"
                          {...formAddress.getFieldProps("numero_interior")}
                          onBlur={(event) => {}}
                        />
                        {formAddress.touched.numero_interior &&
                          formAddress.errors.numero_interior && (
                            <div className="fv-plugins-message-container text-danger w-100">
                              <span role="alert">
                                {formAddress.errors.numero_interior}
                              </span>
                            </div>
                          )}
                      </div>
                      {/*CODIGO POSTAL*/}
                      <div className="col-md-4 fv-row fv-plugins-icon-container">
                        <label className=" fs-6 fw-bold mb-2 required">
                          Código Postal
                        </label>
                        <NumericFormat
                          className="form-control"
                          {...formAddress.getFieldProps("codigo_postal")}
                          onBlur={(event) => {
                            if (event.target.value) {
                                setPostalCode(parseInt(event.target.value));
                                formAddress.setFieldValue('colonia', "")
                              setShowSelectColonias(true)
                              setZipCodeChange(true)
                            } else {
                              setPostalCode(0);
                              setColonia(null);
                              setInputColonia("");
                              setDisabledInput(false);
                              setShowSelectColonias(false);
                              formAddress.setFieldValue("estado", "");
                              formAddress.setFieldValue("ciudad", "");
                            }
                          }}
                          disabled={loadingPostalCodeFetch}
                          // value={postalCode}
                        />
                        {formAddress.touched.codigo_postal &&
                          formAddress.errors.codigo_postal && (
                            <div className="fv-plugins-message-container text-danger w-100">
                              <span role="alert">
                                {formAddress.errors.codigo_postal}
                              </span>
                            </div>
                          )}
                      </div>
                      {/*COLONIA*/}
                      <div className="col-md-4 fv-row fv-plugins-icon-container">
                        <div className="w-100 d-flex">
                          <label className="fw-bold fs-6 mb-2 w-auto">
                            Colonia
                          </label>
                        </div>
                        {showSelectColonias ? (
                          <Select
                            options={colonias}
                            styles={styleSelect}
                            placeholder={"Seleccione"}
                            isLoading={loadingPostalCodeFetch}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={handleSelectColonia}
                            value={colonia}
                            isDisabled= {loadingPostalCodeFetch}
                          />
                        ) : (
                          <>
                            <input
                              {...formAddress.getFieldProps("colonia")}
                              className="form-control"
                              value={inputColonia}
                              name="colonia"
                              onChange={(event) => {
                                if (event.target.value) {
                                  setInputColonia(event.target.value);
                                  formAddress.setFieldValue(
                                    "colonia",
                                    event.target.value
                                  );
                                } else {
                                  setInputColonia("");
                                  formAddress.setFieldValue("colonia", "");
                                }
                              }}
                            />

                            {showBtn ? (
                              <a
                                className="cursor-pointer btn-ligth-primary mt-2"
                                onClick={() => {
                                  setShowSelectColonias(true);
                                  formAddress.setFieldValue("colonia", "");
                                  setColonia(null)
                                }}
                              >
                                Ver listado
                              </a>
                            ) : null}
                          </>
                        )}
                      </div>
                      {/* CIUDAD */}
                      <div className="col-md-4 fv-row fv-plugins-icon-container">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Ciudad
                        </label>
                        <input
                          className="form-control"
                          data-kt-element="input"
                          {...formAddress.getFieldProps("ciudad")}
                          name="ciudad"
                          disabled={disabledInput}
                        ></input>
                      </div>
                      {/* ESTADO */}
                      <div className="col-md-4 fv-row fv-plugins-icon-container">
                        <label className="fs-6 fw-bold mb-2">Estado</label>
                        <input
                          className="form-control"
                          data-kt-element="input"
                          {...formAddress.getFieldProps("estado")}
                          name="estado"
                          disabled={disabledInput}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formAddress.resetForm();
                  handleClose();
                  handleCleanData();
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { AddressModal };
