/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

type Props = {
  handleFilter: (text: string) => void;
  handleFilterClean: () => void;
  valueFilter: string;
};

const DropdownFilter: React.FC<Props> = ({
  handleFilter,
  handleFilterClean,
  valueFilter,
}) => {
  const [nombre, setNombre] = useState(valueFilter);
  useEffect(() => {
    setNombre(valueFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFilter]);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
      data-kt-menu="true"
    >
      <div className="px-7 py-5">
        <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
      </div>

      <div className="separator border-gray-200"></div>

      <div className="px-7 py-5">
        <div className="mb-10">
          <label className="form-label fw-bold">Nombre:</label>
          <div>
            <input
              placeholder="Nombre"
              className="form-control mb-3 mb-lg-0"
              type="text"
              name="Nombre"
              value={nombre}
              onChange={(e) => {
                const regex = /^[a-zA-ZÀ-ÿ0-9\s]*$/;
                if (regex.test(e.target.value)) {
                  setNombre(e.target.value);
                }
              }}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            type="reset"
            className="btn btn-sm btn-white btn-active-light-primary me-2"
            data-kt-menu-dismiss="true"
            onClick={handleFilterClean}
          >
            Limpiar
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-primary"
            data-kt-menu-dismiss="true"
            onClick={() => {
              handleFilter(nombre);
            }}
          >
            Buscar
          </button>
        </div>
      </div>
    </div>
  );
};
export { DropdownFilter };
