import axios from "axios";
import { CollaboratorModel } from "../../pages/collaborators/models/CollaboratorModel";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API;
const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}colaborador/`;

const codesToFilter: string[] = ["COLA_NOM", "COLA_PERF", "COLA_DEPTO"];

export async function getCatFieldsCollaborators() {
  const response = await axios.get(`${API_URL}catalogs/campos/TBL_COLA`);
  const data = response.data.doc.data;
  const dataFilter = data.filter((obj: any) =>
    codesToFilter.includes(obj.code)
  );
  return dataFilter;
}

export async function getAllCollaborators(filters: any = null, startIndex: number, endIndex: number) {
  let campo_id = null;
  let valor = null;
  if (filters) {
    if (filters.campo_id) {
      campo_id = filters.campo_id;
    }
    if (filters.valor) {
      valor = filters.valor;
    }
  }
  const response = await axios.post(`${API_URL}colaboradores/all?limit=${startIndex}&page=${endIndex}`, {
    campo_id,
    valor,
  });
  const data = response.data.doc.data.rows;
  return {
    data: data,
  };
}

export async function addUpdCollaborator(body: CollaboratorModel) {
  let response;
  const { id, fecha_alta, empleos, contacto, imagen,
    numero_empleado, email, celular, telefono, rfc,
    tipo, perfil, nss
  } = body;

  const { genero, curp, pais, fecha_nacimiento } = contacto;

  const isNotEmptyFecIng = !!fecha_alta && fecha_alta.trim() !== '';
  const newFechaAlta = isNotEmptyFecIng ? fecha_alta: new Date()
  .toISOString()
  .split('T')[0];

  const formData = new FormData();
  
  formData.append('id', id?.toString());
  formData.append('fecha_alta', newFechaAlta);
  formData.append('imagen', imagen ?? '');
  formData.append('numero_empleado', numero_empleado)
  formData.append('contacto_id', (contacto.id).toString());
  formData.append('tipo_id', (tipo?.id)?.toString() || '')
  formData.append('genero_id', (genero?.id)?.toString() || '')
  formData.append('curp', curp)
  formData.append('fecha_nacimiento', fecha_nacimiento || '');
  formData.append('pais_id', (pais?.id)?.toString() || '');
  formData.append('email', email ?? '');
  formData.append('celular', celular?.trim() ?? '');
  formData.append('telefono', telefono?.trim() ?? '');
  formData.append('rfc', rfc ?? '');
  formData.append('perfil_id', (perfil?.id)?.toString() || '')
  formData.append('nss',nss ?? '');
  
  let formatData;
    let empleosFormatedData = [];

    for(let job of empleos){

      formatData = {
        id: job.key > 0 ? 0 : job.id,
        fecha_inicio: job.fecha_inicio,
        fecha_termino: job.fecha_termino === '' ? null : job.fecha_termino,
        puesto: {id: job.puesto?.id},
        departamento: {id: job.departamento?.id},
        tarifa_unidad_esfuerzo: job.tarifa_unidad_esfuerzo === '' ? null : job.tarifa_unidad_esfuerzo,
        perfil: {id: job.perfil?.id},
        actual: job.actual
      }

      empleosFormatedData.push(formatData) 
      
    }

   formData.append('empleos', JSON.stringify(empleosFormatedData))

  if (id) {
    response = await axios.put(`${API_URL}colaboradores/${id}`, formData);
  }else{
  response = await axios.post(`${API_URL}colaboradores/`, formData);
  }
  return response;
}

export async function getCollaboratorById(
  id: number
): Promise<CollaboratorModel | undefined> {
  try {
    const response = await axios.get(`${API_URL}colaboradores/${id}`);
    const data = response.data.doc.data;

    const { imagen } = data;
    const random = Math.random() * 40;
    data.url = data.imagen
      ? `${API_URL_IMAGE_PUBLIC}${imagen}?${random}`
      : toAbsoluteUrl("/media/avatars/photo.png");
    
    return data;
  } catch (error) {
    console.error("ERROR:", error);
    return undefined;
  }
}

export async function getDataFilterBySelectFiledCollaborator(code: string) {
  let response;
  let data;

  switch (code) {
    case codesToFilter[0]:
      response = await axios.post(`${API_URL}colaboradores/all`, {
        campo_id: null,
        valor: null,
      });
      data = response.data.doc.data.rows;
      break;
    case codesToFilter[1]:
      response = await axios.get(`${API_URL}catalogs/PASOC?sort=name`);
      data = response.data.doc.data.rows;
      break;
    case codesToFilter[2]:
      response = await axios.get(`${API_URL}catalogs/DEP?sort=name`);
      data = response.data.doc.data.rows;
      break;

    default:
      break;
  }
  return data;
}

export async function getHistory(search: string, id: string) {
  const response = await axios.get(
    `${API_URL}colaboradores/historial/${id}`
  );
  const data = response.data.doc.data;
  return data;
}
