import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getSuppliersList, getTaxes } from '../../../service/products/Products';
import {
  GenricCatalogsServiceModel,
  ServiceModel,
} from '../models/ServiceModel';
import { SimpleAlert } from '../../../../_metronic/helpers';
import { HistoryModel } from '../../../../_metronic/helpers/models/HistoryModel';
import { SelectFormat } from '../../../../_metronic/helpers/models/GenericModel';
import { getChangeType } from '../../../service/catalog/Catalogs';
import { NoteServiceModel } from '../models/NoteServiceModel';
import { ServiceDocModel } from '../models/ServiceDocModel';
import {
  addCategoryServices,
  addServiceType,
  addSupplierToService,
  addUpdateService,
  copyService,
  createHistoryMessageService,
  getActiveCatService,
  getCategoriesGrid,
  getDocumentsByServiceId,
  getHistoryServices,
  getNotesByServiceId,
  getServiceById,
  getSubCategoriesByCat,
  getSupplierByService,
  insertNoteService,
  removeService,
  updateSupplierToService,
} from '../../../service/services/Services';
import { SupplierServiceModel } from '../models/SupplierServiceModel';

type Option = {
  id: number | 0;
  value: number | 0;
  label: string | null | '';
};

export const useGetCatalogOfServicesCategories = (
  reloadCategoryList: number
) => {
  const [loadingCatServices, setLoadingCatServices] = useState<boolean>(true);
  const [selectData, setSelectData] = useState<Option[]>([]);
  const history = useHistory();

  useEffect(() => {
    const fetchGet = async () => {
      const response = await getCategoriesGrid()
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (response) {
        setLoadingCatServices(false);
        const selectOptions = response.rows.map(
          (option: GenricCatalogsServiceModel) => ({
            id: Number(option.id),
            value: option.id,
            label: `${option.nombre} `,
          })
        );

        selectOptions.unshift({
          id: 0,
          value: '0',
          label: `Agregar categoría`,
          data: {},
        });
        setSelectData(selectOptions);
      }
    };

    fetchGet();
  }, [reloadCategoryList]);

  return {
    loadingCatServices,
    selectData,
  };
};

export const useGetCatalogOfSubCategories = (
  categoryId: number,
  reloadSubcategoryList: number
) => {
  const [loadingSubCatProducts, setLoadingSubCatProducts] =
    useState<boolean>(true);
  const [selectData, setSelectData] = useState<Option[]>([]);
  const history = useHistory();

  useEffect(() => {
    const fetchGet = async () => {
      const response = await getSubCategoriesByCat(categoryId)
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (response) {
        setLoadingSubCatProducts(false);
        const selectOptions = response.rows.map(
          (option: GenricCatalogsServiceModel) => ({
            value: option.id,
            label: `${option.nombre} `,
          })
        );

        selectOptions.unshift({
          id: 0,
          value: '0',
          label: `Agregar subcategoría`,
          data: {},
        });
        setSelectData(selectOptions);
      }
    };

    fetchGet();
  }, [categoryId, reloadSubcategoryList]);

  return {
    loadingSubCatProducts,
    selectData,
  };
};

export const useGetCatTaxes = () => {
  const [loadingGetTaxes, setLoadingGetTaxes] = useState<boolean>(true);
  const [optionsSelectTaxes, setOptionsSelectTaxes] = useState<any[]>([]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getTaxes()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        let formatLabel: string;
        const selectOptions = res.map(
          (option: any) => (
            (formatLabel = `${option.siglas} ${option.porcentaje * 100}%`),
            {
              value: option.id,
              label: formatLabel,
              data: option,
            }
          )
        );
        setOptionsSelectTaxes(selectOptions);
        setLoadingGetTaxes(false);
      }
    };
    fetchPost();
  }, []);

  return { loadingGetTaxes, optionsSelectTaxes };
};

export const useCreateEditService = (isEdit: boolean) => {
  const [loadingService, setLoadingService] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);
  const [serviceId, setServiceId] = useState<number>(0);

  const history = useHistory();
  const createEditService = async (service: any) => {
    setLoadingService(false);
    setErrorRequest(false);
    try {
      const res = await addUpdateService(service);

      if (res) {
        if (!isEdit) {
          const {
            doc: {
              data: { id },
            },
          } = res;

          setServiceId(Number(id));
        }

        setLoadingService(true);
        setErrorRequest(false);
        SimpleAlert(res.message, 3000, 'success');
      }
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoadingService(true);
      } else {
        setErrorRequest(true);
        history.push('/error/500');
      }
    }
  };
  return { createEditService, loadingService, errorRequest, serviceId };
};

export const useGetOne = (id: number) => {
  const initModel: ServiceModel = {
    id: 0,
    nombre: '',
    descripcion: '',
    sku: '',
    imagen: '',
    precio_venta: '',
    precio_fijo_venta: '',
    categoria_id: 0,
    aplica_impuesto_precio_venta: false,
    monto_impuesto_precio_venta: '',
    porcentaje_beneficio_precio_venta: null,
    monto_beneficio_precio_venta: null,
    precio_objetivo_calculado_venta: null,
    precio_compra: null,
    porcentaje_conversion_compra: null,
    tipo_cambio_compra: null,
    precio_fijo_compra: null,
    precio_proveedor_compra: null,
    porcentaje_beneficio_precio_compra: null,
    monto_beneficio_precio_compra: null,
    unidadNegocio: null,
    categoria: {
      id: 0,
      nombre: '',
    },
    subcategoria: null,
    tipo: null,
    impuesto: {
      id: 0,
      siglas: '',
      porcentaje: '',
      descripcion: '',
    },
    tipoVenta: null,
    unidadMedidaVenta: {
      id: 0,
      code: '',
      name: '',
    },
    unidadMonedaVenta: {
      id: 0,
      code: '',
      name: '',
    },
    tipoPrecioVenta: {
      id: 0,
      code: '',
      name: '',
    },
    tipoCompra: null,
    unidadMedidaCompra: null,
    unidadMonedaCompra: null,
    tipoPrecioCompra: null,
    activo_asociado: null,
    perfil_asociado: null,
    proveedores: [],
    perfil_id: 0,
    activo_id: 0,
    tipo_precio_compra_id: null,
    tipo_compra_id: null,
    proveedor_id: null,
    unidad_medida_venta_id: 0,
    activo: 1,
  };

  const [initValues, setInitValues] = useState<any>(initModel);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      await getServiceById(id)
        .then((res) => {
          if (res) {
            setInitValues(res);
            setLoadingRequest(false);
          }
        })
        .catch((error) => {
          history.push('/error/500');
        });
    };
    if (id > 0) {
      fetchPost();
    } else {
      setInitValues(initModel);
      setLoadingRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { initValues, loadingRequest };
};

export const useGetHistory = (reaload: number, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistoryServices(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};

export const useInsertMessageService = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: number) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessageService(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useGetNoteByService = (id: number, reaload: number) => {
  const [notes, setNotes] = useState<NoteServiceModel[]>([]);
  const [loadingNote, setLoadingNote] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingNote(false);
    const fetchPost = async () => {
      try {
        const res = await getNotesByServiceId(id);
        if (res) {
          setNotes(res);
          setLoadingNote(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingNote(false);
      }
    };
    if (id) {
      fetchPost();
    }
  }, [id, reaload]);

  return { notes, loadingNote };
};

export const useGetDocumentsByServiceId = (id: number, reload: number) => {
  const [dataDocuments, setDataDocuments] = useState<ServiceDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByServiceId(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    if (id > 0) {
      fetchPost();
    }
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};

export const useCreateNoteService = () => {
  const [loadingCreateNote, setLoadingCreateNote] = useState<boolean>(false);
  const history = useHistory();
  const createNote = async (note: NoteServiceModel, id: number) => {
    setLoadingCreateNote(false);
    try {
      await insertNoteService(note, id);
      setLoadingCreateNote(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingCreateNote(false);
    }
  };
  return { createNote, loadingCreateNote };
};

export const useCreateServiceCopy = () => {
  const [loadingService, setLoadingService] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const createServiceCopy = async (id: any) => {
    setLoadingService(false);
    setErrorRequest(false);
    try {
      const res = await copyService(id);
      setLoadingService(true);
      setErrorRequest(false);
      SimpleAlert(res.message, 3000, 'success');
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoadingService(true);
      } else {
        setErrorRequest(true);
        history.push('/error/500');
      }
    }
  };
  return { createServiceCopy, loadingService, errorRequest };
};

export const useCreateCategoryServices = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const create = async (category: any) => {
    setLoading(false);
    setErrorRequest(false);
    try {
      const res = await addCategoryServices(category);
      if (res) {
        const { message } = res;
        SimpleAlert(message, 3000, 'success');
      }

      setLoading(true);
      setErrorRequest(false);
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push('/error/500');
      }
    }
  };
  return { create, loading, errorRequest };
};

export const useCreateServiceType = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const create = async (name: any) => {
    setLoading(false);
    setErrorRequest(false);
    try {
      const res = await addServiceType(name);
      if (res) {
        const { message } = res;
        SimpleAlert(message, 3000, 'success');
      }
      setLoading(true);
      setErrorRequest(false);
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(true);
      } else {
        setErrorRequest(true);
        history.push('/error/500');
      }
    }
  };
  return { create, loading, errorRequest };
};

export const useSelectSupplier = (reload: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getSuppliersList()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };

    fetchPost();
  }, [reload]);

  return { catalog, loadingRequest };
};

export const useGetSuppliersByService = (
  id: number,
  reaload: number,
  execute: boolean
) => {
  const [suppliers, setSuppliers] = useState<SupplierServiceModel[]>([]);
  const [loadingSupplier, setLoadingSupplier] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    setLoadingSupplier(false);
    const fetchPost = async () => {
      try {
        const res = await getSupplierByService(id);
        if (res) {
          setSuppliers(res);
          setLoadingSupplier(true);
        }
      } catch (error) {
        history.push('/error/500');
        setLoadingSupplier(false);
      }
    };
    if (id) {
      // if (id && !execute) {
      fetchPost();
    }
  }, [id, reaload]);

  return { suppliers, loadingSupplier };
};

export const useCreateEditSupplier = () => {
  const [loadingSupp, setLoadingSupp] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);

  const history = useHistory();
  const createSupplier = async (supp: any) => {
    setLoadingSupp(true);

    try {
      const res = await addSupplierToService(supp);

      if (res) {
        const {
          data: { message },
        } = res;
        setLoadingSupp(false);
        SimpleAlert(message, 3000, 'success');
      }
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoadingSupp(true);
      } else {
        setErrorRequest(true);
        history.push('/error/500');
      }
    }
  };
  return { createSupplier, loadingSupp, errorRequest };
};

export const useChangeTypeCurrency = (body: any) => {
  const [change, setChange] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoading(false);
      const res = await getChangeType(body)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setChange(res);
        setLoading(true);
      }
    };
    if (body) {
      fetchPost();
    }
  }, [body]);

  return { change, loading };
};

export const useUpdateSupplier = () => {
  const [loadingSupp, setLoadingSupp] = useState<boolean>(false);
  const [errorRequest, setErrorRequest] = useState<boolean>(false);
  const [reloadInfo, setReloadInfo] = useState<number>(0);

  const history = useHistory();
  const updateSupplier = async (body: any) => {
    setLoadingSupp(true);
    try {
      const res = await updateSupplierToService(body);
      if (res) {
        setLoadingSupp(false);
        setReloadInfo(Math.random() * 50);
        // SimpleAlert(res.message, 3000, "success");
      }
    } catch (error: any) {
      setErrorRequest(true);
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoadingSupp(true);
      } else {
        setErrorRequest(true);
        history.push('/error/500');
      }
    }
  };
  return { updateSupplier, loadingSupp, errorRequest, reloadInfo };
};

export const useRemoveService = () => {
  const [isDeletedCorrect, setIsDeletedCorrect] = useState<boolean>(false);

  const removeServices = async (service: number) => {
    try {
      await removeService(service)
        .then((res) => {
          setIsDeletedCorrect(true);
          SimpleAlert(res.data.message, 3000, 'success');
        })
        .catch((err) => {
          setIsDeletedCorrect(false);
          let resMessageToast: string = '';
          const {
            data: { message },
          } = err.response;
          resMessageToast = `${message}`;
          SimpleAlert(resMessageToast, 3000, 'error');
        });
    } catch (err: any) {
      setIsDeletedCorrect(false);
      SimpleAlert(err.message, 3000, 'error');
    }
  };

  return { removeServices, isDeletedCorrect };
};

export const useSelectServiceCatActives = () => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getActiveCatService()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };

    fetchPost();
  }, []);

  return { catalog, loadingRequest };
};
