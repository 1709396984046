import React, { useEffect, useState } from 'react';
import { encryptId, KTSVG } from '../../../../_metronic/helpers';
import { Collapse } from 'react-bootstrap-v5';
import { Tarjeta } from './Tarjeta';
import { ListadoTabla } from './ListadoTabla';
import { ListadoTarjeta } from './ListadoTarjeta';
import { Link } from 'react-router-dom';
import { useObtenerTareasPorEtapa } from '../hooks/BitacoraHook';
import { FiltroBitacora } from './FilterBoard';
import { HomeFilterBoard } from '../../oportunidades/models/OportunidadModel';

const View: React.FC = () => {
  const [actualizarTareas, setActualizarTareas] = useState<number>(
    Math.random() * 40
  );
  const [initFilterValues, setInitFilterValues] = useState<HomeFilterBoard>({
    campo_id: null,
    valor: null,
  });
  const { tareas, tareasPorEtapa, cargandoTareas } = useObtenerTareasPorEtapa(
    actualizarTareas,
    initFilterValues
  );

  const [seccionFiltros, setSeccionFiltros] = useState<boolean>(false);
  const [codigoTarjeta, setCodigoTarjeta] = useState<string>('COL');
  const [secction, setSecction] = useState<string | 'BOARD' | 'LIST'>('LIST');
  const toggleMode = (mode: 'LIST' | 'BOARD') => {
    localStorage.setItem('viewMode', mode);
    setSecction(mode);
  };
  const onCleanFilter = () => {
    setInitFilterValues({
      campo_id: null,
      valor: null,
    });
    setActualizarTareas(Math.random() * 40);
  };

  const onSearchFilter = (values: any) => {
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        campo_id: null,
        valor: null,
      });
    }
    setActualizarTareas(Math.random() * 40);
  };

  useEffect(() => {
    const storedMode = localStorage.getItem('viewMode');
    if (storedMode) {
      setSecction(storedMode);
    }
  }, []);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className="card mb-6 mb-xl-9">
            <div
              className="card-header cursor-pointer"
              onClick={() => {
                setSeccionFiltros(!seccionFiltros);
              }}
              aria-controls="collapseGeneral"
            >
              <div className="d-flex align-items-center">
                <KTSVG
                  path={'/media/icons/duotune/text/txt009.svg'}
                  className="svg-icon-2x d-block my-2"
                />
                <h3 className="fw-bolder m-0 ms-3 ">Mi bitácora</h3>
              </div>

              <div className="d-flex align-items-center">
                <div
                  className={`align-self-center`}
                  style={{
                    transform: seccionFiltros
                      ? 'translateX(10%) rotate(-180deg)'
                      : '',
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <KTSVG
                    path={'/media/icons/duotune/arrows/arr072.svg'}
                    className={`svg-icon-1`}
                  />
                </div>
              </div>
            </div>
            {/* btn-primary */}
            <Collapse in={seccionFiltros}>
              <div className="card-body border-top p-9">
                <div className="row g-6 g-xl-9 w-75">
                  <Tarjeta
                    nombre={'Colaboradores'}
                    claseTarjeta={codigoTarjeta === 'COL' ? 'btn-primary' : ''}
                    icono={'/media/icons/duotune/communication/com006.svg'}
                    onClickCard={() => {
                      setCodigoTarjeta('COL');
                    }}
                    onClickAdd={() => {
                      alert('Agregar colaborador');
                    }}
                  ></Tarjeta>
                  {/* <Tarjeta
                    nombre={'Proyectos'}
                    claseTarjeta={codigoTarjeta === 'PROY' ? 'btn-primary' : ''}
                    icono={'/media/icons/duotune/text/txt010.svg'}
                    onClickCard={() => {
                      setCodigoTarjeta('PROY');
                    }}
                    onClickAdd={() => {
                      alert('Agregar proyecto');
                    }}
                  ></Tarjeta>
                  <Tarjeta
                    nombre={'Venta Directa'}
                    claseTarjeta={
                      codigoTarjeta === 'VENTA' ? 'btn-primary' : ''
                    }
                    icono={'/media/icons/duotune/arrows/arr033.svg'}
                    onClickCard={() => {
                      setCodigoTarjeta('VENTA');
                    }}
                    onClickAdd={() => {
                      alert('Agregar venta directa');
                    }}
                  ></Tarjeta> */}
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      <div className="row gy-6 g-xl-9">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
          <div className="card card-flush p-5">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                  <li className="nav-item cursor-pointer ">
                    <div
                      className={`nav-link me-6 ${
                        secction === 'LIST' ? 'text-active-primary active' : ''
                      } `}
                      onClick={() => {
                        toggleMode('LIST');
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/text/txt001.svg"
                        className="svg-icon-2 fs-7"
                      />
                      <span className="margin-left-05">Lista</span>
                    </div>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <div
                      className={`nav-link me-6  ${
                        secction === 'BOARD' ? 'text-active-primary active' : ''
                      }`}
                      onClick={() => {
                        toggleMode('BOARD');
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/layouts/lay002.svg"
                        className="svg-icon-2 fs-7"
                      />
                      <span className="margin-left-05">Tablero</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4 position-end">
                <div className="card-title center-items">
                  <Link
                    className="btn btn-sm btn-icon btn-light-primary"
                    to={`/bitacora/tarea/add/${encryptId('0')}`}
                  >
                    <KTSVG
                      path="/media/icons/duotune/abstract/abs011.svg"
                      className="svg-icon-5 svg-icon-gray-500 "
                    />
                  </Link>
                  <div className="mr-05"></div>
                  <FiltroBitacora
                    onCleanFilter={onCleanFilter}
                    onSearchFilter={onSearchFilter}
                    initFilterValues={initFilterValues}
                  ></FiltroBitacora>
                </div>
              </div>
            </div>
            <div className="row">
              {secction === 'LIST' ? (
                <>
                  <ListadoTabla
                    tareas={tareas}
                    cargandoTareas={cargandoTareas}
                  ></ListadoTabla>
                </>
              ) : (
                <>
                  <ListadoTarjeta
                    etapasConTareas={tareasPorEtapa}
                    onRefreshData={() => {
                      setActualizarTareas(Math.random() * 40);
                    }}
                  ></ListadoTarjeta>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
