import { FC } from 'react';
import { PageTitle, PageLink } from '../../../../../_metronic/layout/core';
import { View } from './components/View';
import { useParams } from 'react-router-dom';
import { decryptId } from '../../../../../_metronic/helpers';

const ProyectosForm: FC = () => {
  const { id } = useParams<{ id: string }>();
  const proyectoId: number = Number(decryptId(id));
  let titulo: string = proyectoId ? `Editar proyecto` : `Nuevo proyecto`;
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Proyectos',
      path: '/proyectos/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{titulo}</PageTitle>
      <View proyectoId={proyectoId} />
    </>
  );
};

export { ProyectosForm };
