import { TareaModel, TaskDocModel } from '../models/TaskModel';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getAllTareas,
  getTareaById,
  getAllUsers,
  getHistory,
  createHistoryMessage,
} from '../../../service/tareas/Tareas';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { TaskModel } from '../models/TaskModel';
import {
  getTaskByID,
  updateTask,
  getUsersTasks,
  getDocumentsByTaskId,
  updatePauseTask,
  getUsersTasksBitacora,
} from '../../../service/tasks/Tasks';
import { HistoryModel } from '../../../../_metronic/helpers/models/HistoryModel';
import { SelectFormat } from '../../../../_metronic/helpers/models/GenericModel';
import { getMe } from '../../../service/users/Usuarios';

export const useGetTaskByID = (id: string, reload: number) => {
  const task: TaskModel = {
    id: 0,
    nombre: '',
    descripcion: '',
    fecha_inicio: new Date().toISOString().split('T')[0],
    fecha_fin: new Date().toISOString().split('T')[0],
    statusTarea: null,
    etapa: null,
    prioridad: null,
    oportunidad: null,
    user_asignado: null,
    user_creador: null,
    etapa_id: 0,
    prioridad_id: 0,
    status_tarea_id: 0,
    user_asignado_id: 0,
    documentos: null,
  };
  const [taskInit, setTaskInit] = useState<TaskModel>(task);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getTaskByID(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setTaskInit(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setTaskInit(task);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);

  return { taskInit };
};

export const useGetAll = (
  realoadGrid: number,
  startIndex: number,
  endIndex: number,
  filters: any,
  opId: number
) => {
  const [data, setData] = useState<TareaModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllTareas(startIndex, endIndex, filters, opId)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.data);
        setCount(res.count);
        setLoadingRequest(1);
      }
    };
    // fetchPost();
    if (realoadGrid) {
      fetchPost();
    }
  }, [endIndex, realoadGrid]);

  return { data, count, loadingRequest };
};

export const useGetOne = (
  oportunidadId: number,
  id: number,
  show: boolean,
  reload: number
) => {
  const initModel = {
    id: 0,
    nombre: '',
    descripcion: '',
    fecha_inicio: new Date(),
    fecha_fin: new Date(),
    statusTarea: {
      id: 0,
      name: '',
      code: '',
    },
    prioridad: {
      id: 0,
      name: '',
      code: '',
    },
    oportunidad: {
      id: 0,
      nombre: '',
    },
    // vendedor: {
    //   id: 0,
    //   nombre: '',
    // },
    user: {
      id: 0,
      alias: '',
      email: '',
      first_name: '',
      father_last_name: '',
      mother_last_name: '',
      phone: '',
    },
    vendedor_id: '',
    prioridad_id: '',
    status_id: '',
  };
  const [initValues, setInitValues] = useState<TareaModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getTareaById(oportunidadId, id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id > 0 && show) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show, reload]);

  return { initValues };
};

export const useGetAllUsers = (realoadGrid: number) => {
  const [dataUsers, setDataUsers] = useState<UserModel[]>([]);
  const [count, setCount] = useState(1);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAllUsers()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataUsers(res.data);
        // setCount(res.count);
        setLoadingRequest(1);
      }
    };
    // fetchPost();
    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { dataUsers, count, loadingRequest };
};

export const useEditTask = () => {
  const [loadingEditTask, setLoadingEditTask] = useState<boolean>(false);
  const history = useHistory();
  const editTask = async (task: TaskModel, oportunityId: number) => {
    setLoadingEditTask(false);
    try {
      await updateTask(task, oportunityId);
      setLoadingEditTask(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingEditTask(false);
    }
  };
  return { editTask, loadingEditTask };
};

export const useGetHistory = (reaload: number, search: string, id: string) => {
  const [data, setData] = useState<HistoryModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getHistory(search, id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (reaload && Number(id) > 0) {
      fetchPost();
    }
  }, [reaload]);

  return { data, loadingRequest };
};

export const useInsertMessage = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const history = useHistory();
  const insertData = async (message: string, id: string) => {
    setLoadingInsert(false);
    try {
      await createHistoryMessage(message, id);
      setLoadingInsert(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingInsert(false);
    }
  };
  return { insertData, loadingInsert };
};

export const useGetUsersTasks = () => {
  const [dataSelect, setDataSelect] = useState<SelectFormat[]>([]);
  const [loadingSelect, setLoadingSelect] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingSelect(false);
      const res = await getUsersTasks()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataSelect(res);
        setLoadingSelect(true);
      }
    };
    fetchPost();
  }, []);

  return { dataSelect, loadingSelect };
};

export const useGetUsersTasksBitacora = () => {
  const [dataSelect, setDataSelect] = useState<SelectFormat[]>([]);
  const [loadingSelect, setLoadingSelect] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingSelect(false);
      const res = await getUsersTasksBitacora()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataSelect(res);
        setLoadingSelect(true);
      }
    };
    fetchPost();
  }, []);

  return { dataSelect, loadingSelect };
};

export const useGetMe = (reloadInfo: boolean, init: any) => {
  const [user, setUser] = useState<any>(init);
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getMe();
      setUser(data);
    };
    fetchPost();
  }, [reloadInfo]);
  return { user };
};

export const useGetDocumentsByTaskId = (taskId: string, reload: number) => {
  const [dataDocuments, setDataDocuments] = useState<TaskDocModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingDocuments(false);
      const res = await getDocumentsByTaskId(taskId)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setDataDocuments(res);
        setLoadingDocuments(true);
      }
    };
    fetchPost();
  }, [reload]);

  return { dataDocuments, loadingDocuments };
};

export const usePauseTask = () => {
  const [loadingPauseTask, setLoadingPauseTask] = useState<boolean>(false);
  const history = useHistory();
  const pauseTask = async (taskId: string, isPause: number) => {
    setLoadingPauseTask(false);
    try {
      await updatePauseTask(taskId, isPause);
      setLoadingPauseTask(true);
    } catch (error) {
      history.push('/error/500');
      setLoadingPauseTask(false);
    }
  };
  return { pauseTask, loadingPauseTask };
};
