import React from 'react';
import { HomeCountByUserModel } from '../models/HomeModel';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../../_metronic/helpers';

type Props = {
  dataCount: HomeCountByUserModel;
};

const CardCount: React.FC<Props> = ({ dataCount }) => {
  const { tareas, oportunidades, cotizaciones, ventas } = dataCount;

  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const { roles } = user;

  return (
    <>
      <div className="card-spacer mt-4">
        <div className="row m-0">
          {userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], roles) && (
            <>
              <div className="col card px-6 py-8 rounded-xl mb-7 mr-15 br-15">
                <div className="center-items font-weight fs-020">
                  Oportunidades
                </div>
                <div className="fs-030 center-items text-muted">
                  {oportunidades}
                </div>
              </div>
            </>
          )}
          {userHasRole(['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA'], roles) && (
            <>
              <div className="col card px-6 py-8 rounded-xl br-15 mb-7">
                <div className="center-items font-weight fs-020">Tareas</div>
                <div className="fs-030 center-items text-muted ">{tareas}</div>
              </div>
            </>
          )}
        </div>
        <div className="row m-0">
          {/* {userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], roles) && (
            <>
              <div className="col card px-6 py-8 rounded-xl mr-15 br-15">
                <div className="center-items font-weight fs-020">
                  Cotizaciones
                </div>
                <div className="fs-030 center-items text-muted ">
                  {cotizaciones}
                </div>
              </div>
            </>
          )} */}
          {userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], roles) && (
            <>
              <div className="col card px-6 py-8 rounded-xl br-15">
                <div className="center-items font-weight fs-020">Ventas</div>
                <div className="fs-030 center-items text-muted ">{ventas}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { CardCount };
