import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { styleSelect } from '../../../../_metronic/helpers/SelectStyles';
import {
  useGetContactosFilter,
  useGetFilterContactos,
} from '../../contacto/hooks/ContactoHook';
import { CreateContactModal } from '../_modals/CreateContactModal';
import {
  KTSVG,
  maskPhoneNumber,
  cleanPhoneNumber,
  maskPhoneNumberNineteen,
} from '../../../../_metronic/helpers';
import {
  useGetOne,
  useGetHistory,
  useInsertMessage,
  useCreateAccount,
  useEditAccount,
  useGetNoteByAccountId,
  useGetSubByAccountId,
  useGetAddressByAccountId,
  useGetDocumentsByAccountId,
  useGetContactsByAccountId,
  useAddContactInAccount,
} from '../hooks/AccountHook';
import {
  useCreateAddress,
  useCreateSubaccount,
} from '../../subaccount/hooks/SubaccountHook';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';
import { useSelectSimpleFormat } from '../../catalogos/generico/hooks/genericoHook';
import { History } from '../../../../_metronic/helpers/components/History';
import { useGetAllVendedoresComerciales } from '../../oportunidades/hooks/OportunidadHook';
import { NoteList } from './sections/NoteList';
import { CreateNoteModal } from '../_modals/CreateNoteModal';
import { CreateNoteDetailModal } from '../_modals/CreateNoteDetailModal';
import { SubAccountList } from './sections/SubAccountList';
import { AddressModal } from '../../../../_metronic/helpers/components/Address';
import { AddressList } from './sections/AddressList';
import { AddressModel } from '../../../../_metronic/helpers/models/AddressModel';
import { DocsList } from './sections/DocsList';
import { ContactList } from './sections/ContactList';
import { AddContactModal } from '../../contacto/_modals/AddContacto';
import { LocationModel } from '../models/AccountModel';

type Props = {
  id: string;
  onRefreshHistory: () => void;
};

// const initAddressModel = {
//   id: 0,
//   nombre: '',
//   pais: '',
//   calle: '',
//   numero_exterior: '',
//   numero_interior: '',
//   codigo_postal: '',
//   colonia: '',
//   ciudad: '',
//   estado: '',
// };

const AccountForm: React.FC<Props> = ({ id, onRefreshHistory }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const sectionTabs = useRef<HTMLDivElement>(null);
  const [contId, setContId] = useState<any | null>(null);
  const [regiValue, setRegiValue] = useState<any | null>(null);
  const [contactValue, setcontactValue] = useState<any | null>(null);
  const location = useLocation<LocationModel>();
  const tab = location.state?.tab;

  const [tabs, setTabs] = useState<
    string | 'NOTE' | 'SUBACCOUNTS' | 'CONTACT' | 'ADDRESSES' | 'DOCUMENTS'
  >(tab ?? 'NOTE');
  const [reloadGridNotes, setReloadGridNotes] = useState<number>(
    Math.random() * 40
  );
  const [reloadGridSubaccount, setReloadGridSubaccount] = useState<number>(
    Math.random() * 40
  );
  const [reloadGridAddress, setReloadGridAddress] = useState<number>(
    Math.random() * 40
  );
  const [reloadGridContacts, setReloadGridContacts] = useState<number>(
    Math.random() * 40
  );
  const [activeNewNote, setActiveNewNote] = useState<boolean>(false);
  const [activeNewAddress, setActiveNewAddress] = useState<boolean>(false);
  const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
  const [showNoteDetailModal, setShowNoteDetailModal] =
    useState<boolean>(false);
  const [activeNewSubaccount, setActiveNewSubaccount] =
    useState<boolean>(false);
  const [activeNewContacts, setActiveNewContacts] = useState<boolean>(false);
  const [showModalAddress, setShowModalAddress] = useState<boolean>(false);
  const [showModalContacts, setShowModalContacts] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [addressInit, setAddessInit] = useState<AddressModel>({
    id: 0,
    nombre: '',
    pais: '',
    calle: '',
    numero_exterior: '',
    numero_interior: '',
    codigo_postal: '',
    colonia: '',
    ciudad: '',
    estado: '',
  });
  const [showModalContact, setShowModalContact] = useState<boolean>(false);
  const [cuentaId, setCuentaId] = useState<number>(0);
  const [subcuentaId, setSubcuentaId] = useState<number>(0);
  const [reloadContact, setReloadContact] = useState<number>(
    Math.random() * 40
  );
  const location_get = 1;
  const { contDataSelect } = useGetContactosFilter(
    reloadContact,
    0,
    subcuentaId,
    location_get
  );
  const { contInfoSelect } = useGetFilterContactos(
    reloadContact,
    cuentaId,
    subcuentaId,
    location_get
  );
  // SERVICES
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
  const { dataDocuments, loadingDocuments } = useGetDocumentsByAccountId(
    Number(id),
    reloadDocuments
  );

  const { initValues, loadingRequest } = useGetOne(Number(id));
  const { sellerData } = useGetAllVendedoresComerciales();
  const { catalog: tipoCuenta } = useSelectGeneric('TCA');
  const { catalog: industrias } = useSelectGeneric('IND');
  const { catalog: tamEmpresa } = useSelectGeneric('TMOE');
  const { optionsSelect: tipoRegimen } = useSelectSimpleFormat('TPREGF', true);
  const { createAccount, loadingCreateAccount, isSuccess, isError } =
    useCreateAccount();
  const {
    editAccount,
    loadingEditAccount,
    isSuccess: isSuccessEdit,
    isError: isErrorEdit,
  } = useEditAccount();

  const { addContactinAcount, loadingAddContactInAcount } =
    useAddContactInAccount();
  const { notes, loadingNote } = useGetNoteByAccountId(
    id.toString(),
    reloadGridNotes
  );
  const { subaccount, loadingSub } = useGetSubByAccountId(
    id.toString(),
    reloadGridSubaccount
  );
  const { address, loadingAddress } = useGetAddressByAccountId(
    id.toString(),
    reloadGridAddress
  );
  const { contacts, loadingContacts } = useGetContactsByAccountId(
    id.toString(),
    reloadGridContacts
  );
  const { insertSubaccount, loadingInsertSubaccount } = useCreateSubaccount();
  const { createAddress, loadingInsertAddress } = useCreateAddress();

  const accountSchema = Yup.object().shape({
    nombre: Yup.string().required('Nombre requerido.'),
    sitio_web: Yup.string().url('Debe ser una URL válida'),
    tipo_id: Yup.number()
      .min(1, 'Debe seleccionar una tipo de cuenta.')
      .required('Tipo de cuenta requerido.'),
    vendedor_comercial_id: Yup.number()
      .min(1, 'Debe seleccionar un vendedor.')
      .required('Vendedor requerido.'),
    regimen_id: Yup.number()
      .min(1, 'Debe seleccionar un régimen.')
      .required('Régimen requerido.'),
    contactoPrincipal_id: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .when('regiValue', (regiValue, schema) => {
        const isContactRequired =
          regiValue?.code === 'FIS' || regiValue?.code === 'FISAE';
        return isContactRequired
          ? schema.required('Contacto requerido.')
          : schema;
      })
      .typeError('Contacto debe ser un número válido.'),
  });

  // FORM DATA
  const accountForm = useFormik({
    initialValues: initValues,
    validationSchema: accountSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      if (Number(id) > 0) {
        editAccount(values, Number(id));
      } else {
        createAccount(values);
      }
      // const isValidNumber = (value: any): value is number =>
      //   typeof value === 'number' && !isNaN(value);
      // if (initValues.id !== 0) {
      //   if (initValues.contactoPrincipal_id !== values.contactoPrincipal_id) {
      //     if (isValidNumber(values.contactoPrincipal_id)) {
      //       addContactinAcount(values);
      //     }
      //   }
      // }
    },
  });

  // HELPERS
  const showMaskPhoneNumber = (values: any) => {
    let value = values.target.value.toString();
    value = cleanPhoneNumber(value);
    const maskedPhoneNumber = maskPhoneNumberNineteen(value);
    accountForm.setFieldValue('telefono', maskedPhoneNumber);
  };

  const openModal = (isOpen: boolean) => {
    setShowAccountModal(isOpen);
  };
  const openDetailModal = (isOpen: boolean) => {
    setShowNoteDetailModal(isOpen);
  };
  const openAddressModal = (isOpen: boolean) => {
    setShowModalAddress(isOpen);
  };
  const openContactsModal = (isOpen: boolean) => {
    setShowModalContacts(isOpen);
  };
  const openModalToEdit = (values: any) => {
    setAddessInit(values);
    setIsEditable(true);
    setShowModalAddress(true);
  };
  const userSelected = (selectedOption: any) => {
    const { value } = selectedOption;
    accountForm.setFieldValue('vendedor_comercial_id', value);
  };

  const saveSubaccount = (subName: string) => {
    insertSubaccount(subName, id.toString());
  };

  const onSaveAddress = (address: AddressModel) => {
    createAddress(address, id.toString());
  };

  // EFFECTS

  useEffect(() => {
    if (Number(id) > 0 && initValues.id > 0) {
      if (initValues.referido?.id) {
        setContId({
          value: initValues.referido?.id,
          label: initValues.referido ? initValues.referido.nombre_completo : '',
        });
        accountForm.setFieldValue('referido_id', initValues.referido?.id);
      }
      if (initValues.regimen?.id) {
        setRegiValue({
          value: initValues.regimen?.id,
          label: initValues.regimen ? initValues.regimen.name : '',
          code: initValues.regimen ? initValues.regimen.code : '',
        });
        accountForm.setFieldValue('regimen_id', initValues.regimen?.id);
      }
      if (initValues.contactoPrincipal?.id) {
        setcontactValue({
          value: initValues.contactoPrincipal?.id,
          label: initValues.contactoPrincipal
            ? initValues.contactoPrincipal.nombre_completo
            : '',
        });
        accountForm.setFieldValue(
          'contactoPrincipal_id',
          initValues.contactoPrincipal?.id
        );
      }
      setCuentaId(Number(id));
    }
  }, [initValues]);

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
    }
    if (isError) {
      setLoading(false);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isSuccessEdit) {
      history.goBack();
    }
    if (isErrorEdit) {
      setLoading(false);
    }
  }, [isSuccessEdit, isErrorEdit]);
  useEffect(() => {
    if (!loadingInsertSubaccount) {
      setReloadGridSubaccount(Math.random() * 40);
      onRefreshHistory();
    }
  }, [loadingInsertSubaccount]);

  useEffect(() => {
    if (loadingInsertAddress) {
      setReloadGridAddress(Math.random() * 40);
      onRefreshHistory();
      setShowModalAddress(false);
    }
  }, [loadingInsertAddress]);

  const handleSelect = (selectedOption: any, selectId: string) => {
    if (selectedOption) {
      const { value, label } = selectedOption;

      if (selectId) {
        setContId(selectedOption);
        accountForm.setFieldValue('referido_id', value);
        if (value === 'new') {
          setShowModalContact(true);
          setContId(null);
          accountForm.setFieldValue('referido_id', null);
        }
      }
    } else {
      setContId(selectedOption);
      accountForm.setFieldValue('referido_id', null);
    }
  };

  const handleSelectContact = (selectedOption: any) => {
    setcontactValue(selectedOption);
    if (selectedOption) {
      const { value } = selectedOption;
      accountForm.setFieldValue('contactoPrincipal_id', value);
    } else {
      accountForm.setFieldValue('contactoPrincipal_id', null);
    }
  };

  const handleSelectRegimen = (selectedOption: any) => {
    const { value } = selectedOption;
    setRegiValue(selectedOption);
    accountForm.setFieldValue('regimen_id', value);
  };
  return (
    <>
      <form
        id="kt_account_form"
        className="form mb-10"
        action="#"
        onSubmit={accountForm.handleSubmit}
      >
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-0">
            <div className="w-100 pe-5">
              <input
                className="form-control mb-2 w-100 border-0"
                style={{
                  width: '100%',
                  padding: '15px',
                  fontSize: '24px',
                  boxSizing: 'border-box',
                  marginBottom: '20px',
                }}
                data-kt-element="input"
                placeholder="Nombre *"
                {...accountForm.getFieldProps('nombre')}
              ></input>
              {accountForm.touched.nombre && accountForm.errors.nombre && (
                <div className="fv-plugins-message-container text-danger ms-3 w-100 mb-3">
                  <span role="alert">{accountForm.errors.nombre}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className="required form-label w-auto">
                  Tipo cuenta
                </label>
              </div>
              <select
                className="form-select mb-5"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                {...accountForm.getFieldProps('tipo_id')}
              >
                <option value={0}>Seleccione</option>
                {tipoCuenta.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {accountForm.touched.tipo_id && accountForm.errors.tipo_id && (
                <div className="fv-plugins-message-container text-danger w-100 mb-3">
                  <span role="alert">{accountForm.errors.tipo_id}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className="required form-label w-auto">Régimen</label>
              </div>
              <Select
                options={tipoRegimen}
                styles={styleSelect}
                placeholder={'Seleccione'}
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{'No se encontraron registros'}</span>;
                }}
                onChange={handleSelectRegimen}
                value={regiValue}
                // isClearable
                backspaceRemovesValue
              />
              {accountForm.touched.regimen_id && accountForm.errors.regimen_id && (
                <div className="fv-plugins-message-container text-danger w-100 mb-3 mt-3">
                  <span role="alert">{accountForm.errors.regimen_id}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label
                  className={` ${
                    regiValue?.code === 'FIS' || regiValue?.code === 'FISAE'
                      ? '' //'required' se comenta por bug 592
                      : ''
                  } form-label w-auto`}
                >
                  Contacto principal
                </label>
              </div>
              <Select
                options={contInfoSelect}
                styles={styleSelect}
                placeholder={'Seleccione'}
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{'No se encontraron registros'}</span>;
                }}
                onChange={handleSelectContact}
                value={contactValue}
                isClearable
                backspaceRemovesValue
              />
              {accountForm.touched.contactoPrincipal_id &&
                accountForm.errors.contactoPrincipal_id && (
                  <div className="fv-plugins-message-container text-danger w-100 mb-3">
                    <span role="alert">
                      {accountForm.errors.contactoPrincipal_id}
                    </span>
                  </div>
                )}
            </div>
          </div>

          {/* d-flex flex-column align-items-center justify-content-center */}

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className="form-label w-auto">Teléfono</label>
              </div>
              <input
                className="form-control mb-3"
                placeholder="Ejemplo: 81 1726 1744"
                value={maskPhoneNumberNineteen(
                  accountForm.values.telefono || ''
                )}
                prefix=""
                onChange={showMaskPhoneNumber}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">Industria</label>
              </div>
              <select
                className="form-select mb-3"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                {...accountForm.getFieldProps('industria_id')}
              >
                <option value={0}>Seleccione</option>
                {industrias.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className="form-label w-auto">Tamaño</label>
              </div>
              <select
                className="form-select mb-5"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                {...accountForm.getFieldProps('tamano_id')}
              >
                <option value={0}>Seleccione</option>
                {tamEmpresa.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">Sitio Web</label>
              </div>
              <input
                maxLength={250}
                type="text"
                {...accountForm.getFieldProps('sitio_web')}
                className="form-control mb-3"
                placeholder="Ejemplo: https://www.dominio.com"
              />
              {accountForm.touched.sitio_web && accountForm.errors.sitio_web && (
                <div className="fv-plugins-message-container text-danger w-100 mb-3">
                  <span role="alert">{accountForm.errors.sitio_web}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">
                  Número de empleados
                </label>
              </div>
              <NumericFormat
                name="num_empleados"
                className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                value={accountForm.values.num_empleados}
                decimalScale={0}
                decimalSeparator="."
                maxLength={9}
                fixedDecimalScale
                placeholder="Ejemplo: 10 empleados"
                prefix=""
                thousandSeparator=""
                onValueChange={(values) => {
                  const { value } = values;
                  accountForm.setFieldValue(
                    'num_empleados',
                    value ? value : ''
                  );
                }}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">Referido por</label>
              </div>
              {/* <input
                {...accountForm.getFieldProps('nombre_referido')}
                className="form-control mb-3"
                placeholder="Ejemplo: Adrián Rodriguez"
              /> */}
              <Select
                options={contDataSelect}
                styles={styleSelect}
                placeholder={'Seleccione'}
                isSearchable={true}
                noOptionsMessage={() => {
                  return <span>{'No se encontraron registros'}</span>;
                }}
                onChange={(event) => handleSelect(event, 'CONT')}
                value={contId}
                // isLoading={loadingRequest}
                isClearable
                backspaceRemovesValue
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="w-100 pe-5">
              <div className="w-100 d-flex">
                <label className=" form-label w-auto">No. de proveedor</label>
              </div>
              <input
                {...accountForm.getFieldProps('proveedor_id')}
                className="form-control mb-3"
                placeholder="Ejemplo: 19237188912023"
              />
              {/* <NumericFormat
                name="proveedor_id"
                className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                value={accountForm.values.proveedor_id}
                decimalScale={0}
                decimalSeparator="."
                maxLength={15}
                fixedDecimalScale
                placeholder="Ejemplo: 19237188912023"
                prefix=""
                thousandSeparator=""
                onValueChange={(values) => {
                  const { value } = values;
                  accountForm.setFieldValue("proveedor_id", value ? value : "");
                }}
              /> */}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 d-flex flex-column ">
            <label className="required form-label w-auto ">
              Vendedor comercial
            </label>
            <Select
              options={sellerData}
              value={
                accountForm.values.vendedor_comercial_id
                  ? sellerData.find(
                      (option) =>
                        option.value ===
                        accountForm.values.vendedor_comercial_id
                    )
                  : null
              }
              styles={{
                placeholder: (provided: any, state: any) => ({
                  ...provided,
                  color: '#181c32',
                  textAlign: 'center',
                }),
                indicatorSeparator: (provided: any, state: any) => ({
                  ...provided,
                  display: 'none',
                }),
                dropdownIndicator: (provided: any, state: any) => ({
                  ...provided,
                  color: '#9096EC',
                  display: 'none',
                }),
                control: (provided: any, state: any) => ({
                  ...provided,
                  border: 'none',
                  borderRadius: '6px',
                  marginBottom: '3px',
                  padding: '3px',
                  fontSize: '1rem',
                  boxShadow: 'none',
                  backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                }),
                singleValue: (provided: any, state: any) => ({
                  ...provided,
                  color: state.isDisabled ? '#181c32' : 'inherit',
                  fontWeight: 500,
                }),
                option: (provided: any, state: any) => ({
                  ...provided,
                  fontSize: '1.0rem',
                  textAlign: 'center',
                }),
              }}
              formatOptionLabel={(option) => (
                <div
                  className="d-flex"
                  style={{ alignItems: 'center', width: '100%' }}
                >
                  <div className="symbol symbol-30px">
                    <span
                      className="symbol-label fs-7 fw-bold bg-light-primary text-primary fw-bold"
                      title={option.label}
                    >
                      {option?.nombre[0]}
                      {option?.apellidoP[0]}
                    </span>
                  </div>
                  <span className="ms-3 ">
                    {option.nombre} {option.apellidoP}
                  </span>
                </div>
              )}
              placeholder={
                <span className="symbol symbol-30px cursor-pointer">
                  <div className="symbol-label">
                    <KTSVG
                      path="/media/icons/duotune/communication/com006.svg"
                      className="svg-icon-1"
                    />
                  </div>
                </span>
              }
              isSearchable={true}
              noOptionsMessage={() => {
                return <span>{'No se encontraron registros'}</span>;
              }}
              onChange={(event) => userSelected(event)}
            />
            {accountForm.touched.vendedor_comercial_id &&
              accountForm.errors.vendedor_comercial_id && (
                <div className="fv-plugins-message-container mt-5 text-danger w-100 mb-3">
                  <span role="alert">
                    {accountForm.errors.vendedor_comercial_id}
                  </span>
                </div>
              )}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-10">
          <button
            type="reset"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={loading}
          >
            {!loading && <span className="indicator-label">Guardar</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Espere por favor...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {Number(id) > 0 && (
        <div className="row g-6 g-xl-9" ref={sectionTabs}>
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="card card-flush p-5"> 
              <div className="row mb-5">
                <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                  <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'NOTE' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('NOTE');
                        }}
                      >
                        <span className="margin-left-05">Notas</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'SUBACCOUNTS'
                            ? 'text-active-primary active'
                            : ''
                        } `}
                        onClick={() => {
                          setTabs('SUBACCOUNTS');
                        }}
                      >
                        <span className="margin-left-05">Subcuentas</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'CONTACT' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('CONTACT');
                        }}
                      >
                        <span className="margin-left-05">Contactos</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'ADDRESSES'
                            ? 'text-active-primary active'
                            : ''
                        } `}
                        onClick={() => {
                          setTabs('ADDRESSES');
                        }}
                      >
                        <span className="margin-left-05">Direcciones</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'DOCUMENTS'
                            ? 'text-active-primary active'
                            : ''
                        } `}
                        onClick={() => {
                          setTabs('DOCUMENTS');
                        }}
                      >
                        <span className="margin-left-05">Documentos</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {tabs === 'NOTE' ? (
                  <>
                    <NoteList
                      data={notes}
                      onOpenModal={openModal}
                      active={activeNewNote}
                      onOpenDetailModal={openDetailModal}
                    ></NoteList>
                  </>
                ) : null}
                {tabs === 'SUBACCOUNTS' ? (
                  <>
                    <SubAccountList
                      subaccounts={subaccount}
                      onSaveSubaccount={saveSubaccount}
                      active={activeNewSubaccount}
                      loading= {loadingInsertSubaccount}
                    ></SubAccountList>
                  </>
                ) : null}
                {tabs === 'CONTACT' ? (
                  <ContactList
                    data={contacts}
                    onOpenModal={openContactsModal}
                    active={activeNewContacts}
                  ></ContactList>
                ) : null}
                {tabs === 'ADDRESSES' ? (
                  <AddressList
                    data={address}
                    onOpenModal={openAddressModal}
                    active={activeNewAddress}
                    onEditAddress={openModalToEdit}
                  />
                ) : null}
                {tabs === 'DOCUMENTS' ? (
                  <DocsList
                    documents={dataDocuments || []}
                    onRefreshData={() => {
                      setReloadDocuments(Math.random() * 100);
                      onRefreshHistory();
                    }}
                    account={Number(id)}
                  ></DocsList>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      <CreateNoteModal
        show={showAccountModal}
        handleClose={(refresh) => {
          setShowAccountModal(!showAccountModal);
          if (refresh) {
            setReloadGridNotes(Math.random() * 40);
            onRefreshHistory();
          }
        }}
        account_id={Number(id)}
      ></CreateNoteModal>
      <CreateNoteDetailModal
        show={showNoteDetailModal}
        handleClose={(refresh) => {
          setShowNoteDetailModal(!showNoteDetailModal);
          onRefreshHistory();
        }}
        data={notes}
        accountId={id}
      ></CreateNoteDetailModal>
      <AddressModal
        isEditable={isEditable}
        show={showModalAddress}
        handleSubmit={onSaveAddress}
        handleClose={() => {
          // setAddessInit(initAddressModel);
          setShowModalAddress(false);
          setReloadGridAddress(Math.random() * 40);
          setIsEditable(false);
          onRefreshHistory();
        }}
        dataEditable={addressInit}
      ></AddressModal>
      <AddContactModal
        id_row={Number(id)}
        title={'Nuevo contacto'}
        show={showModalContacts}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGridContacts(Math.random() * 40);
            onRefreshHistory();
          }
          setShowModalContacts(!showModalContacts);
        }}
      ></AddContactModal>
      <CreateContactModal
        show={showModalContact}
        cuenta={Number(id) ? Number(id) : null}
        subcuenta={subcuentaId ? subcuentaId : null}
        handleClose={(refresh: boolean) => {
          if (refresh) {
            setReloadContact(Math.random() * 50);
          }
          setShowModalContact(!showModalContact);
        }}
      ></CreateContactModal>
    </>
  );
};
export { AccountForm };
