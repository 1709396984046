import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../modules/auth/models/UserModel';
import { RootState } from '../../../setup/index';
import { PageTitle } from '../../../_metronic/layout/core';
import { KTSVG, userHasRole } from '../../../_metronic/helpers';
import { CardUser } from './components/CardUser';
import { CardCount } from './components/CardCount';
import { CardOpportunities } from './components/CardOpportunities';
import {
  useGetDetailCountByUser,
  useGetDataBoard,
  useGetDataTask,
} from './hooks/HomeHook';
import { CardTask } from './components/CardTasks';
import { FilterBoard } from './components/FilterBoard';
import { HomeFilterBoard } from './models/HomeModel';
import CardList from './components/CardList';

const HomePage: FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const [initFilterValues, setInitFilterValues] = useState<HomeFilterBoard>({
    campo_id: null,
    valor: null,
  });
  const [optionFilter, setOptionFilter] = useState<number>(1);
  const [reloadGrid, setReloadGrid] = useState<number>(Math.random() * 100);
  const { dataCount } = useGetDetailCountByUser(optionFilter, reloadGrid);
  const { dataTask, loadingRequest } = useGetDataTask();
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [secction, setSecction] = useState<string | 'BOARD' | 'LIST'>('LIST');
  const { dataBoard, boardFormatter } = useGetDataBoard(
    initFilterValues,
    reloadGrid
  );

  const { roles } = user;
  const isSupervisor = userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], roles);

  const history = useHistory();

  const filterSelected = (option: number) => {
    setOptionFilter(option);
  };

  const toggleMode = (mode: 'LIST' | 'BOARD') => {
    localStorage.setItem('viewMode', mode);
    setSecction(mode);
  };

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        campo_id: null,
        valor: null,
      });
    }
    setReloadGrid(Math.random() * 40);
  };

  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({
      campo_id: null,
      valor: null,
    });
    setReloadGrid(Math.random() * 40);
  };

  const onRefreshData = () => {
    setReloadGrid(Math.random() * 40);
  };

  useEffect(() => {
    const storedMode = localStorage.getItem('viewMode');
    if (storedMode) {
      setSecction(storedMode);
    }
  }, []);

  return (
    <>
      <div className="row g-6 g-xl-9 mb-10">
        <div className="col-xxl-5 col-xl-5">
          <div className="mb-2">
            <CardUser user={user} onSelectedFilter={filterSelected}></CardUser>
          </div>
          <div>
            <CardCount dataCount={dataCount}></CardCount>
          </div>
        </div>
        <div className="col-xxl-7 col-xl-7">
          <CardTask
            tasks={dataTask}
            loadingListTaks={loadingRequest}
          ></CardTask>
        </div>
      </div>
      {isSupervisor ? (
        <div className="row g-6 g-xl-9">
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="card card-flush p-5">
              <div className="row">
                <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                  <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                    <li className="nav-item cursor-pointer ">
                      <a
                        className={`nav-link me-6 ${
                          secction === 'LIST'
                            ? 'text-active-primary active'
                            : ''
                        } `}
                        onClick={() => {
                          toggleMode('LIST');
                        }}
                      >
                        <KTSVG
                          path="/media/icons/duotune/text/txt001.svg"
                          className="svg-icon-1 fs-7"
                        />
                        <span className="margin-left-05">Lista</span>
                      </a>
                    </li>
                    <li className="nav-item cursor-pointer">
                      <a
                        className={`nav-link me-6  ${
                          secction === 'BOARD'
                            ? 'text-active-primary active'
                            : ''
                        }`}
                        onClick={() => {
                          toggleMode('BOARD');
                        }}
                      >
                        <KTSVG
                          path="/media/icons/duotune/layouts/lay002.svg"
                          className="svg-icon-1 fs-7"
                        />
                        <span className="margin-left-05">Tablero</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-xxl-4 col-xl-4 col-md-4 col-lg-4 position-end">
                  <div className="card-title center-items">
                    <a
                      className={
                        'btn btn-sm btn-icon btn-active-light-primary border-btn'
                      }
                      title={'Nueva oportunidad'}
                      onClick={() => {
                        history.push(`/oportunidades_add`);
                      }}
                    >
                      <KTSVG
                        path="/media/icons/duotune/abstract/abs011.svg"
                        className="svg-icon-5 svg-icon-gray-500 "
                      />
                    </a>
                  </div>
                  <div className="mr-05"></div>
                  <FilterBoard
                    onCleanFilter={onCleanFilter}
                    onSearchFilter={onSearchFilter}
                    initFilterValues={initFilterValues}
                  ></FilterBoard>
                </div>
              </div>
              <div className="row">
                {secction === 'BOARD' ? (
                  <CardOpportunities
                    board={dataBoard}
                    boardFormatter={boardFormatter}
                    onRefreshData={onRefreshData}
                  ></CardOpportunities>
                ) : null}
                {secction === 'LIST' ? (
                  <CardList listData={dataBoard}></CardList>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const HomeWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Inicio'}</PageTitle>
      <HomePage />
    </>
  );
};

export { HomeWrapper };
