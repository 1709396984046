import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { KTSVG } from '../../../../_metronic/helpers';
import { ListGrid } from './ListGrid';
import { Filter } from './Filter';
import { CreateModal } from '../_modals/CreateModal';
import { RemoveModal } from '../_modals/RemoveModal';
import { useGetAll, useGetAllVendedores } from '../hooks/VendedorHook';

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userHasRole = (roles: string[]) => {
    const luser: UserModel = user;
    let hasRole: boolean = false;

    roles.forEach((element) => {
      if (luser?.roles?.some((e) => e.code === element)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  const [loadingGrid, setLoadingGrid] = useState(true);
  const [idRow, setIdRow] = useState(0);
  const [idDelete, setIdDelete] = useState(0);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({});
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, loadingRequest } = useGetAll(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );
  const { dataSellers } = useGetAllVendedores(reloadGrid);
  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    setTitleModal('Agregar usuario vendedor');
    setVisivilityModal(!visivilityModal);
  };
  const editRegister = (id: number) => {
    setIdRow(id);
    setTitleModal('Editar usuario vendedor');
    setVisivilityModal(!visivilityModal);
  };
  const removeRegister = (id: number) => {
    setIdDelete(id);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };

  const onSearchFilter = (values: any) => {
    setLoadingGrid(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({});
    }
    setReloadGrid(Math.random() * 40);
  };
  const onCleanFilter = () => {
    setLoadingGrid(true);
    setInitFilterValues({});
    setReloadGrid(Math.random() * 40);
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
                data={dataSellers}
              />
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                {/* {userHasRole(['ADMIN']) && ( */}
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={addRegister}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
                {/* )} */}
              </div>
            </div>
            <ListGrid
              data={data}
              loadingGrid={loadingGrid}
              count={count}
              currentPage={currentPage}
              totalPages={totalPages}
              onRemoveRegister={removeRegister}
              onPageChange={(pageNumber: number) => {
                setLoadingGrid(true);
                setCurrentPage(pageNumber);
              }}
              onEditRegister={editRegister}
            />
          </div>
        </div>
      </div>
      <CreateModal
        id_row={idRow}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setVisivilityModal(true);
          }
          setVisivilityModal(!visivilityModal);
          setReloadGrid(Math.random() * 40);
          setIdRow(0);
        }}
      ></CreateModal>

      <RemoveModal
        show={visivilityRemoveModal}
        id_row={idDelete}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setLoadingGrid(true);
            setReloadGrid(Math.random() * 40);
            const newPage = Math.ceil(
              (count - 1 <= 0 ? 1 : count - 1) / pageSize
            );
            setCurrentPage(newPage);
          }
          setIdDelete(0);
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
    </>
  );
};

export { View };
