import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { FallbackView } from '../../_metronic/partials';
import { userHasRole } from '../../_metronic/helpers';
import { UserModel } from '../modules/auth/models/UserModel';
import { RootState } from '../../setup';
import { DashboardSalesWrapper } from '../pages/dashboard_ventas/DashboardWrapper';
import { DashboardProyeccionesWrapper } from '../pages/dashboard_proyecciones/DashboardWrapper';
// import { CuentaPage } from '../pages/cuenta/Cuenta';
// import { AccountCreateEditPage } from '../pages/cuenta/CuentaAE';
// import { SubCuentaPage } from '../pages/subcuenta/SubCuenta';
// import { VendedorPage } from '../pages/vendedor/Vendedor';
import { ContactoPage } from '../pages/contacto/Contacto';
import { ContactoCreateEditPage } from '../pages/contacto/components/ContactoAE';
import { OportunidadPage } from '../pages/oportunidades/Oportunidad';
import { OpportunityCreateEditPage } from '../pages/oportunidades/OportunidadAE';
import { ScoreCardPage } from '../pages/scorecard/ScoreCard';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { UsuariosPage } from '../pages/usuarios/Usuarios';
import { GenericoPage } from '../pages/catalogos/generico/Generico';
import { NotificacionDetallePage } from '../pages/notificaciones/NotificacionDetalle';
import { PlantillaPage } from '../pages/plantillas/Plantilla';
import { EmailPage } from '../pages/email/Email';
import { HomeWrapper } from '../pages/home/HomeWrapper';
import { CotizacionPage } from '../pages/cotizacion/cotizacion';
import { TasksPage } from '../pages/tasks/Tasks';
import { RisksPage } from '../pages/risks/Risks';
import { MitigatePage } from '../pages/mitigate/Mitigates';
import { AccountPage } from '../pages/account/Account';
import { AccountCreateEditPage } from '../pages/account/AccountAE';
import { NotePage } from '../pages/notes/Notes';
import { NoteOppPage } from '../pages/notes_opp/Notes';
import { SubaccountCreateEditPage } from '../pages/subaccount/SubAccount';
import { PackagesPage } from '../pages/packages/Packages';
import { VendedorPage } from '../pages/vendedor/Vendedor';
import { QuotationPage } from '../pages/cotizacion/CotizacionAE';
import { CotizacionPdf } from '../pages/cotizacion_pdf/CotizacionPdf';
import { DiscountPage } from '../pages/discount/Discount';
import { SurchargePage } from '../pages/surcharge/Surcharge';
import { CategoryProductPage } from '../pages/categories/products/Products';
import { CategoryServicePage } from '../pages/categories/services/Services';
import { CategoryPackagePage } from '../pages/categories/packages/Packages';
import { SubCategoryProductPage } from '../pages/sub_categories/products/Products';
import { SubCategoryPackagePage } from '../pages/sub_categories/packages/Packages';
import { SubCategoryServicePage } from '../pages/sub_categories/services/Services';
import { TaxesPages } from '../pages/taxes/Taxes';
import { ProductsPage } from '../pages/products/Products';
import { NotePackagePage } from '../pages/notes_package/Notes';
import { NoteProductPage } from '../pages/notes_product/Notes';
import { NoteQuoPage } from '../pages/notes_quo/Notes';
import { ServicesPage } from '../pages/services/Services';
import { NoteServicePage } from '../pages/notes_service/Notes';
import { CollaboratorPage } from '../pages/collaborators/Collaborator';
// aqui vamos a importar nuestro componente y crear la ruta
// en este caso pueden renombrar el componente
// hacemos la correcion y accedemos a la pagina principal del form
import { CollaboratorAddEdit } from '../pages/collaborators/CollaboratorAE';
import { CotizacionPreviewPdf } from '../pages/cotizacion/cotizacion_preview_pdf/CotizacionPreviewPdf';
import { BitacoraPage } from '../pages/bitacora/Bitacora';
import { HumanResourcesSettingsPage } from '../pages/settings_erp/human_resources/HumanResources';
import { Tareas } from '../pages/bitacora/tareas/Tareas';
import { NoteContactPage } from '../pages/notes_contact/Notes';
import { ProyectosForm } from '../pages/bitacora/proyectos/form/ProyectosForm';
import { ProyectosPage } from '../pages/bitacora/proyectos/Proyectos';
import { CategoryProyectos } from '../pages/categories/proyectos/Proyectos';
import { SubCategoryProyectosPage } from '../pages/sub_categories/proyectos/Proyectos';
import { NoteProjectPage } from '../pages/notes_project/Notes';
import { MapaSitioPage } from '../pages/mapa_sitio/Mapa';

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route
          path="/index"
          component={
            userHasRole(
              ['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP', 'SUP', 'COMP'],
              userRoles
            )
              ? HomeWrapper
              : ErrorsPage
          }
        />
        <Route
          path="/dashboard/proyecciones/"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP'], userRoles)
              ? DashboardProyeccionesWrapper
              : ErrorsPage
          }
        />
        <Route
          path="/dashboard/ventas/"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP'], userRoles)
              ? DashboardSalesWrapper
              : ErrorsPage
          }
        />
        {/* CLIENTES */}
        <Route
          path="/clientes/cuentas/:id_account/subcuentas/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? SubaccountCreateEditPage
              : ErrorsPage
          }
        />
        <Route
          path="/clientes/cuentas/:id_account/notas/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? NotePage
              : ErrorsPage
          }
        />
        <Route
          path="/clientes/cuentas/edit/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? AccountCreateEditPage
              : ErrorsPage
          }
        />
        <Route
          path="/clientes/cuentas/add/"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? AccountCreateEditPage
              : ErrorsPage
          }
        />
        <Route
          exact
          path="/clientes/cuentas"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? AccountPage
              : ErrorsPage
          }
        />
        {/* CONTACTOS */}
        <Route
          path="/clientes/contactos/:id_contacto/notas/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? NoteContactPage
              : ErrorsPage
          }
        />
        <Route
          path="/clientes/contactos/edit/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? ContactoCreateEditPage
              : ErrorsPage
          }
        />
        <Route
          path="/clientes/contactos/add/"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? ContactoCreateEditPage
              : ErrorsPage
          }
        />
        <Route
          path="/clientes/contactos"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? ContactoPage
              : ErrorsPage
          }
        />
        {/* OPORTUNIDADES */}
        <Route
          path="/oportunidades/:id_oportunity/notas/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? NoteOppPage
              : ErrorsPage
          }
        />
        <Route
          path="/oportunidades"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP'], userRoles)
              ? OportunidadPage
              : ErrorsPage
          }
        />
        <Route
          path="/oportunidades_add/:etapa?"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP'], userRoles)
              ? OpportunityCreateEditPage
              : ErrorsPage
          }
        />
        <Route
          path="/oportunidades_edit/:id/:section?"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP'], userRoles)
              ? OpportunityCreateEditPage
              : ErrorsPage
          }
        />
        <Route
          path="/oportunidad_detalle/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP'], userRoles)
              ? NotificacionDetallePage
              : ErrorsPage
          }
        />
        <Route
          path="/oportunidades_tareas/:id_oportunidad/edit/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP'], userRoles)
              ? TasksPage
              : ErrorsPage
          }
        />
        <Route
          path="/oportunidades_riesgos/:id_oportunidad/edit/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP'], userRoles)
              ? RisksPage
              : ErrorsPage
          }
        />
        <Route
          path="/oportunidades_mitigaciones/:id_oportunidad/riesgo/:id_riesgo/edit/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR', 'RH', 'SOP'], userRoles)
              ? MitigatePage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/:id_cotizacion/notas/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? NoteQuoPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/pdf/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? CotizacionPdf
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/preview/pdf/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? CotizacionPreviewPdf
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/paquetes/:id_package/notas/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? NotePackagePage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/paquetes/edit/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? PackagesPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/productos/:id_product/notas/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? NoteProductPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/productos/edit/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? ProductsPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/productos/add/"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? ProductsPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/servicios/:id_service/notas/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? NoteServicePage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/servicios/edit/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? ServicesPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/servicios/add/"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? ServicesPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/paquetes/add/"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? PackagesPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/add/:oportunidad?"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? QuotationPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones/edit/:id"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? QuotationPage
              : ErrorsPage
          }
        />
        <Route
          path="/cotizaciones"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? CotizacionPage
              : ErrorsPage
          }
        />
        <Route
          path="/bitacora/tarea/edit/:id"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA'], userRoles)
              ? Tareas
              : ErrorsPage
          }
        />
        <Route
          path="/bitacora/tarea/add/:etapa_id"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA'], userRoles)
              ? Tareas
              : ErrorsPage
          }
        />
        <Route
          path="/proyectos/:proyecto_id/notas/:id"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA'], userRoles)
              ? NoteProjectPage
              : ErrorsPage
          }
        />
        <Route
          path="/proyectos/edit/:id"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA'], userRoles)
              ? ProyectosForm
              : ErrorsPage
          }
        />
        <Route
          path="/proyectos/add"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA'], userRoles)
              ? ProyectosForm
              : ErrorsPage
          }
        />
        <Route
          path="/proyectos/"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA'], userRoles)
              ? ProyectosPage
              : ErrorsPage
          }
        />
        <Route
          path="/bitacora"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH', 'TEC', 'CMA'], userRoles)
              ? BitacoraPage
              : ErrorsPage
          }
        />

        {/* creamos la ruta para el alta de colaboradores*/}
        {/* esta ruta se la tenemos que pasar al boton de nuevo que se encuentra en la vista principal View */}

        {/* aqui tener cuidado con la jerarquia de rutas, tomar ejemplo  */}

        {/* COLABORADORES */}

        <Route
          path="/recursos_humanos/colaboradores/add"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH'], userRoles)
              ? CollaboratorAddEdit
              : ErrorsPage
          }
        />

        <Route
          path="/recursos_humanos/colaboradores/edit/:id"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH'], userRoles)
              ? CollaboratorAddEdit
              : ErrorsPage
          }
        />

        <Route
          path="/recursos_humanos/colaboradores"
          component={
            userHasRole(['ADMIN', 'DIR', 'SUP', 'RH'], userRoles)
              ? CollaboratorPage
              : ErrorsPage
          }
        />

        <Route
          path="/scorecard"
          component={
            userHasRole(['ADMIN', 'CMA', 'TEC', 'DIR'], userRoles)
              ? ScoreCardPage
              : ErrorsPage
          }
        />
        {/* PLANTILLA */}
        <Route
          path="/plantilla_correo"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? PlantillaPage
              : ErrorsPage
          }
        />
        <Route
          path="/envio_correo"
          component={
            userHasRole(['ADMIN', 'DIR'], userRoles) ? EmailPage : ErrorsPage
          }
        />
        <Route
          path="/vendedor"
          component={
            userHasRole(['ADMIN', 'DIR'], userRoles) ? VendedorPage : ErrorsPage
          }
        />

        <Route
          path="/catalogos/impuestos"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? TaxesPages
              : ErrorsPage
          }
        />
        {/* CATEGORIAS */}
        <Route
          path="/categorias/productos"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? CategoryProductPage
              : ErrorsPage
          }
        />

        <Route
          path="/categorias/servicios"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? CategoryServicePage
              : ErrorsPage
          }
        />

        <Route
          path="/categorias/paquetes"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? CategoryPackagePage
              : ErrorsPage
          }
        />
        <Route
          path="/categorias/proyectos"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? CategoryProyectos
              : ErrorsPage
          }
        />
        {/* CATALOGO SUBCATEGORIAS */}
        <Route
          path="/subcategorias/productos"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? SubCategoryProductPage
              : ErrorsPage
          }
        />

        <Route
          path="/subcategorias/servicios"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? SubCategoryServicePage
              : ErrorsPage
          }
        />

        <Route
          path="/subcategorias/paquetes"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? SubCategoryPackagePage
              : ErrorsPage
          }
        />
        <Route
          path="/subcategorias/proyectos"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? SubCategoryProyectosPage
              : ErrorsPage
          }
        />

        {/* CATALOGOS GENERICOS*/}
        <Route
          path="/catalogos/generico/:code"
          component={
            userHasRole(['ADMIN', 'DIR', 'CMA', 'TEC', 'SUPER'], userRoles)
              ? GenericoPage
              : ErrorsPage
          }
        />
        {/* DESCUENTOS Y RECARGOS */}
        <Route
          path="/catalogos/descuento"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? DiscountPage
              : ErrorsPage
          }
        />

        <Route
          path="/catalogos/recargo"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR'], userRoles)
              ? SurchargePage
              : ErrorsPage
          }
        />

        {/* AJUSTES ERP*/}
        <Route
          path="/ajustes/erp/recursos_humanos"
          component={
            userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], userRoles)
              ? HumanResourcesSettingsPage
              : ErrorsPage
          }
        />

        <Route
          path="/catalogos/"
          component={
            userHasRole(['ADMIN', 'DIR', 'TEC', 'SOP', 'SUP'], userRoles)
              ? MapaSitioPage
              : ErrorsPage
          }
        />

        {/* USUARIOS DEL SISTEMA */}
        <Route
          path="/usuarios"
          component={
            userHasRole(['ADMIN', 'DIR'], userRoles) ? UsuariosPage : ErrorsPage
          }
        />
        <Route path="/perfil" component={ProfilePage} />
        <Redirect from="/auth" to="/index" />
        <Redirect exact from="/" to="/index" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
