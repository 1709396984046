import axios from "axios";
const API_URL = process.env.REACT_APP_API;
const BASE_URL = `${API_URL}subcategoria/proyectos`;

async function handleRequest(request: Promise<any>) {
  try {
    const response = await request;
    return response.data.doc?.data ?? response.data;
  } catch (error) {
    console.error("Error en la petición:", error);
    throw error; 
  }
}

export async function getSubCategoriesGrid() {
  return await handleRequest(axios.get(BASE_URL));
}

export async function getDataSubCategoryById(id: number) {
  return await handleRequest(axios.get(`${BASE_URL}/${id}`));
}

export async function addValueSubCategory({ nombre, orden, categoria_id }: any) {
  return await handleRequest(axios.post(BASE_URL, { nombre, orden, categoria_id }));
}

export async function updValueSubCategory({ id, nombre, orden, categoria_id }: any) {
  return await handleRequest(axios.patch(`${BASE_URL}/${id}`, { nombre, orden, categoria_id }));
}
export async function removeRegisterSubCategory(id: number) {
  return await handleRequest(axios.delete(`${BASE_URL}/${id}`));
}

export async function getSubCategoriesByIDCategory(id: number) {
  return await handleRequest(axios.get(`${BASE_URL}/categoria/${id}`));
}
