import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { KTSVG, userHasRole } from '../../../../../_metronic/helpers';
import { useFormik } from 'formik';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import { shallowEqual, useSelector } from 'react-redux';
import { styleSelect } from '../../../../../_metronic/helpers/SelectStyles';
import {
  useObtenerCamposFiltroProyectos,
  useObtenerDatosFiltrosProyectos,
} from '../hooks/ProyectosHook';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
};

const FiltrosProyectos: React.FC<Props> = ({
  initFilterValues,
  onCleanFilter,
  onSearchFilter,
}) => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const isAdmin = userHasRole(['ADMIN', 'DIR'], userRoles);
  const [code, setCode] = useState<string>('');

  const { listFields } = useObtenerCamposFiltroProyectos(isAdmin);
  const { dataOptionSelected } = useObtenerDatosFiltrosProyectos(code);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectionSecondOption, setSelectionSecondOption] = useState(null);

  const [filterValues, setFilterValues] = useState(initFilterValues);
  // TEST

  const handleSelect = (selectedOption: any) => {
    const { value, code } = selectedOption;
    setSelectedOption(selectedOption);
    setSelectionSecondOption(null);
    formFilter.setFieldValue('campo_id', value);
    setCode(code);
  };

  const handleSecondSelect = (selectedOption: any) => {
    const { value } = selectedOption;
    setSelectionSecondOption(selectedOption);
    formFilter.setFieldValue('valor', value);
  };

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter(values);
    },
  });

  return (
    <>
      <div className="card-title align-items-start flex-column mt-2">
        <a
          href="#/"
          className={'btn btn-sm btn-light-primary'}
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Filtrar
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown w-250px w-md-350px"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilter.handleSubmit}
          >
            <div className="px-7 py-5">
              <div className="mb-5">
                <label className="form-label fw-bold">
                  Selecciona una opción:{' '}
                </label>
                <div onClick={(e) => e.stopPropagation()}>
                  <Select
                    options={listFields}
                    placeholder={'Seleccione'}
                    onChange={(event) => {
                      handleSelect(event);
                    }}
                    value={selectedOption}
                    noOptionsMessage={() => {
                      return <span>No se encontraron registros</span>;
                    }}
                    styles={styleSelect}
                  />
                </div>
              </div>
              {code && (
                <div className="mb-5">
                  <label className="form-label fw-bold">
                    Selecciona una opción:{' '}
                  </label>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Select
                      options={dataOptionSelected}
                      placeholder={'Seleccione'}
                      onChange={(event) => {
                        handleSecondSelect(event);
                      }}
                      value={selectionSecondOption}
                      noOptionsMessage={() => {
                        return <span>No se encontraron registros</span>;
                      }}
                      styles={styleSelect}
                    />
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-end ">
                <button
                  type="reset"
                  className="btn btn-sm btn-white btn-active-light-primary me-2"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    formFilter.resetForm();
                    onCleanFilter();
                    setSelectedOption(null);
                    setSelectionSecondOption(null);
                    setCode('');
                  }}
                >
                  Limpiar
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  data-kt-menu-dismiss="true"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { FiltrosProyectos };
