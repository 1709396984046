import axios from "axios";
const API_URL = process.env.REACT_APP_API;

export async function getCompanyByUser() {
    const response = await axios.get(`${API_URL}catalogos/empresas/user/company`);

    const data = response.data.doc.data;

    return data;
}

export async function updateCompany(generar_num_empleado: boolean, companyId: number) {
    const response = await axios.put(`${API_URL}catalogos/empresas/colab/${companyId}`, {
      generar_num_empleado: generar_num_empleado,
    });
    return response;
  }