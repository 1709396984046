import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { styleSelect } from '../../../../../../_metronic/helpers/SelectStyles';
import { KTSVG, SimpleAlert } from '../../../../../../_metronic/helpers';
import { TareaAvancesModel } from '../../../../tasks/models/TaskModel';
import { sumar } from '../../../../../../_metronic/helpers/PriceCalculations';
import { formatDate } from '../../../../../../_metronic/helpers/FormatDate';

type Props = {
  recursosAsignados: any[];
  data: TareaAvancesModel[];
  unidadEsfuerzoId: number;
  unidadEsfuerzoCodigo: string;
  avanceTotal: number;
  fechaInicioAvance: string;
  fechaFinAvance: string;
  isSupervisor: boolean;
  cambioEsfuerzo: (totalEsfuerzo: number, recursos: any) => void;
  cambioAvance: (totalEsfuerzo: number) => void;
};

const AvanceList: React.FC<Props> = ({
  recursosAsignados,
  data,
  unidadEsfuerzoId,
  unidadEsfuerzoCodigo,
  avanceTotal,
  fechaInicioAvance,
  fechaFinAvance,
  cambioEsfuerzo,
  cambioAvance,
  isSupervisor
}) => {
  let today = new Date().toISOString().split('T')[0];
  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const [recursos, setRecursos] = useState<any[]>([]);
  const [esfuerzoReal, setEsfuerzoReal] = useState<number>(0);

  const AddRow = () => {
    if (unidadEsfuerzoId <= 0) {
      SimpleAlert('Favor seleccione la unidad de Esfuerzo.', 3000, 'error');
      return false;
    }
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: TareaAvancesModel = {
      id: 0,
      fecha: today,
      esfuerzo: 0,
      colaborador: {
          id: 0,
          contacto: {
            id: 0,
            nombre: '',
            apellido_paterno: '',
            apellido_materno: '',
          },
          perfil: {
            id: 0,
            name: '',
            code: '',
          },
        }, 
      etapa: {
        id: 0,
        name: '',
        code: '',
      },
      unidadEsfuerzo: {
        id: unidadEsfuerzoId,
        name: unidadEsfuerzoCodigo,
        code: unidadEsfuerzoCodigo,
      },
      perfil: {
        id: 0,
        name: '',
        code: '',
      },
    };
    setDataMemo([...cleanedData, newRow]);
  };

  const addConcept = (selectedOption: any) => {
    if (selectedOption) {
      const { id, data } = selectedOption;
      const cleanedData = dataMemo.filter((item) => item.id !== 0);
      const existingIndex = dataMemo.findIndex((item) => item.id === id);
      if (existingIndex === -1) {
        const updateRow = {
          id: id,
          fecha: today,
          esfuerzo: 0,
          recurso: data?.colaborador,
          perfil: data.perfil,
          etapa: {
            id: 0,
            name: '',
            code: '',
          },
          unidadEsfuerzo: data.unidadEsfuerzo,
        };
        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = [...cleanedData, updateRow];
          sumAmount(updatedDataMemo);
          return updatedDataMemo;
        });
      } else {
        setDataMemo([...cleanedData]);
        SimpleAlert('Ya existe este recurso asignado.', 3000, 'error');
      }
    }
  };
  const volumeChange = (newVolumen: number, recursoId: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === recursoId) {
          return {
            ...item,
            esfuerzo: newVolumen,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };
  const dateChange = (newDate: string, recursoId: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === recursoId) {
          return {
            ...item,
            fecha: newDate,
          };
        }
        return item;
      });
      sumAmount(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  const sumAmount = (updatedDataMemo: any) => {
    const totalEsfuerzo = updatedDataMemo.reduce((total: number, item: any) => {
      return sumar(total, Number(item.esfuerzo));
    }, 0);
    setEsfuerzoReal(totalEsfuerzo);
    cambioEsfuerzo(totalEsfuerzo, updatedDataMemo);
  };

  useEffect(() => {
    const cleanedData = data.filter((item) => item.id !== 0);
    console.log('🚀 ~ useEffect ~ Avances:', cleanedData);
    sumAmount(cleanedData);
    setDataMemo(cleanedData);
  }, [data]);

  useEffect(() => {
    return () => {
      setDataMemo([]);
    };
  }, []);

  useEffect(() => {
    if (recursosAsignados) {
      console.log('🚀 ~ useEffect ~ recursosAsignados:', recursosAsignados);
      setRecursos(recursosAsignados);
    }
  }, [recursosAsignados]);

  return (
    <>
      <div className="row mb-10">
        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Fecha inicio </label>
            </div>
            <div className="mt-3">
              <div className="w-100 text-gray-700 mt-2">
                {fechaInicioAvance
                  ? formatDate(new Date(fechaInicioAvance))
                  : 'No registrado'}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Fecha final </label>
            </div>
            <div className="mt-3">
              <div className="w-100 text-gray-700 mt-2">
                {fechaFinAvance
                  ? formatDate(new Date(fechaFinAvance))
                  : 'No registrado'}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">Esfuerzo real</label>
            </div>
            <div className="mt-3">
              <div className="w-100 text-gray-700 mt-2">
                {esfuerzoReal} {unidadEsfuerzoCodigo}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="w-100 pe-5">
            <div className="w-100 d-flex">
              <label className="form-label w-auto">% de avance</label>
            </div>
            <div className="mt-3">
              <div className="w-100 text-gray-700 mt-2">
                <NumericFormat
                  type="text"
                  autoComplete="off"
                  className="form-control mb-3"
                  value={avanceTotal}
                  decimalScale={0}
                  decimalSeparator="."
                  fixedDecimalScale
                  placeholder={'0 %'}
                  disabled={!isSupervisor}
                  suffix={' %'}
                  isAllowed={(values: any) => {
                    const { floatValue } = values;
                    let allow = floatValue <= 100 || floatValue === undefined;
                    return allow;
                  }}
                  allowNegative={false}
                  thousandSeparator={''}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    if (floatValue) {
                      if (floatValue > 0) {
                        cambioAvance(floatValue);
                      }
                    } else {
                      cambioAvance(floatValue || 0);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-200px">Recurso asignado</th>
              <th className="w-200px">Perfil</th>
              <th className="w-200px">Fecha</th>
              <th className="w-200px">Etapa</th>
              <th className="w-200px">Unidad de esfuerzo</th>
              <th className="w-200px">Esfuerzo</th>
              {/* <th className="w-100px"></th> */}
            </tr>
          </thead>
          <tbody>
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-700 fw-bold ">
                        {row.id === 0 ? (
                          <Select
                            key={row.id}
                            options={recursos}
                            styles={styleSelect}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={(e) => {
                              addConcept(e);
                            }}
                            backspaceRemovesValue
                          />
                        ) : (
                          <>
                            {row.recurso &&
                              row.recurso.contacto &&
                              row.recurso.contacto && (
                                <>{`${row.recurso.contacto.nombre} ${row.recurso.contacto.apellido_paterno}`}</>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-700 fw-bold ">
                        {row.id === 0 ? (
                          <>Sin perfil</>
                        ) : (
                          <>{row.recurso?.perfil && <>{`${row.recurso?.perfil?.name}`}</>}</>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-gray-600">
                        <>
                          {row.id === 0 ? (
                            <>{row.fecha}</>
                          ) : (
                            <>
                              <input
                                className={'form-control'}
                                data-kt-element="input"
                                type="date"
                                // min={today}
                                name="fecha_inicio"
                                value={row.fecha}
                                onChange={(e) => {
                                  dateChange(e.target.value, row.id);
                                }}
                              />
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        <>
                          {row.etapa && row.etapa.id > 0 ? (
                            <>{row.etapa.name}</>
                          ) : (
                            <>Pendiente</>
                          )}
                        </>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        <>
                          {row.unidadEsfuerzo && row.unidadEsfuerzo.id > 0 ? (
                            <>{row.unidadEsfuerzo.code}</>
                          ) : (
                            <>Sin unidad</>
                          )}
                        </>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        {row.id !== 0 ? (
                          <NumericFormat
                            className="form-control w-200px"
                            thousandSeparator={false}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            allowNegative={false}
                            decimalScale={0}
                            value={row.esfuerzo}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (!value) {
                                e.target.value = '0';
                              }
                            }}
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              const integerPart = Math.floor(floatValue);
                              return integerPart.toString().length <= 10;
                            }}
                            suffix=""
                            allowLeadingZeros={false}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              if (floatValue) {
                                if (floatValue > 0) {
                                  volumeChange(floatValue, row.id);
                                }
                              } else {
                                volumeChange(floatValue || 0, row.id);
                              }
                            }}
                          />
                        ) : (
                          <>{'0'}</>
                        )}
                      </div>
                    </div>
                  </td>

                  {/* <td>
                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                        <div
                          className="badge badge-light-danger  text-hover-danger fw-bold px-4 py-3 cursor-pointer"
                          title="Eliminar producto"
                          onClick={async (event) => {
                            // deleteRowById(row.id);
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-4"
                          />
                        </div>
                      </div>
                    </div>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center text-muted">
                  Sin recursos
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={6}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Esfuerzo
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { AvanceList };
