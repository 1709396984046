import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../../_metronic/helpers';
import { Link } from 'react-router-dom';

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className="card mb-6 mb-xl-9 p-10">
            {/* <div className="mb-15">
              <div className="fs-5 text-muted fw-semibold">
                Visualización de los catálogos disponibles en el sistema,
                organizada para facilitar la consulta y navegación eficiente de
                los recursos.
              </div>
            </div> */}
            <div className="row g-10 mb-15">
              {userHasRole(['ADMIN', 'CMA', 'DIR', 'TEC'], userRoles) && (
                <>
                  <div className="col-sm-4">
                    <h3 className="fw-bold text-gray-900 mb-6">CRM</h3>
                    <div
                      className="d-flex flex-column fw-semibold fs-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Mi cuenta</div>
                      <Link className="mb-2" to={`/catalogos/generico/UNEG`}>
                        Unidad de negocio
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/UMON`}>
                        Unidad de moneda
                      </Link>
                      <Link className="mb-2" to={`/catalogos/impuestos`}>
                        Tipo de impuesto
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Clientes</div>
                      <Link className="mb-2" to={`/catalogos/generico/TCA`}>
                        Tipo de cuenta
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/DEP`}>
                        Departamentos
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/IND`}>
                        Industrias
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/TMOE`}>
                        Tamaño de empresa
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Oportunidades</div>
                      <Link className="mb-2" to={`/catalogos/generico/TOP`}>
                        Tipo de oportunidad
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/ETP`}>
                        Etapas
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Productos</div>
                      <Link className="mb-2" to={`/catalogos/generico/TPROD`}>
                        Tipo de producto
                      </Link>
                      <Link className="mb-2" to={`/categorias/productos`}>
                        Categoría
                      </Link>
                      <Link className="mb-2" to={`/subcategorias/productos`}>
                        Subcategoría
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/TVEN`}>
                        Tipo de venta
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/TCOM`}>
                        Tipo de compra
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/UNMED`}>
                        Unidad de medida
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Servicios</div>
                      <Link className="mb-2" to={`/catalogos/generico/TSERV`}>
                        Tipo de servicio
                      </Link>
                      <Link className="mb-2" to={`/categorias/servicios`}>
                        Categoría
                      </Link>
                      <Link className="mb-2" to={`/subcategorias/servicios`}>
                        Subcategoría
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/TPVENS`}>
                        Tipo de venta
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/TPCOMS`}>
                        Tipo de compra
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/UNDMEDS`}>
                        Unidad de medida
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/PASOC`}>
                        Perfil asociado
                      </Link>
                      <Link className="mb-2" to={`/servicios/activoAsociado/`}>
                        Activo asociado ( pendiente )
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Paquetes</div>
                      <Link className="mb-2" to={`/catalogos/generico/TPACK`}>
                        Tipo de paquete
                      </Link>
                      <Link className="mb-2" to={`/categorias/paquetes`}>
                        Categoría
                      </Link>
                      <Link className="mb-2" to={`/subcategorias/paquetes`}>
                        Subcategoría
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Cotizaciones</div>
                      <Link className="mb-2" to={`/catalogos/generico/TOP`}>
                        Tipo de cotización
                      </Link>
                      <Link className="mb-2" to={`/catalogos/descuento`}>
                        Descuentos
                      </Link>
                      <Link className="mb-2" to={`/catalogos/recargo`}>
                        Recargos
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      {/* <div className="mb-4">Ventas</div>
                      <Link className="mb-2" to={`/plantilla_correo`}>
                        Plantilla de correo
                      </Link> */}
                    </div>
                  </div>
                </>
              )}
              {userHasRole(['ADMIN', 'RH', 'SOP', 'SUP', 'DIR'], userRoles) && (
                <>
                  <div className="col-sm-6">
                    <h3 className="fw-bold text-gray-900 mb-6">ERP</h3>
                    <div
                      className="d-flex flex-column fw-semibold fs-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Mi cuenta</div>
                      <Link className="mb-2" to={`/catalogos/generico/UNEG`}>
                        Unidad de negocio
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/DEP`}>
                        Departamentos
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/UMON`}>
                        Unidad de moneda
                      </Link>
                      <Link className="mb-2" to={`/catalogos/impuestos`}>
                        Tipo de impuesto
                      </Link>
                      <Link
                        className="mb-2"
                        to={`/ajustes/erp/recursos_humanos`}
                      >
                        Configurar número de empleado
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Colaboradores</div>
                      <Link className="mb-2" to={`/catalogos/generico/TCOLLAB`}>
                        Tipo colaborador
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/PASOC`}>
                        Perfil
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Proyectos</div>
                      <Link
                        className="mb-2"
                        to={`/catalogos/generico/PROYECTO_TIPO`}
                      >
                        Tipo de proyecto
                      </Link>
                      <Link className="mb-2" to={`/categorias/proyectos`}>
                        Categoría
                      </Link>
                      <Link className="mb-2" to={`/subcategorias/proyectos`}>
                        Subcategoría
                      </Link>
                    </div>
                    <div
                      className="d-flex flex-column fw-semibold fs-6 mt-6"
                      style={{
                        width: 'fit-content',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className="mb-4">Tareas</div>
                      <Link className="mb-2" to={`/catalogos/generico/TTASK`}>
                        Tipo de tarea
                      </Link>
                      <Link
                        className="mb-2"
                        to={`/catalogos/generico/UN_EFFORT_TASK`}
                      >
                        Unidad de esfuerzo
                      </Link>
                      <Link className="mb-2" to={`/catalogos/generico/ETAR`}>
                        Etapa
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
