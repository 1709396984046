import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import {
  useCrearEditarProyecto,
  useGetDocumentsByProjectId,
  useGetNoteByProjectId,
  useObtenerCategoriasProyectos,
  useObtenerProyectosPorID,
  useObtenerSubcategorias,
} from '../../hooks/ProyectosHook';
import { colorStatusProject } from '../../../../../../_metronic/helpers/CustomElements';
import { useSelectGeneric } from '../../../../catalogos/generico/hooks/genericoHook';
import { styleSelectPackages } from '../../../../../../_metronic/helpers/SelectStyles';
import {
  ProyectoModel,
  SeccionProyectosModel,
} from '../../models/ProyectoModel';
import { CategoriaModal } from '../_modals/CategoriaModal';
import { SubcategoriaModal } from '../_modals/SubcategoriaModal';
import { KTSVG, SimpleAlert } from '../../../../../../_metronic/helpers';
import { SectionsList } from './sections/SectionsList';
import { DocumentsList } from './sections/DocumentsList';
import { NoteList } from './sections/NoteList';
import { CreateNoteModal } from '../_modals/CreateNoteModal';
import { CreateNoteDetailModal } from '../_modals/CreateNoteDetailModal';

type Props = {
  proyectoId: number;
  onRefreshHistory: () => void;
};

const ProyectoForm: React.FC<Props> = ({ proyectoId, onRefreshHistory }) => {
  const history = useHistory();

  const sectionTabs = useRef<HTMLDivElement>(null);
  const [tabs, setTabs] = useState<string | 'NOTE' | 'DOC'>('NOTE');

  let today = new Date().toISOString().split('T')[0];
  const [loading, setLoading] = useState<boolean>(false);
  const { proyecto, loadingRequest } = useObtenerProyectosPorID(
    Number(proyectoId)
  );
  const [recargarCategoria, setRecargarCategoria] = useState<number>(
    Math.random() * 40
  );
  const [recargarSubcategoria, setRecargarSubcategoria] = useState<number>(
    Math.random() * 40
  );
  const [selectedOptionCategory, setSelectedOptionCategory] = useState<
    any | null
  >(null);
  const [selectedOptionSubcategory, setSelectedOptionSubcategory] = useState<
    any | null
  >(null);
  const [selectSubcategoryAdding, setSelectSubcategoryAdding] =
    useState<boolean>(false);

  const [selectCategoryAdding, setSelectCategoryAdding] =
    useState<boolean>(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] =
    useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [subCategoryId, setSubCategoryId] = useState<number>(0);
  const [sections, setSections] = useState<SeccionProyectosModel[]>([]);
  const [sectionsDeleted, setSectionsDeleted] = useState<
    SeccionProyectosModel[]
  >([]);
  const [isOpenSubcategoryModal, setIsOpenSubcategoryModal] =
    useState<boolean>(false);

  const { catalog: tipo_proyecto } = useSelectGeneric('PROYECTO_TIPO');
  const { catalog: unidad_negocio } = useSelectGeneric('UNEG');
  const { loadingCatalog, categorias } =
    useObtenerCategoriasProyectos(recargarCategoria);
  const { loadingSubCategorias, subcategorias } = useObtenerSubcategorias(
    categoryId,
    recargarSubcategoria
  );
  const { crearEditarProyecto } = useCrearEditarProyecto();

  // DOCUMENTS
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
  const { dataDocuments, loadingDocuments } = useGetDocumentsByProjectId(
    proyectoId,
    reloadDocuments
  );

  // NOTAS
  const [reloadGridNotes, setReloadGridNotes] = useState<number>(
    Math.random() * 40
  );
  const { notes, loadingNote } = useGetNoteByProjectId(
    proyectoId,
    reloadGridNotes
  );
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [activeNewNote, setActiveNewNote] = useState<boolean>(false);
  const [showNoteDetailModal, setShowNoteDetailModal] =
    useState<boolean>(false);

  const validacion = Yup.object().shape({
    nombre: Yup.string()
      .trim()
      .test(
        'not-only-spaces',
        'El nombre no puede contener solo espacios.',
        (value) => value?.trim() !== ''
      )
      .required('Nombre es requerido.'),
    fecha_inicio: Yup.date()
      .nullable()
      .required('La fecha inicio es requerida'),
    fecha_final: Yup.date()
      .min(Yup.ref('fecha_inicio'), 'No puede ser menor fecha inicio.')
      .required('La fecha final es requerida'),
    tipo: Yup.object({
      id: Yup.number()
        .required('El campo tipo es obligatorio')
        .min(1, 'Debe seleccionar un tipo válido'),
    }),
  });

  const Formulario = useFormik<ProyectoModel>({
    initialValues: proyecto,
    validationSchema: validacion,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      values.secciones = sections;
      values.secciones_eliminadas = sectionsDeleted;
      await crearEditarProyecto(values);
      setLoading(false);
    },
  });

  const cambiarCategoria = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionCategory(selectedOption);

      Formulario.setFieldValue('subcategoria.id', 0);
      setSelectedOptionSubcategory(null);
      setSelectSubcategoryAdding(false);
      if (value <= 1) {
        if (Number(value) === 0) {
          setIsOpenCategoryModal(!isOpenCategoryModal);
        }
      }
      Formulario.setFieldValue('categoria.id', value);
      setCategoryId(value);
    } else {
      setCategoryId(0);
      Formulario.setFieldValue('categoria.id', 0);
      Formulario.setFieldValue('subcategoria.id', 0);
      setSelectedOptionSubcategory(null);
    }
  };
  const cambiarSubcategoria = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionSubcategory(selectedOption);
      Formulario.setFieldValue('subcategoria.id', value);
      if (Number(value) === 0) {
        setIsOpenSubcategoryModal(!isOpenSubcategoryModal);
      }
    } else {
      Formulario.setFieldValue('subcategoria.id', 0);
      setSelectedOptionSubcategory(null);
    }
  };

  const addSection = () => {
    let randomID = Math.floor(10000 + Math.random() * 90000);
    const newSection: SeccionProyectosModel = {
      orden: sections.length + 1,
      id: randomID,
      nombre: `Nueva sección`,
      proyecto_id: proyectoId,
      tareas: [],
      esNuevo: 1,
      actualizarSeccion: actualizarSeccion,
    };
    setSections([...sections, newSection]);
    SimpleAlert(
      `Se agregó la sección ${sections.length + 1}.`,
      3000,
      'success'
    );
  };

  const actualizarSeccion = (tareas: any, id: number) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, tareas: tareas } : section
      )
    );
  };

  const updateSectionTitle = (id: number, newTitle: string) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, nombre: newTitle } : section
      )
    );
  };
  const handleTitleBlur = (id: number) => {
    setSections((prevSections) => {
      const updatedSections = prevSections.map((section) => {
        if (section.id === id && section.nombre.trim() === '') {
          return {
            ...section,
            nombre: `Título de sección`,
          };
        }
        return section;
      });
      return updatedSections;
    });
  };

  const sectionRemove = (idToRemove: number) => {
    const sectionToDelete = sections.find(
      (section) => section.id === idToRemove && section.esNuevo !== 1
    );
    if (sectionToDelete) {
      setSectionsDeleted((prevSectionsDeleted) => [
        ...prevSectionsDeleted,
        sectionToDelete,
      ]);
    }
    setSections((prevSections) =>
      prevSections.filter((section) => section.id !== idToRemove)
    );
  };
  const tareaRemove = (idToRemove: number) => {
    const sectionToModify = sections.find((section) =>
      section.tareas?.some((tarea) => tarea.referencia === idToRemove)
    );
    if (sectionToModify) {
      const tareaToRemove = sectionToModify.tareas?.find(
        (tarea) => tarea.referencia === idToRemove
      );
      if (tareaToRemove) {
        const updatedTareas = sectionToModify.tareas.filter(
          (tarea) => tarea.referencia !== idToRemove
        );
        setSections((prevSections) =>
          prevSections.map((section) =>
            section.id === sectionToModify.id
              ? { ...section, tareas: updatedTareas }
              : section
          )
        );
      }
    }
  };

  // SECTIONS NOTES
  const openModal = (isOpen: boolean) => {
    setShowNoteModal(isOpen);
  };
  const openDetailModal = (isOpen: boolean) => {
    setShowNoteDetailModal(isOpen);
  };

  // USE EFFECT
  useEffect(() => {
    const { id, categoria, subcategoria } = proyecto;
    const { id: categoria_id } = categoria;
    const { id: sub_categoria_id } = subcategoria;
    if (id > 0) {
      if (categoria_id) {
        const category = categorias.filter((item) => item.id === categoria_id);

        if (category.length) {
          setSelectedOptionCategory(category[0]);
        }
        setCategoryId(categoria_id);
        if (sub_categoria_id) {
          setSubCategoryId(sub_categoria_id);
        }
      }
    }
  }, [proyecto, categorias]);

  useEffect(() => {
    if (subCategoryId) {
      const currentSubCategory = subcategorias.filter(
        (item: any) => item.value === subCategoryId
      );

      setSelectedOptionSubcategory(currentSubCategory);
    }
  }, [subCategoryId, subcategorias]);

  useEffect(() => {
    if (selectCategoryAdding) {
      const lastPositionCategory = categorias[categorias.length - 1];
      setSelectedOptionCategory(lastPositionCategory);
      setCategoryId(lastPositionCategory.id);
      Formulario.setFieldValue('categoria.id', lastPositionCategory.id);
    }
  }, [categorias]);

  useEffect(() => {
    if (selectSubcategoryAdding) {
      const lastPositionSubcategory = subcategorias[subcategorias.length - 1];
      setSelectedOptionSubcategory(lastPositionSubcategory);
      Formulario.setFieldValue(
        'subcategoria.id',
        lastPositionSubcategory.value
      );
    }
  }, [subcategorias]);

  useEffect(() => {
    if (proyecto.id > 0) {
      initValuesFormatConcepts();
    }
  }, [proyecto]);

  const initValuesFormatConcepts = () => {
    const mappedSections = proyecto.secciones.map(
      (section: any, index: number) => ({
        ...section,
        actualizarSeccion: actualizarSeccion,
      })
    );
    setSections(mappedSections);
  };

  return (
    <>
      <form
        id="kt_account_form"
        className="form"
        action="#"
        noValidate
        onSubmit={Formulario.handleSubmit}
      >
        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 p-0">
                <div className="w-100">
                  <input
                    className="form-control mb-2 w-100 border-0"
                    style={{
                      width: '100%',
                      padding: '15px',
                      fontSize: '24px',
                      boxSizing: 'border-box',
                      marginBottom: '20px',
                    }}
                    maxLength={40}
                    data-kt-element="input"
                    placeholder="Nombre *"
                    {...Formulario.getFieldProps('nombre')}
                  ></input>
                  {Formulario.touched.nombre && Formulario.errors.nombre && (
                    <div className="fv-plugins-message-container text-danger ms-5 w-100 mb-3">
                      <span role="alert">{Formulario.errors.nombre}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Estatus</label>
                  </div>
                  <div
                    className={`mt-4 ${colorStatusProject(
                      Formulario.values.status.code
                    )}`}
                  >
                    {Formulario.values.status.name}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required form-label w-auto">
                      Tipo de proyecto
                    </label>
                  </div>
                  <select
                    className="form-select mb-3"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    {...Formulario.getFieldProps('tipo.id')}
                  >
                    <option value={0}>Seleccione</option>
                    {tipo_proyecto.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {Formulario.touched.tipo?.id && Formulario.errors.tipo?.id && (
                    <div className="fv-plugins-message-container text-danger w-100 mb-3">
                      <span role="alert">{Formulario.errors.tipo.id}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required form-label w-auto">
                      Fecha inicio
                    </label>
                  </div>
                  <input
                    className={'form-control mb-3'}
                    data-kt-element="input"
                    type="date"
                    min={today}
                    name="fecha_inicio"
                    value={Formulario.values.fecha_inicio}
                    onChange={(e) => {
                      Formulario.setFieldValue('fecha_inicio', e.target.value);
                    }}
                  />
                  {Formulario.touched.fecha_inicio &&
                    Formulario.errors.fecha_inicio && (
                      <div className="fv-plugins-message-container text-danger w-100 mb-3">
                        <span role="alert">
                          {Formulario.errors.fecha_inicio}
                        </span>
                      </div>
                    )}
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required form-label w-auto">
                      fecha final
                    </label>
                  </div>
                  <input
                    className={'form-control mb-3'}
                    data-kt-element="input"
                    type="date"
                    min={Formulario.values.fecha_inicio}
                    name="fecha_final"
                    value={Formulario.values.fecha_final}
                    onChange={(e) => {
                      Formulario.setFieldValue('fecha_final', e.target.value);
                    }}
                  />
                  {Formulario.touched.fecha_final &&
                    Formulario.errors.fecha_final && (
                      <div className="fv-plugins-message-container text-danger w-100 mb-3">
                        <span role="alert">
                          {Formulario.errors.fecha_final}
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Unidad de negocio
                    </label>
                  </div>
                  <select
                    className="form-select mb-3"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    {...Formulario.getFieldProps('unidad_negocio.id')}
                  >
                    <option value={0}>Seleccione</option>
                    {unidad_negocio.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 d-flex flex-column ">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className=" form-label w-auto">Categoría</label>
                  </div>
                  <Select
                    options={categorias}
                    value={
                      Formulario.values.categoria.id
                        ? categorias.find(
                            (option) =>
                              option.value === Formulario.values.categoria.id
                          )
                        : null
                    }
                    styles={styleSelectPackages}
                    placeholder={'Seleccione'}
                    isSearchable
                    isClearable
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={cambiarCategoria}
                    isLoading={loadingCatalog}
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Subcategoría</label>
                  </div>
                  <Select
                    isDisabled={Formulario.values.categoria.id <= 0}
                    options={subcategorias}
                    styles={styleSelectPackages}
                    placeholder={'Seleccione'}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={cambiarSubcategoria}
                    value={selectedOptionSubcategory}
                    isLoading={loadingSubCategorias}
                    backspaceRemovesValue
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 ">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Descripción</label>
                  </div>
                  <textarea
                    className="form-control w-100"
                    data-kt-element="input"
                    maxLength={1000}
                    rows={5}
                    style={{ resize: 'none' }}
                    placeholder=""
                    {...Formulario.getFieldProps('descripcion')}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div
            className="btn btn-primary btn-sm mb-10 ms-auto"
            onClick={addSection}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr075.svg"
              className="svg-icon-5"
            />
            Agregar Sección
          </div>
        </div>
        <SectionsList
          sections={sections}
          updateSectionTitle={updateSectionTitle}
          handleTitleBlur={handleTitleBlur}
          sectionRemove={sectionRemove}
          tareaRemove={tareaRemove}
        ></SectionsList>
        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <div className="row">
              <div className="d-flex justify-content-end mt-10">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && <span className="indicator-label">Guardar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {proyectoId ? (
        <div className="row g-6 g-xl-9" ref={sectionTabs}>
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="card card-flush p-5">
              <div className="row mb-5">
                <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                  <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'NOTE' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('NOTE');
                        }}
                      >
                        <span className="margin-left-05">Notas</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'DOC' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('DOC');
                        }}
                      >
                        <span className="margin-left-05">Documentos</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {tabs === 'NOTE' ? (
                  <>
                    <NoteList
                      data={notes}
                      onOpenModal={openModal}
                      active={activeNewNote}
                      onOpenDetailModal={openDetailModal}
                    ></NoteList>
                  </>
                ) : null}
                {tabs === 'DOC' ? (
                  <>
                    <DocumentsList
                      documents={dataDocuments}
                      onRefreshData={() => {
                        setReloadDocuments(Math.random() * 100);
                        onRefreshHistory();
                      }}
                      id={proyectoId}
                      isEdit={false}
                    ></DocumentsList>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <CreateNoteModal
        show={showNoteModal}
        handleClose={(refresh) => {
          setShowNoteModal(!showNoteModal);
          if (refresh) {
            setReloadGridNotes(Math.random() * 40);
            onRefreshHistory();
          }
        }}
        proyecto_id={proyectoId}
      ></CreateNoteModal>
      <CreateNoteDetailModal
        show={showNoteDetailModal}
        handleClose={(refresh) => {
          setShowNoteDetailModal(!showNoteDetailModal);
          onRefreshHistory();
        }}
        data={notes}
        id={proyectoId}
      ></CreateNoteDetailModal>
      <CategoriaModal
        show={isOpenCategoryModal}
        handleClose={(isNew: boolean) => {
          if (isNew) {
            setRecargarCategoria(Math.random() * 40);
            setSelectCategoryAdding(true);
          } else {
            setSelectedOptionCategory(null);
            setCategoryId(0);
            Formulario.setFieldValue('categoria.id', 0);
          }
          setIsOpenCategoryModal(!isOpenCategoryModal);
        }}
      ></CategoriaModal>
      <SubcategoriaModal
        show={isOpenSubcategoryModal}
        handleClose={(isNew: boolean) => {
          if (isNew) {
            setRecargarSubcategoria(Math.random() * 40);
            setSelectSubcategoryAdding(true);
          } else {
            setSelectedOptionSubcategory(null);
            Formulario.setFieldValue('subcategoria.id', 0);
          }
          setIsOpenSubcategoryModal(!isOpenSubcategoryModal);
        }}
        category={selectedOptionCategory}
      ></SubcategoriaModal>
    </>
  );
};

export { ProyectoForm };
